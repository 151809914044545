/**
 * Returns the current UTC date without the time.
 */
const getToday = (): Date => {
  const today = new Date();
  today.setUTCHours(0, 0, 0, 0);

  return today;
};

export default getToday;
