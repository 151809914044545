import { useQuery } from "@tanstack/react-query";
import { ResponseForPath, postApi } from "../lib/api";

/**
 * Normally would just use `useApiQuery` but this is a post request since
 * concept ids gets too long for a query string
 */
const useNumberOfQuestions = (conceptIds: string[] | undefined) =>
  useQuery<ResponseForPath<"/number-of-questions", "post">, unknown>(
    [["/number-of-questions"], conceptIds],
    async () => {
      return await postApi("/number-of-questions", {
        conceptIds: conceptIds ?? [],
      });
    },
    {
      enabled: conceptIds?.length ? conceptIds?.length > 0 : false,
    }
  );

export default useNumberOfQuestions;
