import { Fragment, StrictMode } from "react";
import { DesignTokensProvider, getDesignTokens } from "@gradience/ui";
import ReactDOM from "react-dom/client";
import {
  RouterProvider,
  Router,
  Route,
  RootRoute,
} from "@tanstack/react-router";
import "./index.css";
import reportWebVitals from "./reportWebVitals";
import LandingPage from "./routes/landing-page";
import LogIn from "./routes/auth/log-in";
import SignUp from "./routes/auth/sign-up";
import VerifyEmail from "./routes/verify-email";
import RequestPasswordReset from "./routes/auth/request-password-reset";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import ResetPassword from "./routes/auth/reset-password";
import Root from "./routes/root";
import Dashboard from "./routes/dashboard";
import DashboardHome from "./routes/dashboard/home";
import DashboardAdmins, {
  dashboardAdminsModals,
} from "./routes/dashboard/admins";
import DashboardSchool, {
  dashboardSchoolModals,
} from "./routes/dashboard/school";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import Account from "./routes/account";
import config from "./lib/config";
import DashboardOrder from "./routes/dashboard/order";
import NotFoundPage from "./routes/not-found-page";
import CustomerService from "./routes/customer-service";
import EditSchoolInfo from "./routes/dashboard/edit-school-info";
import DashboardOverview from "./routes/dashboard/overview";
import DashboardResults from "./routes/dashboard/results";
import EditRoles from "./routes/dashboard/edit-roles";
import EditOnlySchoolInfo from "./routes/dashboard/edit-only-school-info";
import GroupDetail from "./routes/dashboard/groups/group-detail";
import SelectCurriculum from "./routes/dashboard/groups/select-curriculum";
import ReviewGrading from "./routes/dashboard/groups/review-grading";
import Bugsnag from "@bugsnag/js";
import BugsnagPluginReact from "@bugsnag/plugin-react";
import BugsnagPerformance from "@bugsnag/browser-performance";
import React from "react";
import posthog from "posthog-js";
import DashboardUpload from "./routes/dashboard/upload";

posthog.init("phc_EC9v20nXmmAc58MrqVhS5h0X0MkKvWd0QhnpkYvIEXz", {
  api_host: "https://us.posthog.com",
});

Bugsnag.start({
  apiKey: "04b7c4a92fd1d307ae9b2d52b1bebae3",
  plugins: [new BugsnagPluginReact()],
});
BugsnagPerformance.start({ apiKey: "04b7c4a92fd1d307ae9b2d52b1bebae3" });

const ErrorBoundary =
  Bugsnag.getPlugin("react")?.createErrorBoundary(React) ?? Fragment;

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

// Create a root route
const rootRoute = new RootRoute({
  component: Root,
});

const landingPageRoute = new Route({
  getParentRoute: () => rootRoute,
  path: "/",
  component: LandingPage,
});

const notFoundRoute = new Route({
  getParentRoute: () => rootRoute,
  path: "*",
  component: NotFoundPage,
});

export const logInRoute = new Route({
  getParentRoute: () => rootRoute,
  path: "/log-in",
  component: LogIn,
  validateSearch: (search: Record<string, unknown>) => {
    return {
      userImpersonationEmail:
        typeof search.userImpersonationEmail === "string"
          ? search.userImpersonationEmail
          : "",
      userImpersonationToken:
        typeof search.userImpersonationToken === "string"
          ? search.userImpersonationToken
          : "",
    };
  },
});

const signUpRoute = new Route({
  getParentRoute: () => rootRoute,
  path: "/sign-up",
  component: SignUp,
});

export const verifyEmailRoute = new Route({
  getParentRoute: () => rootRoute,
  path: "/verify-email",
  component: VerifyEmail,
  validateSearch: (search: Record<string, unknown>) => {
    return {
      token: typeof search.token === "string" ? search.token : "",
    };
  },
});

const requestPasswordResetRoute = new Route({
  getParentRoute: () => rootRoute,
  path: "/request-password-reset",
  component: RequestPasswordReset,
});

export const dashboardRoute = new Route({
  getParentRoute: () => rootRoute,
  path: "/test/$slug",
  component: Dashboard,
});

export const dashboardOverviewRoute = new Route({
  getParentRoute: () => dashboardRoute,
  path: "/overview",
  component: DashboardOverview,
});

export const dashboardResultsRoute = new Route({
  getParentRoute: () => dashboardRoute,
  path: "/results",
  component: DashboardResults,
  validateSearch: (
    search
  ): {
    testScan: string | undefined;
    pageNumber: number | undefined;
  } => {
    return {
      testScan:
        typeof search?.testScan === "string" && search.testScan
          ? search.testScan
          : undefined,
      pageNumber: search.pageNumber
        ? typeof search.pageNumber === "number"
          ? search.pageNumber
          : undefined
        : undefined,
    };
  },
});

export const dashboardUploadRoute = new Route({
  getParentRoute: () => dashboardRoute,
  path: "/upload",
  component: DashboardUpload,
  validateSearch: (
    search
  ): {
    testScan: string | undefined;
    pageNumber: number | undefined;
  } => {
    return {
      testScan:
        typeof search?.testScan === "string" && search.testScan
          ? search.testScan
          : undefined,
      pageNumber: search.pageNumber
        ? typeof search.pageNumber === "number"
          ? search.pageNumber
          : undefined
        : undefined,
    };
  },
});

export const dashboardHomeRoute = new Route({
  getParentRoute: () => dashboardRoute,
  path: "/home",
  component: DashboardHome,
  validateSearch: (search): { configuringGroup: string | undefined } => {
    return {
      configuringGroup:
        typeof search?.configuringGroup === "string" && search.configuringGroup
          ? search.configuringGroup
          : undefined,
    };
  },
});

const editSchoolInfoPrevRouteOptions = ["account", "overview"] as const;
type SchoolInfoPrevRoute = (typeof editSchoolInfoPrevRouteOptions)[number];

export const editSchoolInfoRoute = new Route({
  getParentRoute: () => dashboardRoute,
  path: "/edit-school-info",
  component: EditSchoolInfo,
  validateSearch: (search): { prev: SchoolInfoPrevRoute | undefined } => {
    return {
      prev:
        typeof search?.prev === "string" &&
        // Have to cast for TS, but works fine in JS
        editSchoolInfoPrevRouteOptions.includes(
          search.prev as SchoolInfoPrevRoute
        )
          ? (search.prev as SchoolInfoPrevRoute)
          : undefined,
    };
  },
});

export const editOnlySchoolInfoRoute = new Route({
  getParentRoute: () => rootRoute,
  path: "/edit-only-school-info",
  component: EditOnlySchoolInfo,
});

export const editRolesRoute = new Route({
  getParentRoute: () => dashboardRoute,
  path: "/edit-roles",
  component: EditRoles,
});

export const dashboardAdminsRoute = new Route({
  getParentRoute: () => dashboardRoute,
  path: "/admins",
  component: DashboardAdmins,
  validateSearch: (search: Record<string, unknown>) => {
    const modal =
      typeof search.modal === "string"
        ? dashboardAdminsModals.find((x) => x === search.modal) ?? undefined
        : undefined;

    return {
      modal,
    };
  },
});

export const dashboardSchoolRoute = new Route({
  getParentRoute: () => dashboardRoute,
  path: "/school",
  component: DashboardSchool,
  validateSearch: (search: Record<string, unknown>) => {
    const modal =
      typeof search.modal === "string"
        ? dashboardSchoolModals.find((x) => x === search.modal) ?? undefined
        : undefined;

    return {
      modal,
    };
  },
});

export const dashboardOrderRoute = new Route({
  getParentRoute: () => dashboardRoute,
  path: "/order",
  component: () => <DashboardOrder />,
  validateSearch: (search): { prev: "overview" | undefined } => {
    return {
      prev:
        typeof search?.prev === "string" && search.prev === "overview"
          ? search.prev
          : undefined,
    };
  },
});

const groupDetailModalKeys = [
  "students",
  "group-details",
  "upload-spreadsheet",
] as const;
export const groupDetailRoute = new Route({
  getParentRoute: () => dashboardRoute,
  path: "/group/$groupId",
  component: GroupDetail,
  validateSearch: (search: Record<string, unknown>) => {
    const modal =
      typeof search.modal === "string"
        ? groupDetailModalKeys.find((x) => x === search.modal) ?? undefined
        : undefined;

    let prev = undefined;

    if (typeof search.prev === "string") {
      if (search.prev === "overview") {
        prev = search.prev;
      } else if (search.prev === "results") {
        prev = search.prev;
      }
    }

    return {
      modal,
      prev,
    };
  },
});

export const selectCurriculumRoute = new Route({
  getParentRoute: () => dashboardRoute,
  path: "/group/$groupId/select-curriculum",
  component: SelectCurriculum,
  validateSearch: (search: Record<string, unknown>) => {
    return {
      step: typeof search.step === "number" ? search.step : 0,
    };
  },
});

export const reviewGradingRoute = new Route({
  getParentRoute: () => dashboardRoute,
  path: "/group/$groupId/review-grading",
  component: ReviewGrading,
  validateSearch: (search: Record<string, unknown>) => {
    return {
      student: typeof search.student === "number" ? search.student : null,
    };
  },
});

export const accountRoute = new Route({
  getParentRoute: () => rootRoute,
  path: "/account",
  component: Account,
});

const customerServiceRoute = new Route({
  getParentRoute: () => rootRoute,
  path: "/customer-service",
  component: CustomerService,
});

export const resetPasswordRoute = new Route({
  getParentRoute: () => rootRoute,
  path: "/reset-password",
  component: ResetPassword,
  validateSearch: (search: Record<string, unknown>) => {
    return {
      token: typeof search.token === "string" ? search.token : "",
      initialSetup: search.initialSetup !== undefined,
    };
  },
});

const routeTree = rootRoute.addChildren([
  landingPageRoute,
  notFoundRoute,
  logInRoute,
  signUpRoute,
  verifyEmailRoute,
  requestPasswordResetRoute,
  resetPasswordRoute,
  accountRoute,
  customerServiceRoute,
  editOnlySchoolInfoRoute,
  dashboardRoute.addChildren([
    dashboardOverviewRoute,
    dashboardResultsRoute,
    dashboardUploadRoute,
    dashboardHomeRoute,
    dashboardAdminsRoute,
    dashboardSchoolRoute,
    dashboardOrderRoute,
    editSchoolInfoRoute,
    editRolesRoute,
    groupDetailRoute,
    selectCurriculumRoute,
    reviewGradingRoute,
  ]),
]);

export const router = new Router({ routeTree });

declare module "@tanstack/react-router" {
  interface Register {
    router: typeof router;
  }
}

const queryClient = new QueryClient({
  logger: {
    log: () => {},
    warn: () => {},
    error: () => {},
  },
});
const stripePromise = config.REACT_APP_OFFLINE_DEVELOPMENT_MODE
  ? null
  : loadStripe(config.REACT_APP_STRIPE_PUBLISHABLE_KEY);

const rootElement = document.getElementById("root") as HTMLElement;
if (!rootElement.innerHTML) {
  const root = ReactDOM.createRoot(rootElement);

  const inputStyles = {
    backgroundColor: "transparent",
    padding: "12px 16px",
    borderRadius: "12px",
    border: "1px solid rgba(20, 20, 20, 0.40)",
    boxShadow: "none",
    lineHeight: "115%",
  } as const;
  const designTokens = getDesignTokens(config.REACT_APP_TENANT_THEME_COLOR);

  root.render(
    <ErrorBoundary>
      <StrictMode>
        <Elements
          stripe={stripePromise}
          options={{
            mode: "setup",
            currency: "usd",
            fonts: [
              {
                cssSrc:
                  "https://fonts.googleapis.com/css2?family=Crimson+Pro:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap",
              },
            ],
            appearance: {
              labels: "floating",
              rules: {
                ".Label": {
                  opacity: 0.6,
                } as {},
                ".Input": inputStyles,
                ".Input:focus": {
                  ...inputStyles,
                  border: `1px solid ${designTokens.colors.action.primary}`,
                },
              },
              variables: {
                colorPrimary: designTokens.colors.surface.action,
                colorBackground: designTokens.colors.white,
                colorText: designTokens.colors.text.default,
                colorTextPlaceholder: designTokens.colors.text.subdued,
                colorDanger: designTokens.colors.text.error,
                spacingUnit: "4px",
                borderRadius: "10px",
                fontFamily: designTokens.typography.body.fontFamily,
                fontSmooth: "always",
              },
            },
          }}
        >
          <QueryClientProvider client={queryClient}>
            <DesignTokensProvider
              themeColor={config.REACT_APP_TENANT_THEME_COLOR}
            >
              <RouterProvider router={router} />
            </DesignTokensProvider>
          </QueryClientProvider>
        </Elements>
      </StrictMode>
    </ErrorBoundary>
  );
}
