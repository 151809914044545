import { Text, Button, Input, useDesignTokens } from "@gradience/ui";
import { useRegister } from "../../lib/auth";
import { useState } from "react";
import AuthChrome from "../../components/auth-chrome";
import { isApiError } from "../../lib/api";

function SignUp() {
  const designTokens = useDesignTokens();
  const registerMutation = useRegister();
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [roleAtSchool, setRoleAtSchool] = useState("");
  const [schoolName, setSchoolName] = useState("");
  const [password, setPassword] = useState("");

  return (
    <AuthChrome>
      <form
        onSubmit={(e) => {
          e.preventDefault();
          registerMutation.mutate({
            firstName,
            lastName,
            email,
            roleAtSchool,
            schoolName,
            password,
          });
        }}
        style={{
          display: "flex",
          flexDirection: "column",
          gap: 32,
        }}
      >
        <Text textStyle="headingLarge">Sign Up</Text>

        <div
          style={{
            display: "flex",
            flexDirection: "row",
            gap: 16,
          }}
        >
          <Input
            style={{ flex: 1 }}
            label="First Name"
            name="first_name"
            required
            value={firstName}
            setValue={setFirstName}
          />
          <Input
            style={{ flex: 1 }}
            label="Last Name"
            name="last_name"
            required
            value={lastName}
            setValue={setLastName}
          />
        </div>

        <Input
          label="Email"
          name="email"
          type="email"
          required
          helpText="Please use your official school email for verification purposes."
          value={email}
          setValue={setEmail}
        />
        <Input
          label="Your Role"
          name="role_at_school"
          required
          value={roleAtSchool}
          setValue={setRoleAtSchool}
          helpText="You should be a head of school, principal, or other school administrator. You will be able to add other users to the school account later, such as a head Latin teacher."
        />
        <Input
          label="School Name"
          name="school_name"
          required
          value={schoolName}
          setValue={setSchoolName}
        />
        <Input
          label="Password"
          type="password"
          required
          value={password}
          setValue={setPassword}
        />
        {isApiError(registerMutation.error) && (
          <Text
            textStyle="strong"
            style={{
              color: designTokens.colors.text.error,
            }}
          >
            {registerMutation.error.response?.data.errors[0].message}
          </Text>
        )}
        <div
          style={{
            display: "flex",
            gap: 32,
            flexDirection: "row",
            alignItems: "center",
            flexWrap: "wrap",
          }}
        >
          <Button
            variant="primary"
            text="Sign Up"
            type="submit"
            size="large"
            loading={registerMutation.isLoading}
          />
          <Text textStyle="caption" to="/log-in">
            Already have an account? <b>Log In</b>
          </Text>
        </div>
      </form>
    </AuthChrome>
  );
}

export default SignUp;
