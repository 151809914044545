import { useNavigate, useParams, useSearch } from "@tanstack/react-router";
import {
  Text,
  Loader,
  Card,
  Button,
  Checkbox,
  ModalButtonsRow,
  useDesignTokens,
  Input,
  Divider,
  MeatballMenu,
  Badge,
  Tooltip,
  Icon,
  CompactModal,
} from "@gradience/ui";
import { dashboardHomeRoute, reviewGradingRoute } from "../../..";
import {
  useApiDelete,
  useApiPost,
  useApiPut,
  useApiQuery,
} from "../../../lib/api";
import { Column, Row } from "@gradience/ui";
import { ReactNode, useMemo, useState } from "react";
import config from "../../../lib/config";
import {
  useIsMobile,
  useWindowIsSmallerThan,
} from "../../../lib/use-window-dimensions";
import TopNavigation from "../../../components/top-navigation";
import { PaddedContainer } from "../../../components/page-container";
import { Student, TestScanPage } from "@gradience/api-types";
import { useQueryClient } from "@tanstack/react-query";
import { GradingIssue } from "@gradience/api-types/src/grading-issue";
import styled from "styled-components";

const StudentRow = styled.div<{ selected: boolean }>`
  display: flex;
  flex-direction: row;
  gap: 8px;
  padding: 8px;
  padding-left: 12px;
  border-radius: 12px;
  align-items: center;
  cursor: pointer;
  background-color: ${({ selected, theme }) =>
    selected ? theme.colors.surface.subdued : theme.colors.transparent};
  border: ${({ selected, theme }) =>
    selected
      ? `1px solid ${theme.colors.border.subdued}`
      : `1px solid ${theme.colors.transparent}`};
  &:hover {
    background-color: ${({ theme }) => theme.colors.surface.subdued};
  }
`;

const ReviewGrading = (): ReactNode => {
  const designTokens = useDesignTokens();
  const { student: studentIndex } = useSearch({ from: reviewGradingRoute.id });
  const { groupId, ...params } = useParams({ from: reviewGradingRoute.id });
  const navigate = useNavigate();

  const setStudentIndex = (index: number | null) => {
    navigate({
      to: reviewGradingRoute.fullPath,
      params: { groupId, ...params },
      search: { student: index },
    });
  };

  const group = useApiQuery(
    "/groups/:id",
    { id: groupId ?? "" },
    {
      enabled: groupId !== undefined,
    }
  );
  const students = useApiQuery(
    "/students",
    {},
    { enabled: groupId !== undefined },
    {
      groupId,
    }
  );
  const currentStudent =
    studentIndex !== null ? students.data?.data[studentIndex] : null;
  const testScanPageQuery = useApiQuery(
    "/test-scan-pages",
    {},
    {
      enabled: currentStudent !== undefined,
    },
    {
      studentId: currentStudent?.id,
      take: "20",
    }
  );
  const isFinalStudent =
    students.data && studentIndex === students.data?.data.length - 1;

  const gradingIssues = useApiQuery(
    "/grading-issues",
    {},
    {
      enabled: currentStudent !== undefined,
    },
    {
      studentId: currentStudent?.id,
    }
  );
  const createGradingIssue = useApiPost("/grading-issues", {
    onSuccess: () => {
      setComment("");
      setIssueType(null);
      setQuestionWithIssue(null);
      setEntirePageIsGradedIncorrectly(false);
      gradingIssues.refetch();
    },
  });

  const isMobile = useIsMobile();
  // I found this number by measuring, not sure why it is what it is
  const backButtonIsHidden = useWindowIsSmallerThan(1292);
  const backLinkOptions = {
    to: dashboardHomeRoute.fullPath,
    params: { slug: config.REACT_APP_TEST_SLUG },
  };

  const [entirePageIsGradedIncorrectly, setEntirePageIsGradedIncorrectly] =
    useState(false);
  const [questionWithIssue, setQuestionWithIssue] = useState<string | null>(
    null
  );

  const [issueType, setIssueType] = useState<
    | "INCORRECT_IDENTIFICATION"
    | "FALSE_POSITIVE"
    | "FALSE_NEGATIVE"
    | "OTHER"
    | null
  >(null);

  const [comment, setComment] = useState<string>("");
  const [instructionsModalOpen, setInstructionsModalOpen] = useState(false);

  const groupMutation = useApiPut(
    "/groups/:id",
    { id: groupId },
    {
      onSuccess: () => {
        navigate({
          to: dashboardHomeRoute.fullPath,
          params: { slug: config.REACT_APP_TEST_SLUG },
        });
      },
    }
  );

  if (!groupId) {
    navigate({
      to: dashboardHomeRoute.fullPath,
      params: { slug: config.REACT_APP_TEST_SLUG },
    });
  }

  return (
    <Column
      style={{
        display: "flex",
        position: "relative",
        flex: 1,
      }}
    >
      <TopNavigation
        backLinkOptions={backLinkOptions}
        title="Review Grading"
        subtitle={group.data?.name}
      >
        <Row gap={16}>
          <Button
            onPress={
              studentIndex === null
                ? undefined
                : () => {
                    setStudentIndex(
                      studentIndex === 0 ? null : studentIndex - 1
                    );
                  }
            }
            text={
              backButtonIsHidden && studentIndex === null
                ? "Cancel"
                : "Previous"
            }
            disabled={studentIndex === null && !backButtonIsHidden}
            linkProps={
              backButtonIsHidden && studentIndex === null
                ? backLinkOptions
                : undefined
            }
          />

          <Button
            onPress={async () => {
              if (isFinalStudent) {
                groupMutation.mutate({
                  reviewedAt: new Date().toISOString(),
                });
              } else {
                setStudentIndex((studentIndex ?? -1) + 1);
              }
            }}
            text={
              isFinalStudent
                ? "Finish"
                : currentStudent
                ? "Next Student"
                : "Start"
            }
            loading={groupMutation.isLoading}
            variant="primary"
            disabled={students.isLoading}
          />
        </Row>
      </TopNavigation>
      <PaddedContainer
        style={{
          overflowY: "scroll",
          height: "100%",
          gap: 48,
          flexDirection: "row",
          flexWrap: "wrap",
        }}
      >
        {students.isLoading ? (
          <Loader />
        ) : currentStudent ? (
          <>
            <Column
              gap={8}
              style={{
                flexGrow: 1,
                flexBasis: 350,
              }}
            >
              <Row gap={8}>
                <Text textStyle="headingXS" style={{ flex: 1 }}>
                  Review the following tests
                </Text>
                <Row
                  gap={8}
                  style={{
                    cursor: "pointer",
                  }}
                  onClick={() => setInstructionsModalOpen(true)}
                >
                  <Icon
                    name="information-circle-contained"
                    color={designTokens.colors.brand[100]}
                  />
                  <Text style={{ color: designTokens.colors.brand[100] }}>
                    Instructions
                  </Text>
                </Row>
              </Row>

              {testScanPageQuery.isLoading ? (
                <Loader />
              ) : testScanPageQuery.data?.data.length ? (
                testScanPageQuery.data?.data.map((page) => (
                  <TestScanPageViewer
                    page={page}
                    student={currentStudent}
                    key={page.id}
                  />
                ))
              ) : (
                <div
                  style={{
                    backgroundColor: designTokens.colors.surface.subdued,
                    borderRadius: 12,
                    padding: 24,
                  }}
                >
                  <Text>
                    No test was graded for this student. Either it wasn't
                    received, or the image wasn't readable.
                  </Text>
                </div>
              )}

              {/* Can't use a margin because nested container's margins are collapsed. We can't put the margin on the parent because this child overflowss */}
              <div
                style={{
                  flexBasis: 48,
                  flexShrink: 0,
                }}
              />
            </Column>
            <Column
              gap={16}
              style={{
                flexBasis: 306,
                maxWidth: isMobile ? "unset" : 306,
                flexShrink: 0,
                maxHeight: "100%",
                position: "sticky",
                overflowY: "scroll",
                top: 0,
                flexGrow: 0,
              }}
            >
              <Card
                style={{
                  padding: 24,
                  gap: 8,
                }}
              >
                <Text textStyle="headingXS">Student</Text>
                <Column>
                  {students.data?.data
                    .sort(
                      (a, b) =>
                        (b.score?.correctlyFilledIn ?? 0) -
                        (a.score?.correctlyFilledIn ?? 0)
                    )
                    .map((student) => {
                      return (
                        <span>
                          <StudentRow
                            selected={student.id === currentStudent.id}
                            onClick={() => {
                              setStudentIndex(
                                students.data?.data.findIndex(
                                  (s) => s.id === student.id
                                ) ?? 0
                              );
                            }}
                          >
                            <Text
                              textStyle="caption"
                              key={student.id}
                              style={{ flex: 1 }}
                            >
                              {student.firstName} {student.lastName}
                            </Text>
                            {student.score ? (
                              <Badge>
                                {`${student.score.correctlyFilledIn.toString()} correct`}
                              </Badge>
                            ) : null}
                          </StudentRow>
                        </span>
                      );
                    })}
                </Column>
              </Card>
              <Card
                style={{
                  padding: 24,
                  gap: 16,
                }}
              >
                <Column gap={8}>
                  <Text textStyle="headingXS">Flag for review</Text>
                  <Text>
                    If you see an issue with the grading system, click the
                    button below.
                  </Text>
                </Column>
                <Column gap={8}>
                  <Text textStyle="strong">Which question has an issue?</Text>
                  <Input
                    type="select"
                    value={questionWithIssue?.toString() ?? ""}
                    setValue={(value) => setQuestionWithIssue(value)}
                    disabled={entirePageIsGradedIncorrectly}
                    options={
                      group.data?.numberOfQuestions
                        ? Array.from(
                            { length: group.data.numberOfQuestions },
                            (_, index) => ({
                              value: index.toString(),
                              label: (index + 1).toString(),
                            })
                          )
                        : []
                    }
                  />
                  <label
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      gap: 8,
                      cursor: "pointer",
                    }}
                  >
                    <Checkbox
                      value={entirePageIsGradedIncorrectly}
                      onChange={setEntirePageIsGradedIncorrectly}
                    />
                    <Text>Entire exam is graded incorrectly</Text>
                  </label>
                </Column>
                <Column gap={8}>
                  <Text textStyle="strong">What is the issue?</Text>
                  <Input
                    type="select"
                    inputProps={{
                      style: { maxWidth: "100%" },
                    }}
                    value={issueType ?? ""}
                    setValue={(value) => setIssueType(value as any)}
                    options={[
                      {
                        value: "INCORRECT_IDENTIFICATION",
                        label:
                          "The system incorrectly identified the student’s answer",
                      },
                      {
                        value: "FALSE_POSITIVE",
                        label:
                          "The system identified an answer where none was given",
                      },
                      {
                        value: "FALSE_NEGATIVE",
                        label:
                          "The system failed to identify an answer where one was given",
                      },
                      { value: "OTHER", label: "Other" },
                    ]}
                  />
                  {issueType === "OTHER" ? (
                    <Input
                      value={comment}
                      setValue={setComment}
                      type="text"
                      placeholder="Comment"
                    />
                  ) : null}
                </Column>
                <Tooltip
                  content={
                    !issueType ||
                    (questionWithIssue === null &&
                      !entirePageIsGradedIncorrectly)
                      ? "Please select an issue type and a question"
                      : undefined
                  }
                >
                  <Button
                    variant="primary"
                    text="Add issue"
                    disabled={
                      !issueType ||
                      (questionWithIssue === null &&
                        !entirePageIsGradedIncorrectly)
                    }
                    style={{
                      alignSelf: "flex-start",
                    }}
                    onPress={async () => {
                      createGradingIssue.mutate({
                        pageNumber:
                          questionWithIssue && !entirePageIsGradedIncorrectly
                            ? parseInt(questionWithIssue)
                            : undefined,
                        entirePage: entirePageIsGradedIncorrectly,
                        studentId: currentStudent.id,
                        groupId: groupId,
                        issueType: issueType ?? "INCORRECT_IDENTIFICATION",
                        comment: issueType === "OTHER" ? comment : undefined,
                      });
                      setEntirePageIsGradedIncorrectly(false);
                    }}
                    loading={createGradingIssue.isLoading}
                  />
                </Tooltip>
                {gradingIssues.data?.data.length ? (
                  <>
                    <Divider />
                    {gradingIssues.data?.data
                      .sort((a, b) => {
                        return a.createdAt > b.createdAt ? -1 : 1;
                      })
                      .map((issue) => (
                        <div
                          key={issue.id}
                          style={{
                            backgroundColor:
                              designTokens.colors.surface.subdued,
                            borderRadius: 12,
                            padding: 16,
                          }}
                        >
                          <Row gap={8}>
                            <>
                              <Text textStyle="headingXS">{`${currentStudent.firstName} ${currentStudent.lastName}`}</Text>
                              <Text>
                                {issue.pageNumber !== undefined
                                  ? `Question ${(issue.pageNumber ?? 0) + 1}`
                                  : "Whole test"}
                              </Text>
                            </>
                            <IssueMenu issue={issue} />
                          </Row>
                        </div>
                      ))}
                  </>
                ) : null}
              </Card>
            </Column>
          </>
        ) : (
          <Column gap={8}>
            <Text textStyle="headingXS">Instructions</Text>
            <Text>
              Please carefully look through the results and check for any
              mistakes made by the grading system.
            </Text>
            <Text>
              A red circle will outline the answer that the grading system
              thinks each student has chosen. A circle does not mean that the
              answer is correct or incorrect, simply that the system has
              identified it as the student’s answer.
            </Text>

            <Text>
              Please flag a question for review in any of the following
              situations:
            </Text>
            <ul>
              <li>
                <Text>
                  The grading system has incorrectly identified the student’s
                  answer
                </Text>
              </li>
              <li>
                <Text>
                  The grading system identified an answer where none was given
                </Text>
              </li>
              <li>
                <Text>
                  The grading system failed to identify an answer where an
                  answer was given
                </Text>
              </li>
            </ul>
          </Column>
        )}
      </PaddedContainer>
      {isMobile && (
        <ModalButtonsRow
          style={{
            flexGrow: "unset",
            marginLeft: 36,
            marginRight: 36,
          }}
        >
          <Button
            onPress={
              studentIndex === null
                ? undefined
                : () => {
                    setStudentIndex(
                      studentIndex === 0 ? null : studentIndex - 1
                    );
                  }
            }
            text={
              backButtonIsHidden && studentIndex === null
                ? "Cancel"
                : "Previous"
            }
            disabled={studentIndex === null && !backButtonIsHidden}
            linkProps={
              backButtonIsHidden && studentIndex === null
                ? backLinkOptions
                : undefined
            }
          />
          <Button
            onPress={async () => {
              if (isFinalStudent) {
                groupMutation.mutate({
                  reviewedAt: new Date().toISOString(),
                });
              } else {
                setStudentIndex((studentIndex ?? -1) + 1);
              }
            }}
            text={
              isFinalStudent
                ? "Finish"
                : currentStudent
                ? "Next Student"
                : "Start"
            }
            loading={groupMutation.isLoading}
            variant="primary"
            disabled={students.isLoading}
          />
        </ModalButtonsRow>
      )}
      <CompactModal
        open={instructionsModalOpen}
        close={() => setInstructionsModalOpen(false)}
      >
        <Column gap={16}>
          <Text textStyle="headingXS">Instructions</Text>
          <Text>
            Please carefully look through the results and check for any mistakes
            made by the grading system.
          </Text>
          <Text>
            A red circle will outline the answer that the grading system thinks
            each student has chosen. A circle does not mean that the answer is
            correct or incorrect, simply that the system has identified it as
            the student’s answer.
          </Text>

          <Text>
            Please flag a question for review in any of the following
            situations:
          </Text>
          <ul>
            <li>
              <Text>
                The grading system has incorrectly identified the student’s
                answer
              </Text>
            </li>
            <li>
              <Text>
                The grading system identified an answer where none was given
              </Text>
            </li>
            <li>
              <Text>
                The grading system failed to identify an answer where an answer
                was given
              </Text>
            </li>
          </ul>
        </Column>
      </CompactModal>
    </Column>
  );
};

export default ReviewGrading;

const BRACKET_1_DISTANCE_FROM_TOP = 619;
const BRACKET_1_DISTANCE_FROM_LEFT = 810;
const BRACKET_3_DISTANCE_FROM_LEFT = 5250;
const PAGE_WIDTH = 6120;
const PAGE_HEIGHT = 7920;

const IssueMenu = ({ issue }: { issue: GradingIssue }) => {
  const queryClient = useQueryClient();
  const deleteGradingIssue = useApiDelete(
    "/grading-issues/:id",
    {
      id: issue.id,
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["grading-issues"]);
      },
    }
  );
  return (
    <MeatballMenu
      options={[{ label: "Delete", value: "delete" }]}
      onSelect={(value) => {
        if (value === "delete") {
          deleteGradingIssue.mutate({ id: issue.id });
        }
      }}
    />
  );
};

const TestScanPageViewer = ({
  page,
  student,
}: {
  page: TestScanPage;
  student: Student;
}) => {
  const designTokens = useDesignTokens();
  const [imgDimensions, setImgDimensions] = useState({
    width: 0,
    height: 0,
  });

  const transformScan = useMemo(() => {
    const fiducials = page.fiducialLocations;

    if (!fiducials || !imgDimensions) {
      return null;
    }

    const topLeftFiducial = fiducials.find(
      (fiducial) => fiducial.type === "TOP_LEFT"
    );

    const topRightFiducial = fiducials.find(
      (fiducial) => fiducial.type === "TOP_RIGHT"
    );

    if (!topLeftFiducial || !topRightFiducial) {
      return {};
    }

    const standardDistanceBetweenFiducials =
      BRACKET_3_DISTANCE_FROM_LEFT - BRACKET_1_DISTANCE_FROM_LEFT;
    const scanDistanceBetweenFiducials = Math.sqrt(
      Math.pow(topRightFiducial.x - topLeftFiducial.x, 2) +
        Math.pow(topRightFiducial.y - topLeftFiducial.y, 2)
    );
    const scanScale =
      standardDistanceBetweenFiducials / scanDistanceBetweenFiducials;

    const scaleTransformX = -(
      BRACKET_1_DISTANCE_FROM_LEFT / scanScale -
      topLeftFiducial.x
    );
    const scaleTransformY = -(
      BRACKET_1_DISTANCE_FROM_TOP / scanScale -
      topLeftFiducial.y
    );

    const scanRotation = Math.atan2(
      topRightFiducial.y - topLeftFiducial.y,
      topRightFiducial.x - topLeftFiducial.x
    );

    const percentOfSpaceBetweenFiducials =
      standardDistanceBetweenFiducials / PAGE_WIDTH;
    const scanPercentOfSpaceBetweenFiducials =
      scanDistanceBetweenFiducials / imgDimensions.width;

    return {
      transformOrigin: `0px 0px`,
      transform: `rotate(${scanRotation}rad) translate(${
        (scaleTransformX / imgDimensions.width) * 100
      }%, ${(scaleTransformY / imgDimensions.height) * 100}%) scale(${
        scanPercentOfSpaceBetweenFiducials / percentOfSpaceBetweenFiducials
      })`,
    };
  }, [page, imgDimensions]);

  const pageNumberInTest = page.pageNumberInTest;
  if (pageNumberInTest === undefined) {
    return null;
  }
  return (
    <div
      style={{
        width: "100%",
        position: "relative",
        border: `2px solid ${designTokens.colors.border.subdued}`,
        borderRadius: 12,
        overflow: "hidden",
      }}
    >
      <img
        style={{
          width: "100%",
        }}
        src={page.imageUrl}
        alt={`Test page ${pageNumberInTest} for ${student.firstName} ${student.lastName}`}
        onLoad={(event) => {
          setImgDimensions({
            width: event.currentTarget.naturalWidth,
            height: event.currentTarget.naturalHeight,
          });
        }}
      />
      <div
        style={{
          position: "absolute",
          top: 0,
          left: 0,
          right: 0,
          bottom: `${
            ((imgDimensions.height -
              imgDimensions.width * (PAGE_HEIGHT / PAGE_WIDTH)) /
              imgDimensions.height) *
            100
          }%`,
          ...transformScan,
        }}
      >
        {page.answerCircleLocations
          ?.filter((circle) => circle.page === (pageNumberInTest ?? 0) - 1)
          ?.filter((circle) => circle.filledIn)
          .map((circle, index) => {
            return (
              <div
                key={index}
                style={{
                  position: "absolute",
                  top: `${((circle.y - 4) / 792) * 100}%`,
                  left: `${((circle.x - 4) / 612) * 100}%`,
                  width: `${((circle.width + 8) / 612) * 100}%`,
                  height: `${((circle.height + 8) / 792) * 100}%`,
                  border: `2px solid ${designTokens.colors.brand.critical}`,
                  borderRadius: "50%",
                }}
              />
            );
          })}
      </div>
    </div>
  );
};
