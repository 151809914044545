import { Outlet } from "@tanstack/react-router";
import PageChrome from "../components/page-chrome";

function Dashboard() {
  return (
    <PageChrome>
      <Outlet />
    </PageChrome>
  );
}

export default Dashboard;
