import { useNavigate } from "@tanstack/react-router";
import SchoolInfoForm from "../../forms/school-info";
import { dashboardOverviewRoute } from "../..";
import config from "../../lib/config";

const EditRoles = () => {
  const navigate = useNavigate();

  return (
    <SchoolInfoForm
      onlyAdministrators
      onSubmit={() => {
        navigate({
          to: dashboardOverviewRoute.fullPath,
          params: {
            slug: config.REACT_APP_TEST_SLUG,
          },
        });
      }}
      extraButton={{
        text: "Cancel",
        linkProps: {
          to: dashboardOverviewRoute.fullPath,
          params: {
            slug: config.REACT_APP_TEST_SLUG,
          },
        },
      }}
      backLinkOptions={{
        to: dashboardOverviewRoute.fullPath,
        params: {
          slug: config.REACT_APP_TEST_SLUG,
        },
      }}
    />
  );
};

export default EditRoles;
