import { Outlet, useMatches, useNavigate } from "@tanstack/react-router";
import { useEffect, useMemo } from "react";
import { useLoggedInUser } from "../lib/auth";
import config from "../lib/config";

const unauthenticatedRoutes = [
  "/reset-password",
  "/request-password-reset",
  "/sign-up",
  "/log-in",
  "/",
];

function Root() {
  const navigate = useNavigate();
  const routes = useMatches();

  const user = useLoggedInUser();

  const path = useMemo(() => {
    return routes[1]?.pathname;
  }, [routes]);

  useEffect(() => {
    if (user.status !== "success") {
      return;
    }

    if (user.data) {
      if (user.data.emailVerified) {
        if (path === "/verify-email") {
          navigate({
            to: `/test/$slug/home`,
            params: {
              slug: config.REACT_APP_TEST_SLUG,
            },
          });
        }
        if (unauthenticatedRoutes.includes(path)) {
          navigate({
            to: `/test/$slug/home`,
            params: {
              slug: config.REACT_APP_TEST_SLUG,
            },
          });
        }
      } else {
        navigate({
          to: "/verify-email",
          search: {
            token: "",
          },
        });
      }
    } else {
      if (!unauthenticatedRoutes.includes(path)) {
        navigate({
          to: "/",
        });
      }
    }
  }, [user.data, path, navigate, user.isFetched, user.status]);

  return <Outlet />;
}

export default Root;
