var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useRef, useState } from "react";
import Icon from "./icon";
import Text from "./text";
import { Row } from "./layout";
import displayFileSize from "../lib/display-file-size";
import { useDesignTokens } from "../lib/design-tokens";
var FileInput = function (_a) {
    var style = _a.style, accept = _a.accept, onChange = _a.onChange;
    var _b = useState(null), file = _b[0], setFile = _b[1];
    var fileInputRef = useRef(null);
    var handleFileChange = function (event) {
        var _a;
        var file = (_a = event.target.files) === null || _a === void 0 ? void 0 : _a[0];
        if (file) {
            setFile(file);
        }
        onChange === null || onChange === void 0 ? void 0 : onChange(file !== null && file !== void 0 ? file : null);
    };
    var designTokens = useDesignTokens();
    return (_jsxs("span", __assign({ style: {
            position: "relative",
        } }, { children: [file ? (_jsxs(Row, __assign({ style: {
                    padding: 10,
                    gap: 8,
                    border: "1px solid ".concat(designTokens.colors.border.subdued),
                    borderRadius: 6,
                    alignItems: "center",
                } }, { children: [_jsxs(Row, __assign({ gap: 4, style: { flex: 1 } }, { children: [_jsx("svg", __assign({ width: "20", height: "20", viewBox: "0 0 20 20", fill: "none", xmlns: "http://www.w3.org/2000/svg" }, { children: _jsx("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M2 10C2 5.58172 5.58172 2 10 2C14.4183 2 18 5.58172 18 10C18 14.4183 14.4183 18 10 18C5.58172 18 2 14.4183 2 10ZM14.2071 8.70711C14.5976 8.31658 14.5976 7.68342 14.2071 7.29289C13.8166 6.90237 13.1834 6.90237 12.7929 7.29289L9 11.0858L7.70711 9.79289C7.31658 9.40237 6.68342 9.40237 6.29289 9.79289C5.90237 10.1834 5.90237 10.8166 6.29289 11.2071L8.29289 13.2071C8.68342 13.5976 9.31658 13.5976 9.70711 13.2071L14.2071 8.70711Z", fill: designTokens.colors.brand[100] }) })), _jsx(Text, { children: file.name })] })), _jsx(Text, { children: displayFileSize(file.size) }), _jsx(Icon, { name: "trash-04", style: {
                            cursor: "pointer",
                        }, color: designTokens.colors.text.subdued, onClick: function () {
                            setFile(null);
                            onChange === null || onChange === void 0 ? void 0 : onChange(null);
                            if (fileInputRef.current) {
                                fileInputRef.current.value = "";
                            }
                        } })] }))) : (_jsxs("span", __assign({ style: __assign({ backgroundColor: designTokens.colors.surface.subdued, borderRadius: 8, border: "1px dashed ".concat(designTokens.colors.border.subdued), paddingTop: 74, paddingBottom: 74, paddingLeft: 24, paddingRight: 24, display: "flex", flexDirection: "column", gap: 8, alignItems: "center", cursor: "pointer" }, style) }, { children: [_jsx(Icon, { name: "upload-03", color: designTokens.colors.icon.default, style: {
                            padding: 20,
                        } }), _jsxs(Text, __assign({ textStyle: "caption", style: {
                            color: designTokens.colors.text.subdued,
                        } }, { children: ["Drag & drop files or", " ", _jsx("span", __assign({ style: {
                                    color: designTokens.colors.action.primary,
                                } }, { children: "browse files" }))] })), _jsx(Text, __assign({ textStyle: "caption", style: {
                            color: designTokens.colors.text.subdued,
                        } }, { children: accept === "spreadsheet"
                            ? "CSV, XLS, or XLSX - Max file size 2MB"
                            : "PDF - Max file size 2MB" }))] }))), _jsx("input", { ref: fileInputRef, accept: accept === "spreadsheet" ? ".csv,.xls,.xlsx" : "application/pdf", type: "file", onChange: handleFileChange, style: __assign({ opacity: 0, position: "absolute", top: 0, left: 0, bottom: 0, right: 0, cursor: "pointer" }, (file ? { display: "none" } : {})) })] })));
};
export default FileInput;
