var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import React, { useContext } from "react";
import { ThemeContext } from "styled-components";
var DesignTokensContext = React.createContext({ themeColor: "#000000" });
var StyledComponentsThemeProvider = function (_a) {
    var children = _a.children;
    var designTokens = useDesignTokens();
    return (_jsx(ThemeContext.Provider, __assign({ value: designTokens }, { children: children })));
};
export var DesignTokensProvider = function (_a) {
    var children = _a.children, themeColor = _a.themeColor;
    return (_jsx(DesignTokensContext.Provider, __assign({ value: { themeColor: themeColor } }, { children: _jsx(StyledComponentsThemeProvider, { children: children }) })));
};
export var getDesignTokens = function (themeColor) {
    var gray = {
        0: "#FFFFFF",
        4: "#F6F6F6",
        8: "#ECECEC",
        100: "#141414",
        16: "#D9D9D9",
        12: "#E3E3E3",
        88: "#303030",
        56: "#7B7B7B",
        64: "#696969",
    };
    var brand = {
        100: themeColor,
        // Equivalent to theme color over white at 8% opacity
        8: (function () {
            var rgb = hexToRgb(themeColor);
            var whiteRgb = hexToRgb("#FFFFFF");
            var r = Math.round(rgb.r * 0.08 + whiteRgb.r * 0.92);
            var g = Math.round(rgb.g * 0.08 + whiteRgb.g * 0.92);
            var b = Math.round(rgb.b * 0.08 + whiteRgb.b * 0.92);
            return rgbToHex(r, g, b);
        })(),
        critical: "#C4323A",
    };
    function hexToRgb(hex) {
        var r = parseInt(hex.slice(1, 3), 16);
        var g = parseInt(hex.slice(3, 5), 16);
        var b = parseInt(hex.slice(5, 7), 16);
        return { r: r, g: g, b: b };
    }
    function rgbToHex(r, g, b) {
        return "#".concat(r.toString(16).padStart(2, "0")).concat(g
            .toString(16)
            .padStart(2, "0")).concat(b.toString(16).padStart(2, "0"));
    }
    var surface = {
        background: gray[0],
        subdued: gray[4],
        brand: brand[8],
        dark: gray[100],
        disabled: gray[12],
        darkSubdued: gray[88],
        dark3: gray[64],
    };
    var colors = {
        gray: gray,
        action: {
            critical: "#CC2929",
            disabled: "#E4E4E4",
            primary: themeColor,
            secondary: "#FFFFFF",
            secondaryDisabled: "#E4E4E4",
        },
        border: {
            subdued: gray[16],
            dark: gray[100],
            default: gray[56],
            white: gray[0],
            // Deprecated
            disabled: "#EDEDED",
        },
        brand: __assign({ accent: "#E52EDE", secondary: "#052BF4" }, brand),
        surface: __assign({ action: "#EBEEFE", button: "#141414", default: "#FFFFFF", neutral: "#DADADA", selected: "#F5F6FA" }, surface),
        text: {
            default: gray[100],
            inverted: gray[0],
            subdued: gray[88],
            disabled: gray[64],
            // Deprecated
            success: "#025C20",
            error: "#BA1822",
        },
        icon: {
            default: gray[56],
            white: gray[0],
            dark: gray[100],
            // Deprecated
            disabled: "#DADADA",
            inverted: "#FFFFFF",
            subdued: "#BFBFBF",
        },
        black: "#000000",
        white: "#FFFFFF",
        transparent: "transparent",
    };
    var typography = {
        headingXL: {
            color: colors.text.default,
            fontFamily: "Inter",
            fontSize: 40,
            fontStyle: "normal",
            fontWeight: 700,
            lineHeight: "44px",
            letterSpacing: "-0.8px",
        },
        headingLarge: {
            color: colors.text.default,
            fontSize: 28,
            fontStyle: "normal",
            fontWeight: 700,
            lineHeight: "32px",
            letterSpacing: "-0.56px",
            fontSmooth: "always",
            fontFamily: "Inter",
        },
        headingMedium: {
            color: colors.text.default,
            fontSize: 20,
            fontStyle: "normal",
            fontWeight: 700,
            lineHeight: "24px",
            letterSpacing: "-0.4px",
            fontSmooth: "always",
            fontFamily: "Inter",
        },
        headingSmall: {
            color: colors.text.default,
            fontSize: 18,
            fontStyle: "normal",
            fontWeight: 700,
            lineHeight: "22px",
            letterSpacing: "-0.36px",
            fontSmooth: "always",
            fontFamily: "Inter",
        },
        headingXS: {
            color: colors.text.default,
            fontFamily: "Inter",
            fontSize: 16,
            fontStyle: "normal",
            fontWeight: 700,
            lineHeight: "20px",
            letterSpacing: "-0.32px",
        },
        subHeading: {
            color: colors.text.default,
            fontFamily: "Inter",
            fontSize: 12,
            fontStyle: "normal",
            fontWeight: 600,
            lineHeight: "16px",
            letterSpacing: "1.2px",
            fontSmooth: "always",
            WebkitFontSmoothing: "antialiased",
        },
        body: {
            color: colors.black,
            fontFamily: "Inter",
            fontSize: 14,
            fontStyle: "normal",
            fontWeight: 500,
            lineHeight: "20px",
            letterSpacing: "-0.28px",
            fontSmooth: "always",
            WebkitFontSmoothing: "antialiased",
        },
        bodyLarge: {
            color: colors.black,
            fontFamily: "Inter",
            fontSize: 20,
            fontStyle: "normal",
            fontWeight: 500,
            lineHeight: "140%",
            letterSpacing: "-0.4px",
            fontSmooth: "always",
            WebkitFontSmoothing: "antialiased",
        },
        strong: {
            color: colors.black,
            fontFamily: "Inter",
            fontSize: 14,
            fontStyle: "normal",
            fontWeight: 700,
            lineHeight: "20px",
            letterSpacing: "-0.28px",
            fontSmooth: "always",
            WebkitFontSmoothing: "antialiased",
        },
        caption: {
            color: colors.text.default,
            fontFamily: "Inter",
            fontSize: 12,
            fontStyle: "normal",
            fontWeight: 600,
            lineHeight: "16px",
            letterSpacing: "-0.24px",
            fontSmooth: "always",
            WebkitFontSmoothing: "antialiased",
        },
        button: {
            color: colors.black,
            fontFamily: "Inter",
            fontSize: 14,
            fontStyle: "normal",
            fontWeight: 700,
            lineHeight: "20px",
            fontSmooth: "always",
            WebkitFontSmoothing: "antialiased",
        },
    };
    var breakpoints = {
        xs: 320,
        sm: 640,
        md: 768,
        lg: 1024,
        xl: 1280,
        "2xl": 1536,
    };
    var devices = {
        xs: "(min-width: ".concat(breakpoints.xs, "px)"),
        sm: "(min-width: ".concat(breakpoints.sm, "px)"),
        md: "(min-width: ".concat(breakpoints.md, "px)"),
        lg: "(min-width: ".concat(breakpoints.lg, "px)"),
        xl: "(min-width: ".concat(breakpoints.xl, "px)"),
        "2xl": "(min-width: ".concat(breakpoints["2xl"], "px)"),
    };
    return {
        colors: colors,
        typography: typography,
        breakpoints: breakpoints,
        devices: devices,
    };
};
export var useDesignTokens = function () {
    var context = useContext(DesignTokensContext);
    if (context === undefined) {
        throw new Error("useDesignTokens must be used within a DesignTokensProvider");
    }
    return getDesignTokens(context.themeColor);
};
