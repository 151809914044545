import { useEffect, useState } from "react";
import { useDesignTokens } from "./design-tokens";
var useWindowDimensions = function () {
    var _a = useState({
        height: window.innerHeight,
        width: window.innerWidth,
    }), dimensions = _a[0], setDimensions = _a[1];
    useEffect(function () {
        var handleResize = function () {
            setDimensions({
                height: window.innerHeight,
                width: window.innerWidth,
            });
        };
        window.addEventListener("resize", handleResize);
        return function () { return window.removeEventListener("resize", handleResize); };
    }, []);
    return dimensions;
};
export default useWindowDimensions;
export var useIsMobile = function () {
    var breakpoints = useDesignTokens().breakpoints;
    var dimensions = useWindowDimensions();
    var isMobile = dimensions.width < breakpoints.md;
    return isMobile;
};
export var useWindowIsSmallerThan = function (width) {
    var dimensions = useWindowDimensions();
    var isSmallerThan = dimensions.width < width;
    return isSmallerThan;
};
