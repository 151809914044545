import { useForm } from "@tanstack/react-form";
import {
  Text,
  Modal,
  ModalButtonsRow,
  Button,
  Loader,
  getFormField,
  useDesignTokens,
} from "@gradience/ui";
import { Column, Row } from "@gradience/ui";
import displayDate from "../lib/display-date";
import { isApiError, useApiPost, useApiPut, useApiQuery } from "../lib/api";
import config from "../lib/config";
import { useQueryClient } from "@tanstack/react-query";
import { useNavigate } from "@tanstack/react-router";
import { groupDetailRoute } from "..";
import isGroupConfigured from "../domain/is-group-configured";
import { useMemo, useState } from "react";
import { isAxiosError } from "axios";

const GroupConfigurationModal = ({
  groupId,
  onClose,
  open,
}: {
  open: boolean;
  groupId: string;
  onClose: React.ComponentProps<typeof Modal>["close"];
}) => {
  const designTokens = useDesignTokens();
  const isCreate = groupId === "create";
  const [dateFieldError, setDateFieldError] = useState<string>();
  const [otherError, setOtherError] = useState<string>();
  const updateGroupMutation = useApiPut(
    "/groups/:id",
    {
      id: groupId ?? "",
    },
    {
      onError: (error) => {
        if (isApiError(error)) {
          const testDateError = error.response?.data.errors.find(
            (e) => e.path?.[0] === "testDate"
          );
          if (testDateError) {
            setDateFieldError(testDateError.message);
          }
        }
      },
    }
  );
  const createGroupMutation = useApiPost("/groups", {
    onError: (error) => {
      if (isApiError(error)) {
        const testDateError = error.response?.data.errors.find(
          (e) => e.path?.[0] === "testDate"
        );
        if (testDateError) {
          setDateFieldError(testDateError.message);
        } else {
          // if it's a 400 error, we can show it, otherwise generic contact support
          setOtherError(
            error.response?.status === 400
              ? error.response?.data.errors.map((e) => e.message).join(" ")
              : "An error occurred. Please contact support."
          );
        }
      }
    },
  });
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const group = useApiQuery(
    "/groups/:id",
    {
      id: groupId ?? "",
    },
    { enabled: !isCreate }
  );

  const form = useForm({
    defaultValues: useMemo(
      () => ({
        groupName: group.data?.name
          ? // Would be better to not set the group name on the backend, but this works.
            group.data.name === "Unnamed Group"
            ? ""
            : group.data.name
          : "",
        instructor: {
          firstName: group.data?.instructorFirstName ?? "",
          lastName: group.data?.instructorLastName ?? "",
          email: group.data?.instructorEmail ?? "",
        },
        testDate: group.data?.testDate
          ? new Date(group.data.testDate).toISOString().slice(0, 10)
          : "",
      }),
      [
        group.data?.instructorEmail,
        group.data?.instructorFirstName,
        group.data?.instructorLastName,
        group.data?.name,
        group.data?.testDate,
      ]
    ),
    onSubmit: async (values) => {
      try {
        const res = await (isCreate
          ? createGroupMutation
          : updateGroupMutation
        ).mutateAsync({
          name: values.groupName,
          instructorEmail: values.instructor.email || "",
          instructorFirstName: values.instructor.firstName,
          instructorLastName: values.instructor.lastName,
          testDate: new Date(values.testDate).toISOString(),
          testId: test.data?.id ?? "",
          reviewedAt: undefined,
        } as any);
        form.reset();
        setDateFieldError(undefined);
        setOtherError(undefined);
        queryClient.invalidateQueries(["groups"]);
        navigate({
          to: groupDetailRoute.id,
          params: {
            groupId: res?.id ?? groupId ?? "",
            slug: config.REACT_APP_TEST_SLUG,
          },
        });
      } catch (e) {
        if (!isAxiosError(e)) {
          throw e;
        }
      }
    },
  });

  const FormField = getFormField(form);
  const test = useApiQuery("/tests/:slug", {
    slug: config.REACT_APP_TEST_SLUG,
  });

  return (
    <Modal open={open} close={onClose}>
      {group.isLoading && !isCreate ? (
        <Loader />
      ) : (
        <form.Provider>
          <form
            onSubmit={(e) => {
              e.preventDefault();
              e.stopPropagation();
              void form.handleSubmit();
            }}
            style={{
              display: "flex",
              flexDirection: "column",
              gap: 32,
              width: "100%",
            }}
          >
            <Text textStyle="headingMedium">Group Information</Text>
            <FormField
              label="Group Name"
              name="groupName"
              required
              placeholder="Group Name"
            />
            <Column gap={8}>
              <Text textStyle="strong">Instructor*</Text>
              <Row gap={8}>
                <FormField
                  style={{ flex: 1 }}
                  name="instructor.firstName"
                  required
                  placeholder="First Name"
                />
                <FormField
                  style={{ flex: 1 }}
                  name="instructor.lastName"
                  required
                  placeholder="Last Name"
                />
              </Row>
              <FormField
                name="instructor.email"
                placeholder="Email Address (optional)"
                type="email"
              />
            </Column>
            <Column gap={8}>
              <Text textStyle="strong">Test Date*</Text>
              <Text
                style={{
                  color: designTokens.colors.text.subdued,
                }}
              >
                You may pick any date between{" "}
                {displayDate(test.data?.administrationDateStart)} and{" "}
                {displayDate(test.data?.administrationDateEnd)}. Tests will be
                available for you to print five days before the test date you
                select.
              </Text>
              <FormField
                name="testDate"
                required
                type="date"
                errorText={dateFieldError}
              />
            </Column>
            {otherError && (
              <Text
                style={{
                  color: designTokens.colors.text.error,
                }}
              >
                {otherError}
              </Text>
            )}
            <ModalButtonsRow>
              <Button
                text={
                  isGroupConfigured(group.data)
                    ? "Update Group"
                    : "Create Group"
                }
                type="submit"
                variant="primary"
                loading={form.state.isSubmitting}
              />
              <Button
                loading={form.state.isSubmitting}
                onPress={() => onClose()}
                text="Cancel"
              />
            </ModalButtonsRow>
          </form>
        </form.Provider>
      )}
    </Modal>
  );
};

export default GroupConfigurationModal;
