function stripTimeZone(date: Date): Date;
function stripTimeZone(date: undefined): undefined;
function stripTimeZone(date: Date | undefined): Date | undefined;
function stripTimeZone(date: Date | undefined): Date | undefined {
  if (date === undefined) {
    return date;
  }

  return new Date(date.valueOf() + date.getTimezoneOffset() * 60 * 1000);
}

export default stripTimeZone;
