import { School } from "@gradience/api-types";

export const isSchoolInfoComplete = (
  school: Partial<School> | undefined
): boolean =>
  school?.name !== undefined &&
  school?.address?.city !== undefined &&
  school?.address?.state !== undefined &&
  school?.address?.line1 !== undefined &&
  school?.address?.zip !== undefined &&
  school?.size !== undefined &&
  school?.examAdministratorId !== undefined &&
  school?.headLatinTeacherId !== undefined;

export const isExamAdminInfoComplete = (
  school: Partial<School> | undefined
): boolean =>
  school?.examAdministratorId !== undefined &&
  school?.headLatinTeacherId !== undefined;

export const hasPaymentMethod = (school: Partial<School> | undefined) =>
  school?.paymentMethod !== undefined || Boolean(school?.credit);

export const isOrderComplete = (
  school: Partial<School> | undefined
): school is Partial<School> & {
  paymentMethod: NonNullable<School["paymentMethod"]>;
  numberOfGroups: NonNullable<School["numberOfGroups"]>;
  numberOfStudents: NonNullable<School["numberOfStudents"]>;
} =>
  hasPaymentMethod(school) &&
  school?.numberOfGroups !== undefined &&
  school?.numberOfStudents !== undefined;
