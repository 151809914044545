import {
  Link,
  useNavigate,
  useParams,
  useSearch,
} from "@tanstack/react-router";
import {
  Text,
  Loader,
  Card,
  Button,
  Modal,
  ModalButtonsRow,
  Icon,
  Checkbox,
  DropdownButton,
  FileInput,
  CompactModal,
  Badge,
  Tooltip,
  ClickableText,
  getFormField,
  SummaryItem,
  Column,
  Row,
  useDesignTokens,
  DropdownMenu,
} from "@gradience/ui";
import {
  dashboardHomeRoute,
  dashboardOverviewRoute,
  dashboardResultsRoute,
  groupDetailRoute,
  selectCurriculumRoute,
} from "../../..";
import { useApiDelete, useApiPost, useApiQuery } from "../../../lib/api";
import PageContainer from "../../../components/page-container";
import displayDate from "../../../lib/display-date";
import { ReactNode, useEffect, useMemo, useRef, useState } from "react";
import config from "../../../lib/config";
import { FormApi, useForm } from "@tanstack/react-form";
import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  useReactTable,
} from "@tanstack/react-table";
import { Student } from "@gradience/api-types";
import { useQueryClient } from "@tanstack/react-query";
import readXlsxFile from "read-excel-file";
import isGroupConfigured from "../../../domain/is-group-configured";
import GroupConfigurationModal from "../../../forms/group-configuration-modal";
import useNumberOfQuestions from "../../../domain/use-number-of-questions";
import isGroupCompleted from "../../../domain/is-group-completed";
import { useLoggedInUser } from "../../../lib/auth";
import getToday from "../../../lib/get-today";

const columnHelper = createColumnHelper<Student>();

const GroupDetail = (): ReactNode => {
  const designTokens = useDesignTokens();
  const params = useParams({ from: groupDetailRoute.id });
  const group = useApiQuery(
    "/groups/:id",
    { id: params.groupId },
    {
      // If the study guide is generating, refetch every second
      refetchInterval: (group) =>
        group?.isGeneratingStudyGuide ? 1000 : false,
    }
  );
  const search = useSearch({ from: groupDetailRoute.fullPath });
  const students = useApiQuery(
    "/students",
    {},
    {},
    {
      groupId: params.groupId,
      finalScore: "true",
    }
  );
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const numberOfQuestions = useNumberOfQuestions(group.data?.conceptIds);
  const deleteGroupMutation = useApiDelete(
    "/groups/:id",
    {
      id: params.groupId,
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["groups"]);
        navigate({
          to: dashboardHomeRoute.fullPath,
          params: {
            slug: config.REACT_APP_TEST_SLUG,
          },
        });
      },
    }
  );

  const columns = useMemo(
    () => [
      columnHelper.display({
        id: "selected",
        header: ({ table }) => (
          <Checkbox
            value={table.getIsAllRowsSelected()}
            onChange={(value) => table.toggleAllRowsSelected(value)}
          />
        ),
        cell: ({ row }) => (
          <Checkbox
            value={row.getIsSelected()}
            onChange={(value) => row.toggleSelected(value)}
          />
        ),
      }),
      columnHelper.accessor("firstName", {
        header: "First Name",
        cell: (row) => <Text>{row.getValue()}</Text>,
      }),
      columnHelper.accessor("lastName", {
        header: "Last Name",
        cell: (row) => <Text>{row.getValue()}</Text>,
      }),
      columnHelper.accessor("grade", {
        header: "Grade",
        cell: (row) => <Text>{row.getValue()}</Text>,
      }),
      columnHelper.accessor("finalScore", {
        header: "Score",
        cell: (row) => {
          const score = row.getValue();
          if (!score) {
            return null;
          }
          return (
            <span
              style={{
                display: "flex",
              }}
            >
              <Badge
                style={{
                  alignSelf: "flex-start",
                }}
              >
                {score.adjustedScore < 0
                  ? "0"
                  : score.adjustedScore.toFixed(2).toString()}
              </Badge>
            </span>
          );
        },
      }),
      columnHelper.accessor("finalScore", {
        header: "Percentile",
        cell: (row) => {
          const score = row.getValue();
          if (!score || score.percentile === null) {
            return null;
          }
          return (
            <span
              style={{
                display: "flex",
              }}
            >
              <Badge
                style={{
                  alignSelf: "flex-start",
                }}
                type={
                  score.percentile >= 0.85
                    ? "gold"
                    : score.percentile >= 0.6
                    ? "silver"
                    : "minimal"
                }
                icon={score.percentile >= 0.6 ? "award" : undefined}
              >
                {`${(score.percentile * 100).toFixed(1)}%`}
              </Badge>
            </span>
          );
        },
      }),
    ],
    []
  );

  const { getHeaderGroups, getRowModel, getSelectedRowModel, setRowSelection } =
    useReactTable({
      columns,
      data:
        students.data?.data.sort(
          (a, b) =>
            (b.finalScore?.adjustedScore ?? -1) -
              (a.finalScore?.adjustedScore ?? -1) ?? 0
        ) ?? [],
      getCoreRowModel: getCoreRowModel(),
    });

  const StudentsTable = () => (
    <table
      style={{
        flex: 1,
        borderCollapse: "collapse",
      }}
    >
      <thead>
        {getHeaderGroups().map((headerGroup) => (
          <tr key={headerGroup.id}>
            {headerGroup.headers.map((header) => (
              <th
                key={header.id}
                style={{
                  paddingTop: 14,
                  paddingBottom: 14,
                  paddingLeft: 24,
                  paddingRight: 24,
                  borderTop: `1px solid ${designTokens.colors.border.subdued}`,
                  borderBottom: `1px solid ${designTokens.colors.border.subdued}`,
                  textAlign: "start",
                }}
              >
                <Text textStyle="strong">
                  {header.isPlaceholder
                    ? null
                    : flexRender(
                        header.column.columnDef.header,
                        header.getContext()
                      )}
                </Text>
              </th>
            ))}
            {/* <th
              style={{
                paddingTop: 14,
                paddingBottom: 14,
                paddingLeft: 24,
                paddingRight: 24,
                borderTop: `1px solid ${designTokens.colors.border.subdued}`,
                borderBottom: `1px solid ${designTokens.colors.border.subdued}`,
                textAlign: "start",
              }}
            ></th> */}
          </tr>
        ))}
      </thead>
      <tbody>
        {getRowModel().rows?.map((row) => (
          <tr key={row.id}>
            {row.getVisibleCells()?.map((cell) => (
              <td
                key={cell.id}
                style={{
                  paddingTop: 14,
                  paddingBottom: 14,
                  paddingLeft: 24,
                  paddingRight: 24,
                  borderTop: `1px solid ${designTokens.colors.border.subdued}`,
                }}
              >
                {flexRender(cell.column.columnDef.cell, cell.getContext())}
              </td>
            ))}
            {/* <td
              style={{
                paddingTop: 14,
                paddingBottom: 14,
                paddingLeft: 24,
                paddingRight: 24,
                borderTop: `1px solid ${designTokens.colors.border.subdued}`,
              }}
            >
              <StudentMenu student={row.original} />
            </td> */}
          </tr>
        ))}
      </tbody>
    </table>
  );

  const deleteStudentsMutation = useApiDelete("/student-lists", {});
  const form = useForm<{
    students: { firstName: string; lastName: string; grade: string }[];
  }>({
    onSubmit: () => {},
  });

  const openUploadSpreadsheetModal = () =>
    navigate({
      to: groupDetailRoute.fullPath,
      search: {
        modal: "upload-spreadsheet",
        prev: undefined,
      },
      params: {
        slug: config.REACT_APP_TEST_SLUG,
        groupId: group.data?.id ?? "",
      },
    });
  const openStudentsModal = () =>
    navigate({
      to: groupDetailRoute.fullPath,
      search: {
        modal: "students",
        prev: undefined,
      },
      params: {
        slug: config.REACT_APP_TEST_SLUG,
        groupId: group.data?.id ?? "",
      },
    });
  const [deleteGroupModalOpen, setDeleteGroupModalOpen] = useState(false);
  const test = useApiQuery("/tests/:slug", {
    slug: config.REACT_APP_TEST_SLUG,
  });

  const configurationDatePassed = test.data?.configurationDateEnd
    ? getToday() > new Date(test.data.configurationDateEnd)
    : undefined;

  const ConfigurationDatePassedTooltip = ({
    children,
  }: {
    children: ReactNode;
  }) => {
    if (configurationDatePassed) {
      return (
        <Tooltip content="The configuration date has passed. Please contact support if you need to make changes.">
          {children}
        </Tooltip>
      );
    }
    return <>{children}</>;
  };

  // 5 days before the test date
  const groupPrintDate = new Date(group.data?.testDate ?? "");
  groupPrintDate.setDate(groupPrintDate.getDate() - 5);

  const printDatePassed =
    groupPrintDate && new Date() > new Date(groupPrintDate);
  const testPdfGenerated = Boolean(group.data?.testUrl);

  const user = useLoggedInUser();

  return (
    <PageContainer>
      {group.isLoading || students.isLoading ? (
        <Loader />
      ) : (
        <Column gap={32}>
          <Column
            gap={8}
            style={{
              alignItems: "flex-start",
            }}
          >
            <Link
              style={{
                display: "flex",
                alignItems: "center",
                gap: 8,
                textDecoration: "none",
                flex: 1,
              }}
              to={
                (search.prev === "overview"
                  ? dashboardOverviewRoute
                  : search.prev === "results"
                  ? dashboardResultsRoute
                  : dashboardHomeRoute
                ).fullPath
              }
              params={{
                slug: config.REACT_APP_TEST_SLUG,
              }}
            >
              <Icon
                name="chevron-left"
                color={designTokens.colors.icon.default}
              />
              <Text textStyle="body">Back</Text>
            </Link>
            <Text textStyle="headingLarge">{group.data?.name}</Text>
          </Column>
          <Row
            gap={8}
            style={{
              flexWrap: "wrap",
            }}
          >
            <SummaryItem
              title="Test Date"
              content={displayDate(group.data?.testDate)}
              icon="calendar-check"
            />
            <SummaryItem
              title="Group Size"
              content={students.data?.data.length.toString() ?? ""}
              icon="user-profile-group-filled"
            />
            {numberOfQuestions.data?.numberOfQuestions ? (
              <SummaryItem
                title="Number of Questions"
                content={numberOfQuestions.data.numberOfQuestions.toString()}
                icon="file-question-02"
              />
            ) : null}
          </Row>
          <Row
            gap={48}
            style={{
              flexWrap: "wrap",
            }}
          >
            <Column
              gap={32}
              style={{
                flex: 1,
                flexBasis: 450,
              }}
            >
              <Column gap={16} style={{ display: "none" }}>
                <Text textStyle="headingXS">Test Management</Text>
                {isGroupCompleted(group.data) ? (
                  <>
                    <Card
                      style={{
                        paddingTop: 32,
                        paddingBottom: 32,
                        paddingLeft: 24,
                        paddingRight: 24,
                      }}
                    >
                      <Row
                        gap={32}
                        style={{
                          alignItems: "center",
                          flexWrap: "wrap",
                        }}
                      >
                        <Column gap={4} style={{ flex: 1 }}>
                          <Text textStyle="headingXS">Print Exams</Text>
                          <Text>Available {displayDate(groupPrintDate)}</Text>
                        </Column>
                        <Tooltip
                          style={{
                            display: "flex",
                          }}
                          content={
                            printDatePassed && !testPdfGenerated
                              ? "Your test is being generated. Please check back later today."
                              : undefined
                          }
                        >
                          <Button
                            text="Print"
                            variant="primary"
                            href={group.data?.testUrl}
                            target="_blank"
                            disabled={!printDatePassed || !testPdfGenerated}
                          />
                        </Tooltip>
                      </Row>
                    </Card>
                    {test.data?.coverSheetUrl ? (
                      <Card
                        style={{
                          paddingTop: 32,
                          paddingBottom: 32,
                          paddingLeft: 24,
                          paddingRight: 24,
                        }}
                      >
                        <Row
                          gap={32}
                          style={{
                            alignItems: "center",
                            flexWrap: "wrap",
                          }}
                        >
                          <Column gap={4} style={{ flex: 1 }}>
                            <Text textStyle="headingXS">Cover Sheet</Text>
                            <Text>
                              Print out one cover sheet for each student and
                              place it on top of his or her test on test day.
                            </Text>
                          </Column>

                          <Button
                            text="Print"
                            variant="primary"
                            href={test.data?.coverSheetUrl}
                            target="_blank"
                            disabled={!printDatePassed || !testPdfGenerated}
                          />
                        </Row>
                      </Card>
                    ) : null}
                    {test.data?.administrationInstructionsUrl ? (
                      <Card
                        style={{
                          paddingTop: 32,
                          paddingBottom: 32,
                          paddingLeft: 24,
                          paddingRight: 24,
                        }}
                      >
                        <Row
                          gap={32}
                          style={{
                            alignItems: "center",
                            flexWrap: "wrap",
                          }}
                        >
                          <Column gap={4} style={{ flex: 1 }}>
                            <Text textStyle="headingXS">
                              Administration Instructions
                            </Text>
                            <Text>
                              This document contains the instructions you will
                              need to prepare, administer, and return the exam
                              for grading, as well as the proctor script for
                              test day, and helpful checklist summary at the
                              end.
                            </Text>
                          </Column>

                          <Button
                            text="View"
                            variant="primary"
                            href={test.data?.administrationInstructionsUrl}
                            target="_blank"
                            disabled={!testPdfGenerated}
                          />
                        </Row>
                      </Card>
                    ) : null}
                  </>
                ) : null}
                {group.data?.studyGuideUrl ||
                group.data?.isGeneratingStudyGuide ? (
                  <Card
                    style={{
                      paddingTop: 32,
                      paddingBottom: 32,
                      paddingLeft: 24,
                      paddingRight: 24,
                    }}
                  >
                    <Row
                      gap={32}
                      style={{
                        alignItems: "center",
                        flexWrap: "wrap",
                      }}
                    >
                      <Column gap={4} style={{ flex: 1 }}>
                        <Text textStyle="headingXS">Download Study Guide</Text>
                        <Text>
                          Download a study guide for your students to prepare
                          for the exam.
                        </Text>
                      </Column>
                      <Tooltip
                        style={{
                          display: "flex",
                        }}
                        content={
                          group.data?.isGeneratingStudyGuide
                            ? "Your study guide is being generated, this shouldn't take longer than a minute."
                            : undefined
                        }
                      >
                        <Button
                          text="Download"
                          variant="primary"
                          href={group.data?.studyGuideUrl}
                          disabled={group.data.isGeneratingStudyGuide}
                          target="_blank"
                        />
                      </Tooltip>
                    </Row>
                  </Card>
                ) : null}
                {user.data?.roles.includes("ADMIN") ||
                user.data?.roles.includes("HEAD_LATIN_TEACHER") ? (
                  <Card
                    style={{
                      paddingTop: 32,
                      paddingBottom: 32,
                      paddingLeft: 24,
                      paddingRight: 24,
                    }}
                  >
                    <Row
                      gap={32}
                      style={{
                        alignItems: "center",
                      }}
                    >
                      <Column
                        gap={4}
                        style={{
                          flex: 1,
                        }}
                      >
                        <Text textStyle="headingXS">Configure Tests</Text>
                        <Text>
                          Select which curriculum and chapters you’ve studied to
                          tailor the test to your students’ actual knowledge.
                        </Text>
                        {isGroupConfigured(group.data) ? (
                          <Link
                            style={{
                              textDecoration: "none",
                              color: designTokens.colors.brand[100],
                            }}
                            params={{
                              slug: config.REACT_APP_TEST_SLUG,
                              groupId: group.data?.id ?? "",
                            }}
                            to={selectCurriculumRoute.fullPath}
                            search={{
                              step: -1,
                            }}
                          >
                            <Text
                              style={{
                                color: designTokens.colors.brand[100],
                              }}
                              textStyle="strong"
                            >
                              Edit Configuration
                            </Text>
                          </Link>
                        ) : null}
                      </Column>
                      {isGroupConfigured(group.data) ? (
                        <Badge type="primary" icon="check-02">
                          Complete
                        </Badge>
                      ) : (
                        <ConfigurationDatePassedTooltip>
                          <Button
                            text="Configure"
                            variant="primary"
                            disabled={configurationDatePassed || test.isLoading}
                            onPress={() =>
                              navigate({
                                to: selectCurriculumRoute.fullPath,
                                params: {
                                  slug: config.REACT_APP_TEST_SLUG,
                                  groupId: group.data?.id ?? "",
                                },
                                search: {
                                  step: 0,
                                },
                              })
                            }
                          />
                        </ConfigurationDatePassedTooltip>
                      )}
                    </Row>
                  </Card>
                ) : null}
              </Column>
              <Column gap={16}>
                <Text textStyle="headingXS">Students</Text>
                <Card
                  style={{
                    paddingTop: 32,
                    paddingBottom: 32,
                    paddingLeft: 24,
                    paddingRight: 24,
                  }}
                >
                  <Row
                    gap={8}
                    style={{
                      alignItems: "center",
                    }}
                  >
                    <ConfigurationDatePassedTooltip>
                      <Button
                        text="Upload Spreadsheet"
                        leadingIcon="upload-03"
                        onPress={openUploadSpreadsheetModal}
                        disabled={configurationDatePassed || test.isLoading}
                      />
                    </ConfigurationDatePassedTooltip>
                    <ConfigurationDatePassedTooltip>
                      <Button
                        text="Add Students"
                        onPress={openStudentsModal}
                        disabled={configurationDatePassed || test.isLoading}
                      />
                    </ConfigurationDatePassedTooltip>
                    <span
                      style={{
                        flex: 1,
                        display: "flex",
                        justifyContent: "flex-end",
                      }}
                    >
                      <DropdownButton
                        loading={deleteStudentsMutation.isLoading}
                        onSelect={async (value) => {
                          if (value === "delete") {
                            await deleteStudentsMutation.mutateAsync({
                              studentIds: getSelectedRowModel().rows?.map(
                                (row) => row.original.id
                              ),
                            });
                            queryClient.invalidateQueries(["students"]);
                            queryClient.invalidateQueries(["groups"]);
                            setRowSelection({});
                          }
                        }}
                        options={[
                          {
                            label: "Delete",
                            value: "delete",
                            icon: "trash-04",
                          },
                        ]}
                        disabled={getSelectedRowModel().rows.length === 0}
                        text={`${getSelectedRowModel().rows.length} selected`}
                      />
                    </span>
                  </Row>
                  <Column gap={24}>
                    <StudentsTable />
                    {students.data?.data.length === 0 && (
                      <div
                        style={{
                          backgroundColor: designTokens.colors.surface.subdued,
                          borderRadius: 12,
                          padding: 24,
                        }}
                      >
                        <Text
                          style={{
                            textAlign: "center",
                          }}
                        >
                          No students added yet. You can manually{" "}
                          <ClickableText onClick={openStudentsModal}>
                            add a student
                          </ClickableText>{" "}
                          or{" "}
                          <ClickableText onClick={openUploadSpreadsheetModal}>
                            upload a spreadsheet
                          </ClickableText>
                        </Text>
                      </div>
                    )}
                  </Column>
                </Card>
              </Column>
            </Column>
            <Column
              gap={16}
              style={{
                flexBasis: 345,
                flexShrink: 0,
              }}
            >
              <Text textStyle="headingXS">Group Details</Text>
              <Card
                style={{
                  padding: 24,
                  gap: 24,
                  alignItems: "flex-start",
                }}
              >
                <Column
                  gap={4}
                  style={{
                    alignItems: "flex-start",
                  }}
                >
                  <Badge>Instructor</Badge>
                  <Text textStyle="headingXS">
                    {group.data?.instructorFirstName}{" "}
                    {group.data?.instructorLastName}
                  </Text>
                  {group.data?.instructorEmail ? (
                    <Text>{group.data.instructorEmail}</Text>
                  ) : null}
                </Column>
                <Column
                  gap={8}
                  style={{
                    alignItems: "flex-start",
                  }}
                >
                  <Button
                    text="Edit Group Details"
                    linkProps={{
                      to: groupDetailRoute.fullPath,
                      search: {
                        // Seems to be a react router circular dependency bug when
                        // you navigate to a route within that route's compoennt.
                        modal: "group-details" as unknown as undefined,
                      },
                      params: {
                        slug: config.REACT_APP_TEST_SLUG,
                        groupId: params.groupId,
                      },
                    }}
                  />
                  <Button
                    text="Delete Group"
                    onPress={() => {
                      setDeleteGroupModalOpen(true);
                    }}
                  />
                </Column>
              </Card>
            </Column>
          </Row>
        </Column>
      )}
      <CompactModal
        open={deleteGroupModalOpen}
        close={() =>
          !deleteGroupMutation.isLoading && setDeleteGroupModalOpen(false)
        }
        style={{
          minWidth: 0,
          minHeight: 0,
        }}
      >
        <Column gap={24}>
          <Column gap={8}>
            <Text textStyle="headingSmall">Delete Group</Text>
            <Text>
              Are you sure you want to delete this group? This cannot be undone.
            </Text>
          </Column>
          <Row gap={8}>
            <Button
              loading={deleteGroupMutation.isLoading}
              onPress={() => {
                deleteGroupMutation.mutate({});
              }}
              text="Delete Group"
              variant="primary"
              style={{
                border: 0,
                backgroundColor: designTokens.colors.brand.critical,
              }}
            />
            <Button
              disabled={deleteGroupMutation.isLoading}
              onPress={() => setDeleteGroupModalOpen(false)}
              text="Never Mind"
            />
          </Row>
        </Column>
      </CompactModal>
      <StudentsModal form={form} />
      <UploadSpreadsheetModal form={form} />
      <GroupConfigurationModal
        open={search.modal === "group-details"}
        onClose={() => {
          navigate({
            to: groupDetailRoute.fullPath,
            params: {
              slug: config.REACT_APP_TEST_SLUG,
              groupId: params.groupId,
            },
          });
        }}
        groupId={params.groupId}
      />
    </PageContainer>
  );
};

const BRACKET_1_DISTANCE_FROM_TOP = 619;
const BRACKET_1_DISTANCE_FROM_LEFT = 810;
const BRACKET_3_DISTANCE_FROM_LEFT = 5250;
const PAGE_WIDTH = 6120;

const StudentMenu = ({ student }: { student: Student }) => {
  const [open, setOpen] = useState(false);
  const ref = useRef<HTMLSpanElement>(null);
  const [deleteStudentModalOpen, setDeleteStudentModalOpen] = useState(false);
  const [viewingTestPage, setViewingTestPage] = useState<number>();
  const queryClient = useQueryClient();
  const deleteStudentMutation = useApiDelete(
    "/student-lists",
    {},
    {
      onSuccess: () => {
        setOpen(false);
        setDeleteStudentModalOpen(false);
        queryClient.invalidateQueries(["students"]);
        queryClient.invalidateQueries(["groups"]);
      },
    }
  );
  const designTokens = useDesignTokens();

  const testScanPageQuery = useApiQuery(
    "/test-scan-pages",
    {},
    { enabled: viewingTestPage !== undefined },
    {
      studentId: student.id,
      take: "1",
      skip: viewingTestPage?.toString(),
    }
  );
  const [imgDimensions, setImgDimensions] = useState<{
    width: number;
    height: number;
  }>();

  const transformScan = useMemo(() => {
    const fiducials = testScanPageQuery.data?.data[0]?.fiducialLocations;

    if (!fiducials || !imgDimensions) {
      return null;
    }

    const topLeftFiducial = fiducials.find(
      (fiducial) => fiducial.type === "TOP_LEFT"
    )!;

    const topRightFiducial = fiducials.find(
      (fiducial) => fiducial.type === "TOP_RIGHT"
    )!;

    const standardDistanceBetweenFiducials =
      BRACKET_3_DISTANCE_FROM_LEFT - BRACKET_1_DISTANCE_FROM_LEFT;
    const scanDistanceBetweenFiducials = Math.sqrt(
      Math.pow(topRightFiducial.x - topLeftFiducial.x, 2) +
        Math.pow(topRightFiducial.y - topLeftFiducial.y, 2)
    );
    const scanScale =
      standardDistanceBetweenFiducials / scanDistanceBetweenFiducials;

    const scaleTransformX = -(
      BRACKET_1_DISTANCE_FROM_LEFT / scanScale -
      topLeftFiducial.x
    );
    const scaleTransformY = -(
      BRACKET_1_DISTANCE_FROM_TOP / scanScale -
      topLeftFiducial.y
    );

    const scanRotation = Math.atan2(
      topRightFiducial.y - topLeftFiducial.y,
      topRightFiducial.x - topLeftFiducial.x
    );

    console.log({
      scanScale,
      scaleTransformX,
      scaleTransformY,
      scanRotation,
      image: imgDimensions,
    });

    const percentOfSpaceBetweenFiducials =
      standardDistanceBetweenFiducials / PAGE_WIDTH;
    const scanPercentOfSpaceBetweenFiducials =
      scanDistanceBetweenFiducials / imgDimensions.width;

    return {
      transformOrigin: `0px 0px`,
      transform: `rotate(${scanRotation}rad) translate(${
        (scaleTransformX / imgDimensions.width) * 100
      }%, ${(scaleTransformY / imgDimensions.height) * 100}%) scale(${
        scanPercentOfSpaceBetweenFiducials / percentOfSpaceBetweenFiducials
      })`,
    };
  }, [testScanPageQuery.data?.data, imgDimensions]);

  return (
    <span ref={ref}>
      <Icon onClick={() => setOpen(!open)} name="dot-vertical-filled" />
      {open && (
        <DropdownMenu
          referenceElement={ref.current}
          onSelect={async (value) => {
            switch (value) {
              case "delete":
                setDeleteStudentModalOpen(true);
                break;
              case "view-test":
                setViewingTestPage(0);
                break;
            }
          }}
          setClosed={() => setOpen(false)}
          options={[
            {
              label: "Delete",
              value: "delete",
            },
            {
              label: "View test",
              value: "view-test",
            },
          ]}
        />
      )}
      <CompactModal
        open={deleteStudentModalOpen}
        close={() =>
          !deleteStudentMutation.isLoading && setDeleteStudentModalOpen(false)
        }
        style={{
          minWidth: 0,
          minHeight: 0,
        }}
      >
        <Column gap={24}>
          <Column gap={8}>
            <Text textStyle="headingSmall">Delete Student</Text>
            <Text>
              Are you sure you want to delete this student? This cannot be
              undone.
            </Text>
          </Column>
          <Row gap={8}>
            <Button
              loading={deleteStudentMutation.isLoading}
              onPress={() => {
                deleteStudentMutation.mutate({
                  studentIds: [student.id],
                });
              }}
              text="Delete Student"
              variant="primary"
              style={{
                border: 0,
                backgroundColor: designTokens.colors.brand.critical,
              }}
            />
            <Button
              disabled={deleteStudentMutation.isLoading}
              onPress={() => setDeleteStudentModalOpen(false)}
              text="Never Mind"
            />
          </Row>
        </Column>
      </CompactModal>
      <Modal
        open={viewingTestPage !== undefined}
        close={() => setViewingTestPage(undefined)}
        style={{
          width: "100%",
        }}
      >
        <Column
          gap={24}
          style={{
            flex: 1,
          }}
        >
          <Row
            gap={8}
            style={{
              alignItems: "center",
            }}
          >
            <Text textStyle="headingSmall">View Test</Text>
            <Row
              gap={8}
              style={{
                alignItems: "center",
                justifyContent: "flex-end",
                flex: 1,
              }}
            >
              <Icon
                name="chevron-left"
                onClick={() =>
                  setViewingTestPage(Math.max(0, (viewingTestPage ?? 0) - 1))
                }
              />
              <Text textStyle="strong">Page {(viewingTestPage ?? 0) + 1}</Text>
              <Icon
                name="chevron-right"
                onClick={() => setViewingTestPage((viewingTestPage ?? 0) + 1)}
              />
            </Row>
          </Row>

          {testScanPageQuery.isLoading ? (
            <Loader />
          ) : testScanPageQuery.data?.data[0] ? (
            <div
              style={{
                width: "100%",
                position: "relative",
              }}
            >
              <img
                style={{
                  width: "100%",
                }}
                src={testScanPageQuery.data?.data[0]?.imageUrl}
                alt={`Test page ${(viewingTestPage ?? 0) + 1} for ${
                  student.firstName
                } ${student.lastName}`}
                onLoad={(event) => {
                  setImgDimensions({
                    width: event.currentTarget.naturalWidth,
                    height: event.currentTarget.naturalHeight,
                  });
                }}
              />

              <div
                style={{
                  position: "absolute",
                  top: 0,
                  left: 0,
                  right: 0,
                  bottom: 0,
                  ...transformScan,
                  border: `2px solid ${designTokens.colors.brand.critical}`,
                }}
              >
                {testScanPageQuery.data?.data[0]?.answerCircleLocations
                  ?.filter((circle) => circle.page === (viewingTestPage ?? 0))
                  ?.filter((circle) => circle.filledIn === true)
                  .map((circle, index) => {
                    return (
                      <div
                        key={index}
                        style={{
                          position: "absolute",
                          top: `${((circle.y - 4) / 792) * 100}%`,
                          left: `${((circle.x - 4) / 612) * 100}%`,
                          width: `${((circle.width + 8) / 612) * 100}%`,
                          height: `${((circle.height + 8) / 792) * 100}%`,
                          border: `2px solid ${designTokens.colors.brand.critical}`,
                          borderRadius: "50%",
                        }}
                      />
                    );
                  })}
              </div>
            </div>
          ) : (
            <Text>No more pages to show</Text>
          )}

          <ModalButtonsRow>
            <Button
              text="Close"
              onPress={() => setViewingTestPage(undefined)}
            />
          </ModalButtonsRow>
        </Column>
      </Modal>
    </span>
  );
};

const StudentsModal = ({
  form,
}: {
  form: FormApi<{
    students: { firstName: string; lastName: string; grade: string }[];
  }>;
}) => {
  const designTokens = useDesignTokens();
  const search = useSearch({ from: groupDetailRoute.fullPath });
  const params = useParams({ from: groupDetailRoute.id });
  const navigate = useNavigate();

  const FormField = getFormField(form);
  const onClose = () => {
    navigate({ search: { modal: undefined } });
    form.reset();
  };
  const queryClient = useQueryClient();
  const postStudentListMutation = useApiPost("/student-lists", {
    onSuccess: () => {
      queryClient.invalidateQueries(["students"]);
      queryClient.invalidateQueries(["groups"]);
      form.reset();
    },
  });

  /**
   * Initialize the modal with at least one student. Don't use form default
   * value or it overwrites the values set by the spreadsheet upload modal.
   */
  useEffect(() => {
    if (search.modal === "students" && !form.state.values.students) {
      form.setFieldValue("students", [
        {
          firstName: "",
          lastName: "",
          grade: "",
        },
      ]);
    }
  }, [form, search.modal]);

  return (
    <Modal open={search.modal === "students"} close={onClose}>
      <form.Provider>
        <form
          onSubmit={async (event) => {
            event.preventDefault();
            await postStudentListMutation.mutateAsync({
              groupId: params.groupId,
              students: form.state.values.students?.map((student) => ({
                firstName: student.firstName,
                lastName: student.lastName,
                grade: student.grade,
              })),
            });
            onClose();
          }}
          style={{
            display: "flex",
            flexDirection: "column",
            gap: 32,
            flex: 1,
          }}
        >
          <Text textStyle="headingSmall">Add Students</Text>
          <Column gap={16}>
            <form.Subscribe
              children={(formState) =>
                formState.values.students?.map((student, index) => (
                  <Column gap={8} key={index}>
                    <Text textStyle="strong">Student Info*</Text>
                    <Row
                      gap={8}
                      style={{
                        alignItems: "center",
                      }}
                    >
                      <FormField
                        // This works fine, but the types aren't set up
                        // @ts-ignore
                        name={`students.${index}.firstName`}
                        placeholder="First Name"
                        required
                      />
                      <FormField
                        // @ts-ignore
                        name={`students.${index}.lastName`}
                        placeholder="Last Name"
                        required
                      />
                      <FormField
                        // @ts-ignore
                        name={`students.${index}.grade`}
                        placeholder="Grade"
                        required
                      />
                      <Icon
                        onClick={() => form.removeFieldValue("students", index)}
                        style={{
                          cursor: "pointer",
                        }}
                        name="trash-04"
                        color={designTokens.colors.icon.default}
                      />
                    </Row>
                  </Column>
                ))
              }
            />
            <button
              style={{
                backgroundColor: designTokens.colors.surface.subdued,
                padding: 24,
                borderRadius: 12,
                alignItems: "center",
                justifyContent: "center",
                cursor: "pointer",
                display: "flex",
                gap: 8,
                appearance: "none",
                border: "none",
              }}
              type="button"
              onClick={() => {
                form.pushFieldValue("students", {
                  firstName: "",
                  lastName: "",
                  grade: "",
                });
              }}
            >
              <Icon name="plus-01" color={designTokens.colors.icon.default} />
              <Text
                style={{
                  color: designTokens.colors.text.subdued,
                }}
              >
                Add Another
              </Text>
            </button>
          </Column>
          <ModalButtonsRow>
            <Button
              text="Add"
              type="submit"
              variant="primary"
              loading={form.state.isSubmitting}
            />
            <Button
              loading={form.state.isSubmitting}
              onPress={() => onClose()}
              text="Cancel"
            />
          </ModalButtonsRow>
        </form>
      </form.Provider>
    </Modal>
  );
};

const MAX_ROWS = 200;
const UploadSpreadsheetModal = ({
  form,
}: {
  form: FormApi<{
    students: { firstName: string; lastName: string; grade: string }[];
  }>;
}) => {
  const designTokens = useDesignTokens();
  const search = useSearch({ from: groupDetailRoute.fullPath });
  const params = useParams({ from: groupDetailRoute.id });
  const navigate = useNavigate();
  const [file, setFile] = useState<File | null>(null);
  const onClose = () => {
    navigate({ search: { modal: undefined } });
    setFile(null);
  };

  return (
    <CompactModal open={search.modal === "upload-spreadsheet"} close={onClose}>
      <Column gap={24} style={{ flex: 1 }}>
        <Text textStyle="headingSmall">Upload</Text>
        <FileInput
          key={search.modal}
          accept="spreadsheet"
          onChange={(file) => {
            setFile(file);
          }}
        />
        {file ? (
          <Button
            text="Review Students"
            variant="primary"
            icon="arrow-right"
            onPress={async () => {
              let rows: string[][] = [];
              if (file.name.endsWith(".xlsx") || file.name.endsWith(".xls")) {
                rows = (await readXlsxFile(file))
                  .slice(0, MAX_ROWS)
                  .map((row) => row.map(String));
              } else if (file.name.endsWith(".csv")) {
                rows = (await file.text())
                  .split("\n")
                  .slice(0, MAX_ROWS)
                  .map((row) => row.split(","));
              }
              for (const row of rows) {
                // Skip header row, doesn't need to be too intelligent, low
                // stakes since they can just delete the row in the next step
                if (
                  row[0].toString().trim().toLowerCase() === "first name" &&
                  row[1].toString().trim().toLowerCase() === "last name" &&
                  row[2].toString().trim().toLowerCase() === "grade"
                ) {
                  continue;
                }
                form.pushFieldValue("students", {
                  firstName: row[0]?.toString() ?? "",
                  lastName: row[1]?.toString() ?? "",
                  grade: row[2]?.toString() ?? "",
                });
              }
              setFile(null);

              navigate({
                to: groupDetailRoute.fullPath,
                search: {
                  modal: "students",
                  prev: undefined,
                },
                params: {
                  slug: config.REACT_APP_TEST_SLUG,
                  groupId: params.groupId,
                },
              });
            }}
          />
        ) : (
          <Row gap={8} style={{ alignItems: "flex-start" }}>
            <span
              style={{
                backgroundColor: designTokens.colors.brand[8],
                borderRadius: 12,
                padding: 12,
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Icon
                name="information-circle-contained"
                color={designTokens.colors.brand[100]}
              />
            </span>
            <Column gap={4}>
              <Text textStyle="strong">Format Requirements</Text>
              <Text textStyle="caption">
                Your spreadsheet must be CSV, XLS, or XLSX format with three
                columns - First Name, Last Name, and Grade.
              </Text>
            </Column>
          </Row>
        )}
      </Column>
    </CompactModal>
  );
};

export default GroupDetail;
