var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useRef, useState } from "react";
import Icon from "./icon";
import DropdownMenu from "./dropdown-menu";
var MeatballMenu = function (_a) {
    var options = _a.options, onSelect = _a.onSelect;
    var _b = useState(false), open = _b[0], setOpen = _b[1];
    var ref = useRef(null);
    return (_jsxs("span", __assign({ ref: ref }, { children: [_jsx(Icon, { onClick: function () { return setOpen(!open); }, name: "dot-vertical-filled" }), open && (_jsx(DropdownMenu, { referenceElement: ref.current, onSelect: onSelect, setClosed: function () { return setOpen(false); }, options: options }))] })));
};
export default MeatballMenu;
