import { Text, Badge, Button, Card } from "@gradience/ui";
import { Column, Row } from "@gradience/ui";

const CompletionCard = ({
  complete,
  onPress,
  title,
  description,
  disabled,
}: {
  complete: boolean;
  onPress: () => unknown;
  title: string;
  description: string;
  disabled?: boolean;
}) => {
  return (
    <Card
      style={{
        padding: 24,
      }}
    >
      <Column gap={32}>
        <Column gap={16}>
          <Row
            gap={16}
            style={{
              alignItems: "center",
            }}
          >
            <Text
              textStyle="headingMedium"
              style={{
                flex: 1,
              }}
            >
              {title}
            </Text>
            {complete && (
              <Badge icon="check-02" type="primary">
                Complete
              </Badge>
            )}
          </Row>
          {!complete && <Text>{description}</Text>}
        </Column>
        {!complete && (
          <Button
            variant="primary"
            disabled={disabled}
            onPress={() => {
              onPress();
            }}
            style={{
              alignSelf: "flex-start",
            }}
            text="Start"
          />
        )}
      </Column>
    </Card>
  );
};

export default CompletionCard;
