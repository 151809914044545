import { Text, Card, Loader } from "@gradience/ui";
import PageChrome from "../../components/page-chrome";
import { useApiPost, useApiQuery } from "../../lib/api";
import { useLogin } from "../../lib/auth";
import { useNavigate } from "@tanstack/react-router";

function CustomerService() {
  const schools = useApiQuery("/school-list", {});
  const login = useLogin();
  const navigate = useNavigate();
  const getImpersonationToken = useApiPost("/auth/impersonation-token", {
    onSuccess: (data) => {
      login.mutate(
        {
          username: data.email,
          password: data.token,
        },
        {
          onSuccess: () => {
            navigate({ to: "/" });
            window.location.reload();
          },
        }
      );
    },
  });

  return (
    <PageChrome>
      <div
        style={{
          paddingTop: 11,
          paddingBottom: 11,
        }}
      >
        <Text textStyle="headingLarge">Customer Service</Text>
      </div>
      <Card style={{ padding: 32 }}>
        {schools.data ? (
          <ul>
            {schools.data.map((school) => (
              <li
                key={school.id}
                style={{
                  cursor: "pointer",
                }}
                onClick={() => {
                  getImpersonationToken.mutate({ schoolId: school.id });
                }}
              >
                <Text>{school.name}</Text>
              </li>
            ))}
          </ul>
        ) : (
          <Loader />
        )}
      </Card>
    </PageChrome>
  );
}

export default CustomerService;
