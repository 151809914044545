import { useNavigate } from "@tanstack/react-router";
import SchoolInfoForm from "../../forms/school-info";
import { accountRoute } from "../..";
import config from "../../lib/config";
import PageChrome from "../../components/page-chrome";

const EditOnlySchoolInfo = () => {
  const navigate = useNavigate();

  return (
    <PageChrome>
      <SchoolInfoForm
        hideAdministrators
        onSubmit={() => {
          navigate({
            to: accountRoute.fullPath,
            params: {
              slug: config.REACT_APP_TEST_SLUG,
            },
          });
        }}
        extraButton={{
          text: "Cancel",
          linkProps: {
            to: accountRoute.fullPath,
            params: {
              slug: config.REACT_APP_TEST_SLUG,
            },
          },
        }}
        backLinkOptions={{
          to: accountRoute.fullPath,
          params: {
            slug: config.REACT_APP_TEST_SLUG,
          },
        }}
      />
    </PageChrome>
  );
};

export default EditOnlySchoolInfo;
