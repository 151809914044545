import { Manifest } from "@gradience/api-types/src/manifest";
import { useApiQuery } from "./api";

const useManifest = () => {
  const { data } = useApiQuery("/manifest", {});

  // A copy of the manifest is included in the build under manifest.json
  const localManifest = require("../manifest.json") as Manifest;

  return data ?? localManifest;
};

export default useManifest;
