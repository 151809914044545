import { useEffect, useRef } from "react";
var defaultEvents = ["mousedown", "touchstart"];
var useClickAway = function (el, onClickAway, events) {
    if (events === void 0) { events = defaultEvents; }
    var savedCallback = useRef(onClickAway);
    useEffect(function () {
        savedCallback.current = onClickAway;
    }, [onClickAway]);
    useEffect(function () {
        var handler = function (event) {
            el &&
                !el.contains(event.target) &&
                savedCallback.current(event);
        };
        for (var _i = 0, events_1 = events; _i < events_1.length; _i++) {
            var eventName = events_1[_i];
            on(document, eventName, handler);
        }
        return function () {
            for (var _i = 0, events_2 = events; _i < events_2.length; _i++) {
                var eventName = events_2[_i];
                off(document, eventName, handler);
            }
        };
    }, [el, events]);
};
export default useClickAway;
function on(obj) {
    var args = [];
    for (var _i = 1; _i < arguments.length; _i++) {
        args[_i - 1] = arguments[_i];
    }
    if (obj && obj.addEventListener) {
        obj.addEventListener.apply(obj, args);
    }
}
function off(obj) {
    var args = [];
    for (var _i = 1; _i < arguments.length; _i++) {
        args[_i - 1] = arguments[_i];
    }
    if (obj && obj.removeEventListener) {
        obj.removeEventListener.apply(obj, args);
    }
}
