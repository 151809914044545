var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import { Link } from "@tanstack/react-router";
import styled, { keyframes } from "styled-components";
import { useDesignTokens } from "../lib/design-tokens";
var shimmer = keyframes(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  0% {\n    background-position: -100% 0;\n  }\n  100% {\n    background-position: 100% 0;\n  }\n"], ["\n  0% {\n    background-position: -100% 0;\n  }\n  100% {\n    background-position: 100% 0;\n  }\n"])));
var ShimmerBackground = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  animation-duration: 2.2s;\n  animation-fill-mode: forwards;\n  animation-iteration-count: infinite;\n  animation-name: ", ";\n  animation-timing-function: linear;\n  background: linear-gradient(\n    to right,\n    rgba(189, 189, 189, 0.5) 20%,\n    rgba(228, 228, 228, 0.5) 60%,\n    rgba(189, 189, 189, 0.5) 80%\n  );\n  background-size: 1200px 100%;\n"], ["\n  animation-duration: 2.2s;\n  animation-fill-mode: forwards;\n  animation-iteration-count: infinite;\n  animation-name: ", ";\n  animation-timing-function: linear;\n  background: linear-gradient(\n    to right,\n    rgba(189, 189, 189, 0.5) 20%,\n    rgba(228, 228, 228, 0.5) 60%,\n    rgba(189, 189, 189, 0.5) 80%\n  );\n  background-size: 1200px 100%;\n"])), shimmer);
var Text = function (_a) {
    var _b = _a.textStyle, textStyle = _b === void 0 ? "body" : _b, children = _a.children, style = _a.style, to = _a.to, loading = _a.loading, loadingWidth = _a.loadingWidth;
    var designTokens = useDesignTokens();
    var _style = __assign(__assign(__assign({}, designTokens.typography[textStyle]), { marginBottom: 0, marginTop: 0 }), style);
    var getInner = function () {
        switch (textStyle) {
            case "body":
            case "strong":
            case "bodyLarge":
                return _jsx("p", __assign({ style: _style }, { children: children }));
            case "headingXL":
                return _jsx("h1", __assign({ style: _style }, { children: children }));
            case "headingLarge":
                return _jsx("h2", __assign({ style: _style }, { children: children }));
            case "headingMedium":
                return _jsx("h3", __assign({ style: _style }, { children: children }));
            case "headingSmall":
                return _jsx("h4", __assign({ style: _style }, { children: children }));
            case "headingXS":
                return _jsx("h5", __assign({ style: _style }, { children: children }));
            case "subHeading":
                return _jsx("h6", __assign({ style: _style }, { children: children }));
            case "button":
                return _jsx("span", __assign({ style: _style }, { children: children }));
            case "caption":
                return _jsx("small", __assign({ style: _style }, { children: children }));
        }
    };
    var textStyleToken = designTokens.typography[textStyle];
    // Gets a multiplier based on the line height whether it's a px or a % value.
    // This gives us a consistent height after the text loads in.
    var lineHeightMultiplier = textStyleToken.lineHeight.endsWith("%")
        ? parseInt(textStyleToken.lineHeight.slice(0, -1)) / 100
        : parseInt(textStyleToken.lineHeight.slice(0, -2)) /
            textStyleToken.fontSize;
    if (loading) {
        return (_jsx(ShimmerBackground, { style: __assign({ minWidth: loadingWidth || "10rem", borderRadius: "12px", minHeight: "".concat(textStyleToken.fontSize * lineHeightMultiplier, "px") }, _style) }));
    }
    else if (to) {
        return (_jsx(Link, __assign({ style: {
                textDecoration: "none",
            }, to: to }, { children: getInner() })));
    }
    else {
        return getInner();
    }
};
export default Text;
var templateObject_1, templateObject_2;
