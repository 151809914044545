import { useParams } from "@tanstack/react-router";
import { useApiQuery } from "../../lib/api";
import {
  Loader,
  Button,
  Badge,
  Card,
  Text,
  Column,
  Row,
  useDesignTokens,
} from "@gradience/ui";
import React, { ReactNode } from "react";
import {
  dashboardOrderRoute,
  editRolesRoute,
  editSchoolInfoRoute,
  groupDetailRoute,
} from "../..";
import config from "../../lib/config";
import useSchool from "../../lib/use-school";
import PageContainer from "../../components/page-container";
import {
  isExamAdminInfoComplete,
  isOrderComplete,
  isSchoolInfoComplete,
} from "../../domain/is-school-info-complete";
import formatCurrency from "../../lib/format-currency";
import OrderSummary, {
  PRICE_PER_STUDENT,
  PROCESSING_FEE,
} from "../../components/order-summary";
import displayDate from "../../lib/display-date";
import GroupConfigurationModal from "../../forms/group-configuration-modal";
import isGroupCreated from "../../domain/is-group-created";
import { useLoggedInUser } from "../../lib/auth";

const DashboardOverview = (): ReactNode => {
  const route = useParams({
    from: "/test/$slug/home",
  });

  const test = useApiQuery("/tests/:slug", {
    slug: route.slug,
  });
  const user = useLoggedInUser();

  const school = useSchool();
  const headLatinTeacher = useApiQuery(
    "/users/:id",
    {
      id: school.data?.headLatinTeacherId ?? "",
    },
    {
      enabled:
        Boolean(school.data?.headLatinTeacherId) &&
        user.data?.roles.includes("ADMIN"),
    }
  );
  const examAdministrator = useApiQuery(
    "/users/:id",
    {
      id: school.data?.examAdministratorId ?? "",
    },
    {
      enabled:
        Boolean(school.data?.examAdministratorId) &&
        user.data?.roles.includes("ADMIN"),
    }
  );

  const isLoading =
    school.isInitialLoading || test.isInitialLoading || user.isInitialLoading;
  const [configuringGroup, setConfiguringGroup] = React.useState<string>();
  const schoolInfoIsComplete =
    isSchoolInfoComplete(school.data) && isExamAdminInfoComplete(school.data);

  const configurationIsAvailable = Boolean(
    test.data?.configurationDateStart &&
      new Date() >= new Date(test.data.configurationDateStart)
  );
  const groups = useApiQuery(
    "/groups",
    {},
    { enabled: configurationIsAvailable }
  );
  const designTokens = useDesignTokens();

  const inner =
    isLoading || !test.data ? (
      <Loader />
    ) : (
      <>
        <Text textStyle="headingLarge">Overview</Text>
        <Row
          style={{
            gap: 48,
            flexWrap: "wrap",
          }}
        >
          <Column
            grow
            gap={16}
            style={{
              flexBasis: 0,
              flexGrow: 2,
            }}
          >
            <Text textStyle="headingXS">Groups</Text>
            {configurationIsAvailable ? (
              groups.isLoading ? (
                <Loader />
              ) : isOrderComplete(school.data) ? (
                <Column gap={8}>
                  {groups.data?.data.map((group) => (
                    <Card style={{ padding: 24 }} key={group.id}>
                      <Row
                        gap={8}
                        style={{
                          alignItems: "center",
                        }}
                      >
                        <Column
                          style={{ flex: 1, alignItems: "flex-start" }}
                          gap={8}
                        >
                          <Text textStyle="headingXS">{group.name}</Text>
                          <Row gap={8}>
                            {group.numberOfStudents ? (
                              <Badge>{`${group.numberOfStudents} students`}</Badge>
                            ) : null}
                            {group.testDate ? (
                              <Badge>{displayDate(group.testDate)}</Badge>
                            ) : null}
                            <Badge>{`Group ID #${group.id}`}</Badge>
                          </Row>
                        </Column>
                        <Button
                          text={isGroupCreated(group) ? "View" : "Start"}
                          linkProps={
                            isGroupCreated(group)
                              ? {
                                  to: groupDetailRoute.fullPath,
                                  params: {
                                    groupId: group.id,
                                    slug: config.REACT_APP_TEST_SLUG,
                                  },
                                  search: {
                                    prev: "overview",
                                  },
                                }
                              : undefined
                          }
                          onPress={() => {
                            if (!isGroupCreated(group)) {
                              setConfiguringGroup(group.id);
                            }
                          }}
                          variant={
                            isGroupCreated(group) ? "default" : "primary"
                          }
                          disabled={!configurationIsAvailable}
                        />
                      </Row>
                    </Card>
                  ))}
                  <Button
                    text="New Group"
                    leadingIcon="plus-02"
                    onPress={() => setConfiguringGroup("create")}
                  />
                </Column>
              ) : (
                <div
                  style={{
                    backgroundColor: designTokens.colors.surface.subdued,
                    padding: 32,
                    borderRadius: 12,
                  }}
                >
                  <Text
                    style={{
                      color: designTokens.colors.text.disabled,
                    }}
                  >
                    Groups will show up here once you have placed your ULE
                    order.{" "}
                  </Text>
                </div>
              )
            ) : (
              <div
                style={{
                  borderRadius: 12,
                  padding: 32,
                  backgroundColor: designTokens.colors.surface.subdued,
                }}
              >
                <Text
                  textStyle="body"
                  style={{
                    color: designTokens.colors.text.disabled,
                  }}
                >
                  Configured groups will show up here. Group configuration will
                  be available on{" "}
                  {displayDate(test.data.configurationDateStart)} and you will
                  be able to configure your groups from the home page.
                </Text>
              </div>
            )}
          </Column>
          {user.data?.roles.includes("ADMIN") ? (
            <Column
              gap={32}
              style={{
                flexBasis: 0,
                flexGrow: 1,
              }}
            >
              <Column gap={16}>
                <Text textStyle="headingXS">Teacher Roles</Text>
                <Card
                  style={{
                    padding: 24,
                    alignItems: "flex-start",
                  }}
                >
                  {schoolInfoIsComplete ? (
                    <>
                      <Column
                        gap={4}
                        style={{
                          alignItems: "flex-start",
                        }}
                      >
                        <Badge>Head Latin Teacher</Badge>
                        <Text textStyle="headingXS">
                          {headLatinTeacher.data?.firstName}{" "}
                          {headLatinTeacher.data?.lastName}
                        </Text>
                        <Text>{headLatinTeacher.data?.email}</Text>
                      </Column>
                      <Column
                        gap={4}
                        style={{
                          alignItems: "flex-start",
                        }}
                      >
                        <Badge>Exam Administrator</Badge>
                        <Text textStyle="headingXS">
                          {examAdministrator.data?.firstName}{" "}
                          {examAdministrator.data?.lastName}
                        </Text>
                        <Text>{examAdministrator.data?.email}</Text>
                      </Column>
                      <Button
                        text="Update Roles"
                        linkProps={{
                          to: editRolesRoute.fullPath,
                          params: {
                            slug: config.REACT_APP_TEST_SLUG,
                          },
                        }}
                      />
                    </>
                  ) : (
                    <>
                      <Text
                        style={{
                          color: designTokens.colors.text.subdued,
                        }}
                      >
                        Add your school details and specify the Head Latin
                        Teacher and Exam Administrator.
                      </Text>
                      <Button
                        text="Start"
                        variant="primary"
                        linkProps={{
                          search: {
                            prev: "overview",
                          },
                          to: editSchoolInfoRoute.fullPath,
                          params: {
                            slug: config.REACT_APP_TEST_SLUG,
                          },
                        }}
                      />
                    </>
                  )}
                </Card>
              </Column>
              <Column
                gap={16}
                style={{
                  flexBasis: 0,
                  flexGrow: 1,
                }}
              >
                <Text textStyle="headingXS">Order Summary</Text>
                <Card
                  style={{
                    padding: 24,
                    gap: 24,
                    display: "flex",
                  }}
                >
                  {isOrderComplete(school.data) ? (
                    school.data.receiptUrl ? (
                      <>
                        <Text>Your order has been paid for.</Text>
                        <Button
                          text="View Receipt"
                          href={school.data.receiptUrl}
                        />
                      </>
                    ) : (
                      <>
                        <Text>
                          {school.data.credit ? (
                            <>Your credit balance</>
                          ) : (
                            <>
                              Your card ending in{" "}
                              <b>{school.data?.paymentMethod?.last4}</b>
                            </>
                          )}{" "}
                          will be charged for{" "}
                          {formatCurrency(
                            PRICE_PER_STUDENT * school.data.numberOfStudents +
                              PROCESSING_FEE
                          )}{" "}
                          {/* Add to test settings as billing date */}
                          on {displayDate("2024-03-11 00:00:00")}
                        </Text>
                        <OrderSummary
                          numberOfStudents={school.data.numberOfStudents}
                        />
                        <Button
                          style={{
                            alignSelf: "flex-start",
                          }}
                          text="Change Order"
                          linkProps={{
                            to: dashboardOrderRoute.fullPath,
                            params: {
                              slug: config.REACT_APP_TEST_SLUG,
                            },
                            search: {
                              prev: "overview",
                            },
                          }}
                        />
                      </>
                    )
                  ) : (
                    <>
                      <Text>
                        Provide student and exam group counts, and complete your
                        reservation by entering your credit card information.
                      </Text>
                      <Button
                        text="Start"
                        variant="primary"
                        linkProps={{
                          to: dashboardOrderRoute.fullPath,
                          params: {
                            slug: config.REACT_APP_TEST_SLUG,
                          },
                          search: {
                            prev: "overview",
                          },
                        }}
                        style={{
                          alignSelf: "flex-start",
                        }}
                      />
                    </>
                  )}
                </Card>
              </Column>
            </Column>
          ) : null}
        </Row>
      </>
    );

  return (
    <PageContainer>
      {inner}
      {configuringGroup && (
        <GroupConfigurationModal
          open={true}
          onClose={() => {
            setConfiguringGroup(undefined);
          }}
          groupId={configuringGroup}
        />
      )}
    </PageContainer>
  );
};

export default DashboardOverview;
