import {
  Card,
  Text,
  Icon,
  Loader,
  Column,
  MobileColumn,
  Row,
} from "@gradience/ui";
import { useApiQuery } from "../../lib/api";
import config from "../../lib/config";
import placeholder from "../../images/maps-placeholder.png";
import { useNavigate } from "@tanstack/react-router";
import {
  isExamAdminInfoComplete,
  isSchoolInfoComplete,
} from "../../domain/is-school-info-complete";
import CompletionCard from "../../components/completion-card";
import useSchool from "../../lib/use-school";

const { REACT_APP_OFFLINE_DEVELOPMENT_MODE } = config;

export const dashboardSchoolModals = [
  "school-info",
  "administrators",
  "full-school-info",
] as const;

type ModalKey = (typeof dashboardSchoolModals)[number];

const DashboardSchool = () => {
  const school = useSchool();
  const headLatinTeacher = useApiQuery(
    "/users/:id",
    { id: school.data?.headLatinTeacherId ?? "" },
    { enabled: Boolean(school.data?.headLatinTeacherId) }
  );
  const examAdministrator = useApiQuery(
    "/users/:id",
    { id: school.data?.examAdministratorId ?? "" },
    { enabled: Boolean(school.data?.examAdministratorId) }
  );

  const navigate = useNavigate();

  const openModal = (modal: ModalKey) =>
    navigate({
      to: "/test/$slug/school",
      params: {
        slug: config.REACT_APP_TEST_SLUG,
      },
      search: {
        modal,
      },
    });

  const loading =
    headLatinTeacher.isInitialLoading ||
    examAdministrator.isInitialLoading ||
    school.isInitialLoading;

  const schoolInfoIsComplete =
    isSchoolInfoComplete(school.data) && isExamAdminInfoComplete(school.data);

  return (
    <>
      {loading ? (
        <Loader />
      ) : schoolInfoIsComplete ? (
        <>
          <MobileColumn
            style={{
              gap: 32,
            }}
          >
            <Column
              gap={8}
              style={{
                flex: 1,
              }}
            >
              <span>
                <span
                  style={{
                    alignItems: "center",
                    gap: 16,
                    display: "inline-flex",
                    cursor: "pointer",
                  }}
                  onClick={() => openModal("administrators")}
                >
                  <Text
                    textStyle="headingMedium"
                    style={{
                      lineHeight: "160%",
                    }}
                  >
                    Exam Administrators
                  </Text>
                  <Icon name="pencil" size="small" />
                </span>
              </span>
              <Card
                style={{
                  padding: 32,
                }}
              >
                <Row
                  gap={24}
                  style={{
                    alignItems: "center",
                    flexWrap: "wrap",
                  }}
                >
                  <Column
                    style={{
                      flex: 1,
                    }}
                  >
                    <Text textStyle="headingXS">
                      {" "}
                      {[
                        headLatinTeacher.data?.firstName,
                        headLatinTeacher.data?.lastName,
                      ].join(" ")}
                    </Text>
                    <Text>Head Latin Teacher</Text>
                  </Column>
                  <Text>{headLatinTeacher.data?.email}</Text>
                </Row>
              </Card>
              <Card
                style={{
                  padding: 32,
                }}
              >
                <Row
                  gap={24}
                  style={{
                    alignItems: "center",
                    flexWrap: "wrap",
                  }}
                >
                  <Column
                    style={{
                      flex: 1,
                    }}
                  >
                    <Text textStyle="headingXS">
                      {[
                        examAdministrator.data?.firstName,
                        examAdministrator.data?.lastName,
                      ].join(" ")}
                    </Text>
                    <Text>Exam Administrator</Text>
                  </Column>
                  <Text>{examAdministrator.data?.email}</Text>
                </Row>
              </Card>
            </Column>
            <Column
              style={{
                flexBasis: 410,
              }}
              gap={8}
            >
              <span>
                <span
                  style={{
                    alignItems: "center",
                    gap: 16,
                    display: "inline-flex",
                    cursor: "pointer",
                  }}
                  onClick={() => openModal("school-info")}
                >
                  <Text
                    textStyle="headingMedium"
                    style={{
                      lineHeight: "160%",
                    }}
                  >
                    School Details
                  </Text>
                  <Icon name="pencil" size="small" />
                </span>
              </span>
              <Card
                style={{
                  padding: 40,
                  gap: 32,
                }}
              >
                <Column gap={16}>
                  <Column gap={8}>
                    <Text textStyle="headingSmall">{school.data?.name}</Text>
                    <Text>
                      {school.data?.address.line1}
                      <br />
                      {school.data?.address.line2 && (
                        <>
                          {school.data?.address.line2}
                          <br />
                        </>
                      )}
                      {school.data?.address.city}, {school.data?.address.state}{" "}
                      {school.data?.address.zip}
                    </Text>
                  </Column>
                  <div
                    style={{
                      height: "332px",
                      width: "100%",
                      borderRadius: 8,
                      overflow: "hidden",
                    }}
                  >
                    {REACT_APP_OFFLINE_DEVELOPMENT_MODE ? (
                      <img
                        src={placeholder}
                        alt="map"
                        style={{
                          height: "100%",
                          width: "100%",
                          objectFit: "cover",
                        }}
                      />
                    ) : (
                      <img
                        style={{
                          height: "100%",
                          width: "100%",
                          maxWidth: "332px",
                          objectFit: "contain",
                        }}
                        alt="map"
                        src={`https://maps.googleapis.com/maps/api/staticmap?
                          center=${school.data?.latitude},${school.data?.longitude}
                          &zoom=18
                          &scale=2
                          &size=332x332
                          &tilt=45
                          &markers=color:red%7C${school.data?.latitude},${school.data?.longitude}
                          &key=AIzaSyBryD0Hp7EWVP4JII-VCFBZWgo2opKdVzk`}
                      />
                    )}
                  </div>
                </Column>
                <Column gap={8}>
                  <Text textStyle="headingSmall">Size</Text>
                  <Text>{school.data?.size} students</Text>
                </Column>
              </Card>
            </Column>
          </MobileColumn>
        </>
      ) : (
        <CompletionCard
          onPress={() => openModal("full-school-info")}
          title={"Enter your school information"}
          description={
            "Add your school details and specify the Head Latin Teacher and Exam Administrator."
          }
          complete={schoolInfoIsComplete}
        />
      )}
    </>
  );
};

export default DashboardSchool;
