var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx } from "react/jsx-runtime";
import { useDesignTokens } from "../lib/design-tokens";
var Card = function (_a) {
    var children = _a.children, style = _a.style, _b = _a.as, as = _b === void 0 ? "div" : _b, className = _a.className, formProps = __rest(_a, ["children", "style", "as", "className"]);
    var As = as;
    var designTokens = useDesignTokens();
    return (_jsx(As, __assign({ style: __assign({ display: "flex", flexDirection: "column", alignItems: "flexStart", gap: 32, padding: 56, borderRadius: 12, borderColor: designTokens.colors.border.subdued, borderWidth: 1, borderStyle: "solid", background: designTokens.colors.surface.background }, style) }, (as === "form" ? formProps : {}), { className: className }, { children: children })));
};
export default Card;
