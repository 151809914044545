import { useEffect, useLayoutEffect } from "react";
export var useLockBodyScroll = function (locked) {
    useLayoutEffect(function () {
        if (locked) {
            document.body.style.overflow = "hidden";
        }
        else {
            document.body.style.overflow = "unset";
        }
    }, [locked]);
    // I'm not really sure why, but this fixes an issue where the body is
    // scrollable if the modal is open on page load
    useEffect(function () {
        if (locked) {
            document.body.style.overflow = "hidden";
        }
    }, [locked]);
};
export default useLockBodyScroll;
