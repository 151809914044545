var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useDesignTokens } from "../lib/design-tokens";
import { useIsMobile } from "../lib/use-window-dimensions";
import Card from "./card";
import Icon from "./icon";
import { Row } from "./layout";
import Text from "./text";
/**
 * A card for displaying a small bit of information about an entity.
 */
var SummaryItem = function (_a) {
    var icon = _a.icon, title = _a.title, content = _a.content;
    var designTokens = useDesignTokens();
    var isMobile = useIsMobile();
    return (_jsx(Card, __assign({ style: __assign({ padding: 24 }, (isMobile
            ? {
                flex: 1,
            }
            : {})) }, { children: _jsxs(Row, __assign({ gap: 16, style: {
                alignItems: "center",
            } }, { children: [_jsx("div", __assign({ style: {
                        display: "flex",
                        padding: 10,
                        backgroundColor: designTokens.colors.surface.brand,
                        borderRadius: 12,
                    } }, { children: _jsx(Icon, { name: icon, size: "medium", color: designTokens.colors.brand[100] }) })), _jsxs(Text, __assign({ textStyle: "body" }, { children: [title, ": "] })), _jsx(Text, __assign({ textStyle: "headingMedium" }, { children: content }))] })) })));
};
export default SummaryItem;
