import {
  Text,
  Loader,
  Divider,
  Button,
  Badge,
  Card,
  SummaryItem,
  Column,
  Row,
  useDesignTokens,
  ClickableText,
} from "@gradience/ui";
import {
  Link,
  useNavigate,
  useParams,
  useSearch,
} from "@tanstack/react-router";
import stripTimeZone from "../../lib/strip-time-zone";
import { useApiQuery } from "../../lib/api";
import {
  isExamAdminInfoComplete,
  isOrderComplete,
  isSchoolInfoComplete,
} from "../../domain/is-school-info-complete";
import CompletionCard from "../../components/completion-card";
import { useHasRole, useLoggedInUser } from "../../lib/auth";
import intersect from "../../lib/intersect";
import React, { ReactNode } from "react";
import {
  dashboardHomeRoute,
  dashboardOrderRoute,
  dashboardOverviewRoute,
  editSchoolInfoRoute,
  groupDetailRoute,
  reviewGradingRoute,
} from "../..";
import config from "../../lib/config";
import useSchool from "../../lib/use-school";
import PageContainer from "../../components/page-container";
import displayDate from "../../lib/display-date";
import GroupConfigurationModal from "../../forms/group-configuration-modal";
import isGroupCreated from "../../domain/is-group-created";
import isGroupCompleted from "../../domain/is-group-completed";

const DashboardHome = (): ReactNode => {
  const isExamAdministrator = useHasRole("EXAM_ADMINISTRATOR");
  const designTokens = useDesignTokens();

  const navigate = useNavigate();
  const route = useParams({
    from: "/test/$slug",
  });
  const test = useApiQuery("/tests/:slug", {
    slug: route.slug,
  });

  const school = useSchool();
  const groups = useApiQuery("/groups", {});

  const { configuringGroup } = useSearch({
    from: dashboardHomeRoute.id,
  });

  const isLoading = school.isInitialLoading || test.isInitialLoading;
  const schoolInfoIsComplete =
    isSchoolInfoComplete(school.data) && isExamAdminInfoComplete(school.data);
  const configurationIsAvailable =
    test.data?.configurationDateStart &&
    new Date() >= new Date(test.data.configurationDateStart);

  const SchoolInfoCard = () => (
    <CompletionCard
      onPress={() =>
        navigate({
          to: editSchoolInfoRoute.fullPath,
          params: {
            slug: config.REACT_APP_TEST_SLUG,
          },
        })
      }
      title={"Enter your school information"}
      description={
        "Add your school details and specify the Head Latin Teacher and Exam Administrator."
      }
      complete={schoolInfoIsComplete}
    />
  );
  const OrderInfoCard = () => (
    <CompletionCard
      onPress={() =>
        navigate({
          to: dashboardOrderRoute.fullPath,
          params: {
            slug: config.REACT_APP_TEST_SLUG,
          },
        })
      }
      title={"Reserve Your Tests"}
      description={
        "Provide student and exam group counts, and complete your reservation by entering your credit card information."
      }
      complete={isOrderComplete(school.data)}
    />
  );
  const ConfigureCard = () => (
    <Card
      style={{
        padding: 24,
        display: "flex",
        flexDirection: "column",
        gap: 24,
      }}
    >
      <Row
        style={{
          alignItems: "center",
          flexWrap: "wrap",
          gap: 4,
        }}
      >
        <Text
          textStyle="headingMedium"
          style={{
            flex: 1,
          }}
        >
          Configure Your Groups
        </Text>
        {!configurationIsAvailable && (
          <Text
            style={{
              color: designTokens.colors.text.disabled,
            }}
          >
            Available Starting {displayDate(test.data?.configurationDateStart)}
          </Text>
        )}
      </Row>
      {groups.data?.data.map((group) => (
        <Column gap={16} key={group.id}>
          <React.Fragment>
            <Divider />
            <Row
              gap={8}
              style={{
                alignItems: "center",
              }}
            >
              <Column style={{ flex: 1, alignItems: "flex-start" }} gap={8}>
                <Text textStyle="headingXS">{group.name}</Text>
                <Row gap={8}>
                  {group.numberOfStudents ? (
                    <Badge>{`${group.numberOfStudents} students`}</Badge>
                  ) : null}
                  {group.testDate ? (
                    <Badge>{displayDate(group.testDate)}</Badge>
                  ) : null}
                  <Badge>{`Group ID #${group.id}`}</Badge>
                </Row>
              </Column>
              {isGroupCompleted(group) ? (
                <Badge icon="check-02" type="primary">
                  Complete
                </Badge>
              ) : (
                <Button
                  text={isGroupCreated(group) ? "View" : "Start"}
                  linkProps={
                    isGroupCreated(group)
                      ? {
                          to: groupDetailRoute.fullPath,
                          params: {
                            groupId: group.id,
                            slug: config.REACT_APP_TEST_SLUG,
                          },
                        }
                      : {
                          to: dashboardHomeRoute.fullPath,
                          params: {
                            slug: config.REACT_APP_TEST_SLUG,
                          },
                          search: {
                            configuringGroup: group.id,
                          },
                        }
                  }
                  variant={isGroupCreated(group) ? "default" : "primary"}
                  disabled={!configurationIsAvailable}
                />
              )}
            </Row>
          </React.Fragment>
        </Column>
      ))}
    </Card>
  );
  const ReviewTestsCard = () => (
    <Card
      style={{
        padding: 24,
        display: "flex",
        flexDirection: "column",
        gap: 24,
      }}
    >
      <Row
        style={{
          alignItems: "center",
          flexWrap: "wrap",
          gap: 4,
        }}
      >
        <Text
          textStyle="headingMedium"
          style={{
            flex: 1,
          }}
        >
          Review Grading
        </Text>
        <Text>
          In order to ensure a smooth experience providing results for the ULE
          you need to review answers selected by the ULE automatic grading
          system. If you fail to review your tests during this period, you will
          not be able to review them later.
        </Text>
      </Row>
      {groups.data?.data.map((group) => (
        <Column gap={16} key={group.id}>
          <React.Fragment>
            <Divider />
            <Row
              gap={8}
              style={{
                alignItems: "center",
              }}
            >
              <Column style={{ flex: 1, alignItems: "flex-start" }} gap={8}>
                <Text textStyle="headingXS">{group.name}</Text>
                <Row gap={8}>
                  <Badge>{`Group ID #${group.id}`}</Badge>
                </Row>
                {group.reviewedAt ? (
                  <Link
                    style={{
                      textDecoration: "none",
                    }}
                    to={reviewGradingRoute.fullPath}
                    params={{
                      groupId: group.id,
                      slug: config.REACT_APP_TEST_SLUG,
                    }}
                    search={{
                      student: null,
                    }}
                  >
                    <Text
                      textStyle="caption"
                      style={{
                        color: designTokens.colors.action.primary,
                      }}
                    >
                      Review Grading
                    </Text>
                  </Link>
                ) : null}
              </Column>
              {group.reviewedAt ? (
                <Badge icon="check-02" type="primary">
                  Complete
                </Badge>
              ) : (
                <Button
                  text="Review"
                  linkProps={{
                    to: reviewGradingRoute.fullPath,
                    params: {
                      groupId: group.id,
                      slug: config.REACT_APP_TEST_SLUG,
                    },
                  }}
                  variant="primary"
                />
              )}
            </Row>
          </React.Fragment>
        </Column>
      ))}
    </Card>
  );
  const PrintCard = () => (
    <CompletionCard
      description={`${
        isExamAdministrator ? "You" : "Your Exam Administrator"
      } will receive a notification in March when it’s time to print tests.`}
      title="Print"
      complete={false}
      onPress={() => {
        navigate({
          to: dashboardOverviewRoute.fullPath,
          params: {
            slug: config.REACT_APP_TEST_SLUG,
          },
        });
      }}
      disabled={new Date(test.data?.printDateStart ?? "") > new Date()}
    />
  );
  const NothingToSeeHereCard = () => (
    <CompletionCard
      description="You have no tasks to complete at this time."
      title="All Done"
      complete={true}
      onPress={() => {}}
      disabled
    />
  );

  const upNextSteps = [
    {
      availableToRoles: ["ADMIN"],
      cards: [SchoolInfoCard, OrderInfoCard],
      isComplete:
        isSchoolInfoComplete(school.data) && isOrderComplete(school.data),
    },
    {
      availableToRoles: ["ADMIN", "HEAD_LATIN_TEACHER"],
      cards: [ConfigureCard],
      isComplete: new Date(test.data?.configurationDateEnd ?? "") < new Date(),
    },
    {
      availableToRoles: ["EXAM_ADMINISTRATOR", "ADMIN"],
      cards: [PrintCard],
      isComplete: new Date() > new Date(test.data?.printDateStart ?? ""),
    },
    {
      availableToRoles: ["ADMIN", "HEAD_LATIN_TEACHER", "EXAM_ADMINISTRATOR"],
      cards: [ReviewTestsCard],
      isComplete: new Date() > new Date(test.data?.reviewDateEnd ?? ""),
    },
  ];
  const user = useLoggedInUser();

  const upNext = upNextSteps.find(
    (step) =>
      intersect(step.availableToRoles, user.data?.roles ?? []) &&
      !step.isComplete
  ) ?? {
    cards: [NothingToSeeHereCard],
  };

  const inner =
    isLoading || !test.data ? (
      <Loader />
    ) : (
      <>
        <Column gap={32}>
          <Text textStyle="headingLarge">{test.data.name}</Text>
          <Row
            style={{
              justifyContent: "stretch",
              rowGap: "16px",
              columnGap: "8px",
              flexWrap: "wrap",
            }}
          >
            <SummaryItem
              icon="calendar"
              title="Days Left to Reserve"
              content={Math.max(
                0,
                Math.ceil(
                  (new Date(test.data.reservationDateEnd).getTime() -
                    Date.now()) /
                    (1000 * 60 * 60 * 24)
                )
              ).toString()}
            />
            {school.data?.numberOfStudents !== undefined ? (
              <SummaryItem
                icon="user-profile-group-filled"
                title="Students"
                content={school.data.numberOfStudents.toString()}
              />
            ) : null}
            {school.data?.numberOfGroups !== undefined ? (
              <SummaryItem
                icon="grid-04-filled"
                title="Groups"
                content={school.data.numberOfGroups.toString()}
              />
            ) : null}
          </Row>
        </Column>
        <Row
          style={{
            gap: 48,
            flexWrap: "wrap",
          }}
        >
          <Column
            grow
            gap={16}
            style={{
              flexBasis: 0,
              flexGrow: 2,
            }}
          >
            <Text textStyle="headingXS">Up Next</Text>
            <Column gap={8}>
              {upNext?.cards.map((Card) => (
                <Card key={Card.name} />
              ))}
            </Column>
          </Column>
          <Column
            gap={16}
            style={{
              flexBasis: 0,
              flexGrow: 1,
            }}
          >
            <Text textStyle="headingXS">Timeline</Text>
            <Card
              style={{
                padding: 32,
                paddingInlineStart: 16,
              }}
            >
              <Stepper
                steps={[
                  {
                    title: "Reserve",
                    content: "Claim spots for your students!",
                    startDate: new Date(test.data.reservationDateStart),
                    endDate: new Date(test.data.reservationDateEnd),
                    completed:
                      new Date(test.data.reservationDateStart) < new Date(),
                  },
                  {
                    title: "Configure",
                    content:
                      "Allow your teachers to configure tests based on their curriculum and progress. This is the last chance to register additional students.",
                    startDate: new Date(test.data.configurationDateStart),
                    endDate: new Date(test.data.configurationDateEnd),
                    completed:
                      new Date(test.data.configurationDateEnd) < new Date(),
                  },
                  {
                    title: "Print",
                    content: "Print your tests and administer them.",
                    startDate: new Date(test.data.printDateStart),
                    endDate: new Date(test.data.administrationDateEnd),
                    completed: new Date(test.data.printDateStart) < new Date(),
                  },
                  {
                    title: "Review",
                    content:
                      "Review your students' results and flag any inaccuracies.",
                    startDate: new Date(test.data.reviewDateStart),
                    endDate: new Date(test.data.reviewDateEnd),
                    completed: new Date(test.data.reviewDateStart) < new Date(),
                  },
                  {
                    title: "Get Results",
                    content:
                      "See how many awards your students have won for your school and access test data.",
                    startDate: new Date(test.data.completionDate),
                    completed: new Date(test.data.completionDate) < new Date(),
                  },
                ]}
              />
            </Card>
          </Column>
        </Row>
        {configuringGroup && (
          <GroupConfigurationModal
            open={true}
            onClose={() => {
              navigate({
                to: dashboardHomeRoute.fullPath,
                params: {
                  slug: config.REACT_APP_TEST_SLUG,
                },
              });
            }}
            groupId={configuringGroup}
          />
        )}
      </>
    );

  return <PageContainer>{inner}</PageContainer>;
};

export default DashboardHome;

type StepperStep = {
  title: string;
  content: string;
  startDate: Date;
  endDate?: Date;
  completed: boolean;
};

const Stepper = ({ steps }: { steps: StepperStep[] }) => {
  const StepperItem = ({
    finalStep,
    ...step
  }: { finalStep?: boolean } & StepperStep) => {
    const designTokens = useDesignTokens();
    const startDateOnly = stripTimeZone(step.startDate);
    const endDateOnly = stripTimeZone(step.endDate);
    const startMonth = startDateOnly.toLocaleString("default", {
      month: "short",
    });
    const startDay = startDateOnly.getDate();
    const startYear = startDateOnly.getFullYear();
    const endMonth = endDateOnly
      ? endDateOnly.toLocaleString("default", {
          month: "short",
        })
      : undefined;
    const endDay = endDateOnly?.getDate();
    const endYear = endDateOnly?.getFullYear();

    const showStartYear = startYear !== endYear;

    const startDateFormatted = `${startMonth} ${startDay}${
      showStartYear ? `, ${startYear}` : ""
    }`;
    const endDateFormatted = `${endMonth} ${endDay}, ${endYear}`;

    return (
      <Row
        style={{
          gap: 16,
          alignItems: "flex-start",
          overflow: "visible",
          maxWidth: "100%",
        }}
      >
        <div
          style={{
            paddingTop: 4,
            flexGrow: 0,
            flexShrink: 0,
            width: 48,
            paddingLeft: 16,
            paddingRight: 16,
            height: "100%",
            boxSizing: "border-box",
            position: "relative",
            display: "flex",
          }}
        >
          <svg
            width="16"
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            style={{
              position: "absolute",
              top: 4,
            }}
          >
            <circle
              cx="8"
              cy="8"
              r="8"
              fill={
                step.completed
                  ? designTokens.colors.action.primary
                  : designTokens.colors.border.subdued
              }
              strokeWidth="2"
            />
          </svg>
          {!finalStep && (
            <svg
              width="16"
              style={{
                marginTop: 16,
              }}
              viewBox="0 0 16 1"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              preserveAspectRatio="none"
            >
              <line
                x1="8"
                y1="0"
                x2="8"
                y2="1"
                style={{
                  stroke: step.completed
                    ? designTokens.colors.action.primary
                    : designTokens.colors.border.subdued,
                  strokeWidth: 3,
                }}
              />
            </svg>
          )}
        </div>
        <Column
          gap={8}
          grow
          style={{
            flexWrap: "wrap",
          }}
        >
          <Row gap={8} grow>
            <Text
              textStyle="strong"
              style={{
                flexGrow: 1,
                fontSize: "16px",
                fontWeight: "700",
                lineHeight: "24px",
              }}
            >
              {step.title}
            </Text>
            <div
              style={{
                paddingLeft: 8,
                paddingRight: 8,
                paddingTop: 2,
                paddingBottom: 2,
                borderRadius: 8,
                backgroundColor: step.completed
                  ? designTokens.colors.surface.brand
                  : designTokens.colors.gray[8],
              }}
            >
              <Text
                textStyle="caption"
                style={{
                  whiteSpace: "nowrap",
                  color: step.completed
                    ? designTokens.colors.action.primary
                    : designTokens.colors.text.subdued,
                }}
              >
                {startDateFormatted}
                {step.endDate ? ` – ${endDateFormatted}` : ""}
              </Text>
            </div>
          </Row>
          <Text
            style={{
              ...(finalStep ? {} : { marginBottom: 24 }),
              fontSize: "14px",
              fontWeight: "500",
              lineHeight: "20px",
              color: designTokens.colors.text.subdued,
              letterSpacing: "-0.28px",
            }}
          >
            {step.content}
          </Text>
        </Column>
      </Row>
    );
  };

  return (
    <Column>
      {steps.map((step, index) => {
        return (
          <StepperItem
            key={index}
            {...step}
            finalStep={index === steps.length - 1}
          />
        );
      })}
    </Column>
  );
};
