import { Text, Card, Button, Modal, Input, Column, Row } from "@gradience/ui";
import { useNavigate, useSearch } from "@tanstack/react-router";
import { dashboardAdminsRoute } from "../..";
import config from "../../lib/config";

export const dashboardAdminsModals = ["admins"] as const;
type ModalKey = (typeof dashboardAdminsModals)[number];

const TableCell = ({ children }: { children: string }) => {
  return (
    <td
      style={{
        textAlign: "left",
        paddingTop: 14,
        paddingBottom: 14,
        paddingLeft: 24,
        paddingRight: 24,
        boxShadow: "0px 1px 0px 0px rgba(0, 0, 0, 0.08) inset",
      }}
    >
      <Text>{children}</Text>
    </td>
  );
};

const TableHeader = ({ children }: { children: string }) => {
  return (
    <th
      style={{
        textAlign: "left",
        paddingTop: 14,
        paddingBottom: 14,
        paddingLeft: 24,
        paddingRight: 24,
      }}
    >
      <Text textStyle="strong">{children}</Text>
    </th>
  );
};

const DashboardAdmins = () => {
  const { modal } = useSearch({
    from: dashboardAdminsRoute.id,
  });
  const navigate = useNavigate();
  const closeModal = () =>
    navigate({
      search: {
        modal: undefined,
      },
    });

  const openModal = (modal: ModalKey) =>
    navigate({
      to: dashboardAdminsRoute.fullPath,
      params: {
        slug: config.REACT_APP_TEST_SLUG,
      },
      search: {
        modal,
      },
    });

  return (
    <>
      <Column gap={24}>
        <Row
          style={{
            alignItems: "center",
          }}
        >
          <Text
            style={{
              flexGrow: 1,
            }}
            textStyle="headingMedium"
          >
            Exam Administrators
          </Text>
          <Button text="Edit" onPress={() => openModal("admins")} />
        </Row>
        <Card
          style={{
            padding: 0,
            paddingTop: 8,
            paddingBottom: 8,
          }}
        >
          <table
            style={{
              borderCollapse: "collapse",
            }}
          >
            <thead>
              <TableHeader>Name</TableHeader>
              <TableHeader>Role</TableHeader>
              <TableHeader>Email</TableHeader>
            </thead>
            <tbody>
              <tr>
                <TableCell>Jane Doe</TableCell>
                <TableCell>Exam Administrator</TableCell>
                <TableCell>Jane.Doe@school.org</TableCell>
              </tr>
              <tr>
                <TableCell>John Smith</TableCell>
                <TableCell>Head Latin Teacher</TableCell>
                <TableCell>John.Smith@school.org</TableCell>
              </tr>
            </tbody>
          </table>
        </Card>
      </Column>
      <Modal open={modal === "admins"} close={closeModal}>
        <Column gap={32}>
          <Text textStyle="headingMedium">Exam Administrators</Text>
          <Column gap={12}>
            <Text textStyle="strong">Head Latin Teacher Information</Text>
            <Text textStyle="body">
              This person will receive a link to customize the Latin exams for
              each group. This person will need to be familiar with what
              material each group has studied to customize the exams.
            </Text>
            <Row gap={12}>
              <Input style={{ flex: 1 }} placeholder="First Name" />
              <Input style={{ flex: 1 }} placeholder="Last Name" />
            </Row>
            <Input placeholder="Email" />
          </Column>
          <Column gap={12}>
            <Text textStyle="strong">Exam Administrator Information</Text>
            <Text textStyle="body">
              This person will receive a PDF for each exam group 5 days before
              the exam time and will be responsible for distributing the exam
              and collecting results.
            </Text>
            <Row gap={12}>
              <Input style={{ flex: 1 }} placeholder="First Name" />
              <Input style={{ flex: 1 }} placeholder="Last Name" />
            </Row>
            <Input placeholder="Email" />
          </Column>
          <Row gap={16}>
            <Button
              text="Save"
              disabled
              type="submit"
              variant="primary"
              size="large"
            />
            <Button text="Cancel" onPress={closeModal} size="large" />
          </Row>
        </Column>
      </Modal>
    </>
  );
};

export default DashboardAdmins;
