import { Text, Icon, useDesignTokens } from "@gradience/ui";
import { Link, ToPathOption, useMatchRoute } from "@tanstack/react-router";
import { styled } from "styled-components";
import { useEffect, useState } from "react";
import { useIsMobile } from "../lib/use-window-dimensions";
import config from "../lib/config";
import useManifest from "../lib/use-manifest";
import { useHover } from "@uidotdev/usehooks";
import { Column, Row } from "@gradience/ui";
import { useApiQuery } from "../lib/api";
import { useLoggedInUser } from "../lib/auth";
import { useLockBodyScroll } from "@gradience/ui";
import stripTimeZone from "../lib/strip-time-zone";

const NavContainer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;

  @media only screen and ${({ theme }) => theme.devices.md} {
    position: static;
  }
`;

const HelpAndSupportContainer = styled.div`
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  flex-grow: 1;
  justify-content: flex-end;
  @media only screen and ${({ theme }) => theme.devices.md} {
    align-items: stretch;
  }
`;

const ImageContainer = styled.div`
  display: flex;
  margin-left: 18px;
  margin-top: 28px;
  margin-bottom: 33px;
  margin-right: 24px;
  gap: 4px;
  align-items: center;
  @media only screen and ${({ theme }) => theme.devices.md} {
    margin-left: 24px;
    margin-top: 40px;
    margin-bottom: 57px;
  }
`;

function Navbar() {
  const [open, setOpen] = useState(true);
  const route = window.location.pathname;
  const isMobile = useIsMobile();
  const manifest = useManifest();
  const test = useApiQuery("/tests/:slug", {
    slug: config.REACT_APP_TEST_SLUG,
  });
  const designTokens = useDesignTokens();

  // Close the navbar when navigating
  useEffect(() => {
    setOpen(false);
  }, [route]);

  useLockBodyScroll(open && isMobile);

  return !isMobile || open ? (
    <NavContainer
      style={{
        backgroundColor: designTokens.colors.surface.subdued,
        flexDirection: "column",
        flexGrow: 0,
        flexShrink: 0,
        flexBasis: 267,
        boxSizing: "border-box",
        borderRight: `1px solid ${designTokens.colors.border.subdued}`,
      }}
    >
      <span
        style={{
          position: "sticky",
          top: 0,
          display: "flex",
          flexDirection: "column",
          height: "100dvh",
          paddingBottom: 8,
          boxSizing: "border-box",
        }}
      >
        <ImageContainer>
          <Link style={{ flex: 1 }} to="/">
            <img
              src={manifest.logoUrl}
              alt={`${manifest.name} Logo`}
              style={{
                maxWidth: "11.8125rem",
              }}
            />
          </Link>
          {isMobile && (
            <Icon
              name="close"
              onClick={() => setOpen(false)}
              style={{
                justifySelf: "flex-end",
              }}
            />
          )}
        </ImageContainer>
        <Column
          style={{
            margin: 8,
            flex: 1,
          }}
        >
          <Row
            style={{
              paddingLeft: 8,
              paddingRight: 8,
              paddingTop: 16,
              paddingBottom: 16,
              alignItems: "center",
            }}
            gap={4}
          >
            {test.data?.testLogoUrl && (
              <img
                src={test.data.testLogoUrl}
                alt={`${test.data.name} Logo`}
                style={{
                  padding: 4,
                  maxWidth: 24,
                  maxHeight: 24,
                }}
              />
            )}
            <Text textStyle="strong" loading={test.isLoading}>
              {test.data?.name}
            </Text>
          </Row>
          <div style={{ marginLeft: 24, marginRight: 24, flex: 1 }}>
            <NavLink
              to={`/test/${config.REACT_APP_TEST_SLUG}/home`}
              text="Home"
            />
            <NavLink
              to={`/test/${config.REACT_APP_TEST_SLUG}/overview`}
              text="Overview"
              available={
                typeof test.data?.reservationDateStart === "string"
                  ? new Date(test.data.reservationDateStart)
                  : undefined
              }
            />
            <NavLink
              text="Upload"
              to={`/test/${config.REACT_APP_TEST_SLUG}/upload`}
              available={
                typeof test.data?.administrationDateStart === "string"
                  ? new Date(test.data.administrationDateStart)
                  : undefined
              }
            />
            <NavLink
              text="Results"
              to={`/test/${config.REACT_APP_TEST_SLUG}/results`}
              available={
                typeof test.data?.completionDate === "string"
                  ? new Date(test.data.completionDate)
                  : undefined
              }
            />
            <NavLink
              href="https://instituteforclassicallanguages.org/latin-exam/"
              text="About"
            />
          </div>
          <HelpAndSupportContainer>
            <AccountAndSupportLink />
          </HelpAndSupportContainer>
        </Column>
      </span>
    </NavContainer>
  ) : (
    <Icon
      name="menu"
      onClick={() => setOpen(true)}
      style={{
        position: "absolute",
        top: 32,
        right: 24,
      }}
    />
  );
}

export default Navbar;

const AccountAndSupportLink = () => {
  const matchRoute = useMatchRoute();
  const designTokens = useDesignTokens();
  const [ref, hovering] = useHover();
  const user = useLoggedInUser();

  return (
    <Link
      ref={ref}
      to="/account"
      style={{
        display: "flex",
        borderRadius: 12,
        alignItems: "center",
        paddingTop: 16,
        paddingBottom: 16,
        paddingLeft: 20,
        paddingRight: 20,
        textDecoration: "none",
        flexDirection: "row",
        gap: 10,
        backgroundColor:
          hovering || matchRoute({ to: "/account" })
            ? designTokens.colors.surface.disabled
            : undefined,
      }}
    >
      <Row
        gap={8}
        style={{
          alignItems: "center",
        }}
      >
        <span
          style={{
            padding: 2,
          }}
        >
          <div
            style={{
              height: 28,
              width: 28,
              borderRadius: "50%",
              backgroundColor: designTokens.colors.brand["100"],
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Text
              textStyle="caption"
              style={{
                color: designTokens.colors.white,
              }}
            >
              {user.data?.firstName?.[0]}
              {user.data?.lastName?.[0]}
            </Text>
          </div>
        </span>
        <Text
          textStyle="body"
          style={{
            fontWeight: matchRoute({ to: "/account" }) ? 700 : 500,
          }}
        >
          Account & Support
        </Text>
      </Row>
    </Link>
  );
};

const NavLink = ({
  to,
  text,
  onPress,
  href,
  available,
  style,
}: {
  to?: ToPathOption;
  onPress?: () => void;
  text: string;
  href?: string;
  available?: Date;
  style?: React.CSSProperties;
}) => {
  const matchRoute = useMatchRoute();
  const designTokens = useDesignTokens();
  const Component = to ? Link : "a";
  const [ref, hovering] = useHover();
  const disabled = available && new Date() < stripTimeZone(available);

  return (
    <Component
      ref={ref}
      style={{
        display: "flex",
        borderRadius: 12,
        alignItems: "center",
        paddingTop: 12,
        paddingBottom: 12,
        paddingLeft: 20,
        paddingRight: 20,
        textDecoration: "none",
        flexDirection: "row",
        gap: 10,
        backgroundColor:
          (hovering && !disabled) || (to && matchRoute({ to }))
            ? designTokens.colors.surface.disabled
            : undefined,
        ...style,
      }}
      to={to}
      onClick={onPress}
      href={href}
      disabled={disabled}
      target={href ? "_blank" : undefined}
    >
      <Text
        textStyle="body"
        style={{
          color: designTokens.colors.text[disabled ? "disabled" : "default"],
          fontWeight: to && matchRoute({ to }) ? 700 : 500,
        }}
      >
        {text}
      </Text>
    </Component>
  );
};
