import { Text, Button, useDesignTokens } from "@gradience/ui";
import AuthChrome from "../components/auth-chrome";

function NotFoundPage() {
  const designTokens = useDesignTokens();
  return (
    <AuthChrome>
      <div
        style={{
          flexGrow: 1,
          display: "flex",
          flexDirection: "column",
          gap: 32,
        }}
      >
        <Text
          textStyle="headingXL"
          style={{
            color: designTokens.colors.text.default,
            fontSize: 72,
            fontStyle: "normal",
            fontWeight: 600,
            lineHeight: "80%",
            letterSpacing: "-1.8px",
            fontSmooth: "always",
            fontFamily: "Crimson Pro",
          }}
        >
          Page Not Found
        </Text>

        <Text textStyle="bodyLarge">You found a page that doesn't exist.</Text>
        <div
          style={{
            display: "flex",
            gap: 12,
            flexDirection: "row",
            alignItems: "center",
          }}
        >
          <Button text="Go home" size="large" to="/" />
        </div>
      </div>
    </AuthChrome>
  );
}

export default NotFoundPage;
