import { Button, Text, useDesignTokens } from "@gradience/ui";
import HomePageChrome from "../components/home-page-chrome";
import useManifest from "../lib/use-manifest";

function LandingPage() {
  const manifest = useManifest();
  const designTokens = useDesignTokens();

  return (
    <HomePageChrome>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          gap: 32,
        }}
      >
        <Text
          textStyle="headingLarge"
          style={{
            color: designTokens.colors.text.default,
            fontSize: 72,
            fontStyle: "normal",
            fontWeight: 600,
            lineHeight: "80%",
            letterSpacing: "-1.8px",
            fontSmooth: "always",
            fontFamily: "Crimson Pro",
          }}
        >
          {manifest.homePageHeading}
        </Text>

        <Text textStyle="bodyLarge">{manifest.homePageText}</Text>
        <div
          style={{
            display: "flex",
            gap: 12,
            flexDirection: "row",
            alignItems: "center",
            flexWrap: "wrap",
          }}
        >
          <Button variant="primary" text="Sign Up" size="large" to="/sign-up" />
          <Button text="Log In" size="large" to="/log-in" />
        </div>
      </div>
    </HomePageChrome>
  );
}

export default LandingPage;
