import { Group } from "@gradience/api-types";

/**
 * A group is configured once concepts are selected. These may be selected
 * manually in which case no curriculum is chosen.
 */
const isGroupConfigured = (
  group: Group | undefined
): group is Group & {
  conceptIds: NonNullable<Group["conceptIds"]>;
} => {
  return Boolean(group?.conceptIds?.length);
};

export default isGroupConfigured;
