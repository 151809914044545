import { Link, LinkOptions } from "@tanstack/react-router";
import { Text, Icon, useDesignTokens } from "@gradience/ui";
import { Row } from "@gradience/ui";
import { useIsMobile } from "../lib/use-window-dimensions";
import styled from "styled-components";
import PageContainer from "./page-container";

const TopNavigationRow = styled.div`
  position: sticky;
  top: 0;
  z-index: 1;
  background-color: ${({ theme }) => theme.colors.surface.background};

  align-items: center;
  border-bottom: 1px solid ${({ theme }) => theme.colors.border.subdued};
  gap: 16px;
  display: flex;
  flex-direction: row;

  padding-left: 20px;
  padding-right: 20px;
  padding-top: 32px;
  padding-bottom: 32px;
  container-type: inline-size;
  justify-content: center;

  @media only screen and ${({ theme }) => theme.devices.md} {
    padding-left: 40px;
    padding-right: 40px;
    min-height: 46px;
  }
`;

const MarginSizeContainer = styled.span`
  flex: 1;
  /* Max width of content div + padding on content div + gap between back button
  and content div + width of back button */
  @container (width < 945px) {
    display: none;
  }
`;

const TopNavigation = ({
  title,
  subtitle,
  backLinkOptions,
  children,
}: {
  title: string;
  subtitle?: string;
  backLinkOptions: LinkOptions;
  children?: React.ReactNode;
}) => {
  const isMobile = useIsMobile();
  const designTokens = useDesignTokens();

  return (
    <TopNavigationRow>
      {!isMobile && (
        <MarginSizeContainer>
          <Link
            style={{
              display: "flex",
              alignItems: "center",
              gap: 8,
              textDecoration: "none",
              flex: 1,
            }}
            {...backLinkOptions}
          >
            <Icon
              name="chevron-left"
              color={designTokens.colors.icon.default}
            />
            <Text textStyle="body">Back</Text>
          </Link>
        </MarginSizeContainer>
      )}
      <Row
        style={{
          flexBasis: 789,
          alignItems: "center",
        }}
      >
        <Row
          gap={12}
          style={{
            flexGrow: 1,
            alignItems: "center",
          }}
        >
          <Text textStyle="headingLarge">{title}</Text>
          {!isMobile && (
            <Text
              textStyle="body"
              style={{ color: designTokens.colors.text.subdued, flexGrow: 1 }}
            >
              {subtitle}
            </Text>
          )}
        </Row>
        {!isMobile && <>{children}</>}
      </Row>
      {!isMobile && (
        <MarginSizeContainer
          style={{
            flex: 1,
          }}
        />
      )}
    </TopNavigationRow>
  );
};

export default TopNavigation;

/**
 * Used under a top nav to render content aligned with the title in the top nav.
 */
export const ContentUnderTopNav = ({
  children,
  style,
  pageContainerStyle,
}: {
  children: React.ReactNode;
  style?: React.CSSProperties;
  pageContainerStyle?: React.CSSProperties;
}) => (
  <PageContainer style={pageContainerStyle}>
    <div
      style={{
        maxWidth: 789,
        alignSelf: "center",
        width: "100%",
        ...style,
      }}
    >
      {children}
    </div>
  </PageContainer>
);
