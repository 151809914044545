var displayFileSize = function (size) {
    if (size < 1024) {
        return "".concat(size, "B");
    }
    if (size < 1024 * 1024) {
        return "".concat((size / 1024).toFixed(1), "KB");
    }
    return "".concat((size / 1024 / 1024).toFixed(1), "MB");
};
export default displayFileSize;
