import { styled } from "styled-components";
import { Text } from "@gradience/ui";
import gradience from "../images/gradience.png";

export const PaddedContainer = styled.div`
  flex-grow: 1;
  padding: 20px;
  padding-top: 40px;
  padding-bottom: 40px;
  display: flex;
  flex-grow: 1;
  flex-shrink: 0;
  flex-basis: 50%;
  box-sizing: border-box;
  flex-direction: column;
  gap: 32px;
  overflow: scroll;
  max-width: 100%;

  @media only screen and ${({ theme }) => theme.devices.md} {
    padding-top: 56px;
    padding-left: 40px;
    padding-right: 40px;
    padding-bottom: 56px;
    gap: 58px;
    flex-grow: 1;
  }
`;

const PageContainer = ({
  children,
  style,
}: {
  children: React.ReactNode;
  style?: React.CSSProperties;
}) => {
  return (
    <PaddedContainer style={style}>
      <>
        {children}
        <div
          style={{
            flexDirection: "row",
            gap: 8,
            justifyContent: "center",
            display: "flex",
            alignSelf: "flex-end",
            paddingTop: 51,
            flexGrow: 1,
            alignItems: "flex-end",
            cursor: "pointer",
          }}
          onClick={() => {
            window.open(
              "mailto:build@gradience.co?subject=I%20have%20a%20a%20software%20development%20project%20I%20would%20like%20to%20discuss"
            );
          }}
        >
          <Text textStyle="caption">Powered by</Text>
          <img
            src={gradience}
            alt="Gradience Logo"
            style={{ height: "1rem" }}
          />
        </div>
      </>
    </PaddedContainer>
  );
};

export default PageContainer;
