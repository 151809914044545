import formatCurrency from "../lib/format-currency";
import { Divider, Text } from "@gradience/ui";
import { Column, Row } from "@gradience/ui";

export const PRICE_PER_STUDENT = 450;
export const PROCESSING_FEE = 3500;

const OrderSummary = ({
  numberOfStudents,
}: {
  numberOfStudents: number | undefined | null;
}) => {
  const _numberOfStudents = numberOfStudents || 0;

  return (
    <>
      <Column gap={8}>
        <Row gap={4}>
          <Text
            style={{
              flex: 1,
            }}
          >
            Price per student
          </Text>
          <Text>{formatCurrency(PRICE_PER_STUDENT)}</Text>
        </Row>
        <Row gap={4}>
          <Text
            style={{
              flex: 1,
            }}
          >
            # of Students
          </Text>
          <Text>{_numberOfStudents}</Text>
        </Row>
      </Column>
      <Divider />
      <Column gap={8}>
        <Row gap={4}>
          <Text
            style={{
              flex: 1,
            }}
          >
            Tests
          </Text>
          <Text>{formatCurrency(_numberOfStudents * PRICE_PER_STUDENT)}</Text>
        </Row>
        <Row gap={4}>
          <Text
            style={{
              flex: 1,
            }}
          >
            Processing Fee
          </Text>
          <Text>{formatCurrency(PROCESSING_FEE)}</Text>
        </Row>
        <Row gap={4}>
          <Text
            textStyle="strong"
            style={{
              flex: 1,
            }}
          >
            Order Total
          </Text>
          <Text textStyle="strong">
            {formatCurrency(
              _numberOfStudents * PRICE_PER_STUDENT + PROCESSING_FEE
            )}
          </Text>
        </Row>
      </Column>
    </>
  );
};

export default OrderSummary;
