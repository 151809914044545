import { Text, Card, Button, Loader, useDesignTokens } from "@gradience/ui";
import { Column, Row } from "@gradience/ui";
import PageChrome from "../components/page-chrome";
import { useLoggedInUser, useLogout } from "../lib/auth";
import useSchool from "../lib/use-school";
import PageContainer from "../components/page-container";
import { editOnlySchoolInfoRoute, editSchoolInfoRoute } from "..";
import { isSchoolInfoComplete } from "../domain/is-school-info-complete";
import config from "../lib/config";
import { ReactNode } from "react";

const Account = (): ReactNode => {
  const designTokens = useDesignTokens();
  const school = useSchool();
  const user = useLoggedInUser();
  const isLoading = school.isInitialLoading;
  const logout = useLogout();

  return (
    <PageChrome>
      <PageContainer>
        <Text textStyle="headingLarge">Account & Support</Text>
        <Row
          style={{
            gap: 48,
            flexWrap: "wrap",
          }}
        >
          <Column
            grow
            gap={16}
            style={{
              flexBasis: 0,
              flexGrow: 2,
            }}
          >
            <Text textStyle="headingXS">Support</Text>
            <Card
              style={{
                padding: 24,
              }}
            >
              <Row
                style={{
                  flexWrap: "wrap",
                  alignItems: "center",
                  justifyContent: "flex-start",
                }}
                gap={32}
              >
                <Column
                  style={{
                    flexBasis: 400,
                    flexGrow: 1,
                  }}
                  gap={8}
                >
                  <Row>
                    <Text textStyle="headingMedium">ULE Questions</Text>
                  </Row>
                  <Text textStyle="body">
                    For questions about the Universal Latin Exam, send an email
                    to{" "}
                    <a
                      style={{
                        textDecoration: "none",
                        color: designTokens.colors.brand[100],
                      }}
                      href="mailto:ULEinfo@instituteforclassicallanguages.org"
                    >
                      ULEinfo@instituteforclassicallanguages.org
                    </a>
                    .
                  </Text>
                </Column>
                <Button
                  href="mailto:ULEinfo@instituteforclassicallanguages.org"
                  text="Contact"
                  icon="external-link"
                />
              </Row>
            </Card>
            <Card
              style={{
                padding: 24,
              }}
            >
              <Row
                style={{
                  flexWrap: "wrap",
                  alignItems: "center",
                  justifyContent: "flex-start",
                }}
                gap={32}
              >
                <Column
                  style={{
                    flexBasis: 400,
                    flexGrow: 1,
                  }}
                  gap={8}
                >
                  <Row>
                    <Text textStyle="headingMedium">Technical Issues</Text>
                  </Row>
                  <Text textStyle="body">
                    If you’re experiencing a software bug or technical issue,
                    please email{" "}
                    <a
                      style={{
                        textDecoration: "none",
                        color: designTokens.colors.brand[100],
                      }}
                      href="mailto:help@gradience.co"
                    >
                      help@gradience.co
                    </a>{" "}
                    and we’ll get back to you within 48 hours.
                  </Text>
                </Column>
                <Button
                  href="mailto:help@gradience.co"
                  text="Contact"
                  icon="external-link"
                />
              </Row>
            </Card>
          </Column>
          <Column
            gap={32}
            style={{
              flexBasis: 0,
              flexGrow: 1,
            }}
          >
            <Column gap={16}>
              <Text textStyle="headingXS">School Info</Text>
              <Card
                style={{
                  padding: 24,
                  alignItems: "flex-start",
                  gap: 24,
                }}
              >
                {isLoading ? (
                  <span
                    style={{
                      alignItems: "center",
                      width: "100%",
                    }}
                  >
                    <Loader />
                  </span>
                ) : isSchoolInfoComplete(school.data) ? (
                  <>
                    <Column gap={8}>
                      <Text textStyle="headingSmall">{school.data?.name}</Text>
                      <Text>
                        {school.data?.address.line1}
                        <br />
                        {school.data?.address.line2 && (
                          <>
                            {school.data?.address.line2}
                            <br />
                          </>
                        )}
                        {school.data?.address.city},{" "}
                        {school.data?.address.state} {school.data?.address.zip}
                      </Text>
                    </Column>
                    <Column gap={4}>
                      <Text textStyle="strong">Size</Text>
                      <Text>{school.data?.size} students</Text>
                    </Column>
                    <Button
                      text="Update Information"
                      linkProps={{
                        to: editOnlySchoolInfoRoute.fullPath,
                      }}
                    />
                  </>
                ) : (
                  <>
                    <Text>
                      Add your school details and specify the Head Latin Teacher
                      and Exam Administrator.
                    </Text>
                    <Button
                      variant="primary"
                      text="Start"
                      linkProps={{
                        search: {
                          // Not sure why this is necessary
                          prev: "account" as unknown as undefined,
                        },
                        to: editSchoolInfoRoute.fullPath,
                        params: {
                          slug: config.REACT_APP_TEST_SLUG,
                        },
                      }}
                    />
                  </>
                )}
              </Card>
            </Column>
            <Column
              gap={16}
              style={{
                flexBasis: 0,
                flexGrow: 1,
              }}
            >
              <Text textStyle="headingXS">Account</Text>
              <Card
                style={{
                  padding: 24,
                  gap: 24,
                  display: "flex",
                }}
              >
                <Row style={{ alignItems: "center" }}>
                  <Column gap={4} style={{ flex: 1 }}>
                    <Text textStyle="headingSmall">
                      {[user.data?.firstName, user.data?.lastName].join(" ")}
                    </Text>
                    <Text>{user.data?.roleAtSchool}</Text>
                  </Column>
                  <div
                    style={{
                      height: 32,
                      width: 32,
                      borderRadius: "50%",
                      backgroundColor: designTokens.colors.brand["100"],
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <Text
                      textStyle="caption"
                      style={{
                        color: designTokens.colors.white,
                      }}
                    >
                      {user.data?.firstName?.[0]}
                      {user.data?.lastName?.[0]}
                    </Text>
                  </div>
                </Row>
                <Row>
                  <Button
                    loading={logout.isLoading}
                    text="Log Out"
                    onPress={() => logout.mutate()}
                  />
                </Row>
              </Card>
            </Column>
          </Column>
        </Row>
      </PageContainer>
    </PageChrome>
  );
};

export default Account;
