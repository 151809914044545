import { useDesignTokens } from "@gradience/ui";
import { useEffect, useState } from "react";

const useWindowDimensions = () => {
  const [dimensions, setDimensions] = useState({
    height: window.innerHeight,
    width: window.innerWidth,
  });

  useEffect(() => {
    const handleResize = () => {
      setDimensions({
        height: window.innerHeight,
        width: window.innerWidth,
      });
    };

    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return dimensions;
};

export default useWindowDimensions;

export const useIsMobile = () => {
  const { breakpoints } = useDesignTokens();
  const dimensions = useWindowDimensions();
  const isMobile = dimensions.width < breakpoints.md;

  return isMobile;
};

export const useWindowIsSmallerThan = (width: number) => {
  const dimensions = useWindowDimensions();
  const isSmallerThan = dimensions.width < width;

  return isSmallerThan;
};
