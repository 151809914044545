import { Text, Button, Input } from "@gradience/ui";
import { useNavigate, useSearch } from "@tanstack/react-router";
import { useState } from "react";
import { LOGGED_IN_USER_KEY, useApiPost } from "../../lib/api";
import AuthChrome from "../../components/auth-chrome";
import { setToken } from "../../lib/auth";
import { useQueryClient } from "@tanstack/react-query";
import config from "../../lib/config";
import { resetPasswordRoute } from "../..";

function ResetPassword() {
  const [password, setPassword] = useState("");
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const { token, initialSetup } = useSearch({
    from: resetPasswordRoute.id,
  });
  const requestPasswordesetMutation = useApiPost("/auth/password-reset", {
    onSuccess: ({ token }) => {
      setToken(token);
      queryClient.invalidateQueries(LOGGED_IN_USER_KEY);
      navigate({
        to: "/test/$slug/home",
        params: {
          slug: config.REACT_APP_TEST_SLUG,
        },
      });
    },
  });

  return (
    <AuthChrome>
      <form
        onSubmit={(e) => {
          e.preventDefault();
          requestPasswordesetMutation.mutate({
            token,
            password: password,
          });
        }}
        style={{
          display: "flex",
          flexDirection: "column",
          gap: 32,
        }}
      >
        <Text textStyle="headingLarge">
          {initialSetup ? "Choose a" : "Reset"} Password
        </Text>
        <Input
          label={initialSetup ? "Password" : "New Password"}
          name="password"
          value={password}
          setValue={setPassword}
          type="password"
          required
        />
        <div
          style={{
            display: "flex",
            gap: 32,
            flexDirection: "row",
            alignItems: "center",
            flexWrap: "wrap",
          }}
        >
          <Button
            variant="primary"
            text={initialSetup ? "Set password" : "Reset Password"}
            size="large"
            type="submit"
          />
          {!initialSetup && (
            <Text textStyle="caption" to="/log-in">
              Remembered? <b>Sign In</b>
            </Text>
          )}
        </div>
      </form>
    </AuthChrome>
  );
}

export default ResetPassword;
