/**
 * Returns true if the lists share any elements in common
 */
const intersect = <T>(arr1: T[], arr2: T[]): boolean => {
  for (const item of arr1) {
    for (const item2 of arr2) {
      if (item === item2) {
        return true;
      }
    }
  }
  return false;
};

export default intersect;
