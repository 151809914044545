import { Button, Text, Row } from "@gradience/ui";
import { useNavigate, useSearch } from "@tanstack/react-router";
import { useLoggedInUser, useLogout, useVerifyEmail } from "../lib/auth";
import { useEffect } from "react";
import AuthChrome from "../components/auth-chrome";
import { useApiPost } from "../lib/api";
import config from "../lib/config";
import { verifyEmailRoute } from "..";

function VerifyEmail() {
  const search = useSearch({
    from: verifyEmailRoute.id,
  });
  const navigate = useNavigate();
  const loggedInUser = useLoggedInUser();

  const verifyEmail = useVerifyEmail();
  const requestVerificationEmail = useApiPost("/auth/verify-email-request");

  const logout = useLogout();

  useEffect(() => {
    if (search.token) {
      verifyEmail.mutate(
        {
          token: search.token,
        },
        {
          onSuccess: () => {
            navigate({
              to: `/test/$slug/home`,
              params: {
                slug: config.REACT_APP_TEST_SLUG,
              },
            });
          },
          onError: () => {
            alert("Invalid token");
          },
        }
      );
    }
    // Only want to run on initial mount
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <AuthChrome>
      <Text textStyle="headingLarge">Verification Email Sent</Text>
      <Text textStyle="body">
        Please check the inbox for <strong>{loggedInUser.data?.email}</strong>{" "}
        and click the verification link within the next 15 minutes, then come
        back and refresh this page.
      </Text>
      <Text textStyle="body">
        Not seeing an email? Make sure to check your spam folder
      </Text>
      <Row gap={16}>
        <Button
          disabled={logout.isLoading}
          text="Log Out"
          onPress={() => logout.mutate()}
        />
        <Button
          disabled={requestVerificationEmail.isLoading}
          text="Send Again"
          onPress={() => requestVerificationEmail.mutate({})}
        />
      </Row>
    </AuthChrome>
  );
}

export default VerifyEmail;
