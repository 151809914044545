import { Text, Button, Input, useDesignTokens } from "@gradience/ui";
import { useLogin } from "../../lib/auth";
import { useEffect, useState } from "react";
import AuthChrome from "../../components/auth-chrome";
import { isApiError } from "../../lib/api";
import { useSearch } from "@tanstack/react-router";
import { logInRoute } from "../..";

function LogIn() {
  const designTokens = useDesignTokens();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const { userImpersonationEmail, userImpersonationToken } = useSearch({
    from: logInRoute.id,
  });

  const logInMutation = useLogin();

  useEffect(() => {
    if (userImpersonationEmail && userImpersonationToken) {
      logInMutation.mutate({
        username: userImpersonationEmail,
        password: userImpersonationToken,
      });
    }
  }, [logInMutation, userImpersonationEmail, userImpersonationToken]);

  return (
    <AuthChrome>
      <form
        style={{
          display: "flex",
          flexDirection: "column",
          gap: 32,
        }}
        onSubmit={(e) => {
          e.preventDefault();
          logInMutation.mutate({ username: email, password });
        }}
      >
        <Text textStyle="headingLarge">Log In</Text>
        <Input
          label="Email"
          name="email"
          required
          value={email}
          setValue={setEmail}
        />
        <Input
          label="Password"
          name="password"
          type="password"
          required
          value={password}
          setValue={setPassword}
          helpLinkText="Forgot Password?"
          helpLink={{
            to: "/request-password-reset",
          }}
        />
        {isApiError(logInMutation.error) && (
          <Text
            textStyle="strong"
            style={{
              color: designTokens.colors.text.error,
            }}
          >
            {logInMutation.error?.response?.data.errors[0].message}
          </Text>
        )}
        <div
          style={{
            display: "flex",
            gap: 32,
            flexDirection: "row",
            alignItems: "center",
            flexWrap: "wrap",
          }}
        >
          <Button
            variant="primary"
            text="Log In"
            size="large"
            type="submit"
            loading={logInMutation.isLoading}
          />
          <Text textStyle="caption" to="/sign-up">
            Don’t have an account? <b>Sign Up</b>
          </Text>
        </div>
      </form>
    </AuthChrome>
  );
}

export default LogIn;
