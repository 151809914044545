var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled from "styled-components";
var ClickableText = styled.button(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  background-color: transparent;\n  border: none;\n  color: ", ";\n  cursor: pointer;\n  font-size: inherit;\n  font-weight: 700;\n  line-height: inherit;\n  margin: 0;\n  padding: 0;\n  text-transform: inherit;\n  &:hover {\n    opacity: 0.75;\n  }\n  &:active {\n    opacity: 0.45;\n  }\n  &:disabled {\n    color: ", "\n    cursor: not-allowed;\n  }\n"], ["\n  background-color: transparent;\n  border: none;\n  color: ", ";\n  cursor: pointer;\n  font-size: inherit;\n  font-weight: 700;\n  line-height: inherit;\n  margin: 0;\n  padding: 0;\n  text-transform: inherit;\n  &:hover {\n    opacity: 0.75;\n  }\n  &:active {\n    opacity: 0.45;\n  }\n  &:disabled {\n    color: ", "\n    cursor: not-allowed;\n  }\n"])), function (_a) {
    var colors = _a.theme.colors;
    return colors.brand[100];
}, function (_a) {
    var colors = _a.theme.colors;
    return colors.text.disabled;
});
export default ClickableText;
var templateObject_1;
