import {
  Text,
  Button,
  Loader,
  getFormField,
  useDesignTokens,
} from "@gradience/ui";
import { useForm } from "@tanstack/react-form";
import {
  getApi,
  isApiError,
  useApiPost,
  useApiPut,
  useApiQuery,
} from "../lib/api";
import { useMemo, useState } from "react";
import { Column, Row } from "@gradience/ui";
import useSchool from "../lib/use-school";
import PageContainer from "../components/page-container";
import TopNavigation from "../components/top-navigation";
import { useIsMobile } from "../lib/use-window-dimensions";
import { LinkOptions } from "@tanstack/react-router";

const SchoolInfoForm = ({
  onSubmit,
  extraButton,
  hideAdministrators,
  onlyAdministrators,
  backLinkOptions,
}: {
  hideAdministrators?: boolean;
  onlyAdministrators?: boolean;
  onSubmit?: () => void;
  extraButton?: Partial<Parameters<typeof Button>[0]> & {
    text: string;
  };
  backLinkOptions: LinkOptions;
}) => {
  const school = useSchool();
  const designTokens = useDesignTokens();
  const isMobile = useIsMobile();
  const headLatinTeacher = useApiQuery(
    "/users/:id",
    {
      id: school.data?.headLatinTeacherId ?? "",
    },
    {
      enabled: Boolean(school.data?.headLatinTeacherId && !hideAdministrators),
    }
  );
  const examAdministrator = useApiQuery(
    "/users/:id",
    {
      id: school.data?.examAdministratorId ?? "",
    },
    {
      enabled: Boolean(school.data?.examAdministratorId && !hideAdministrators),
    }
  );
  const putSchoolMutation = useApiPut("/school", {});

  const putHeadLatinTeacherMutation = useApiPut(
    `/users/:id`,
    {
      id: school.data?.headLatinTeacherId ?? "",
    },
    { useErrorBoundary: false }
  );
  const postHeadLatinTeacherMutation = useApiPost(`/users`, {
    useErrorBoundary: false,
  });

  const putExamAdministratorMutation = useApiPut(
    `/users/:id`,
    {
      id: school.data?.examAdministratorId ?? "",
    },
    { useErrorBoundary: false }
  );
  const postExamAdministratorMutation = useApiPost(`/users`, {
    useErrorBoundary: false,
  });
  const [fieldErrors, setFieldErrors] = useState<
    Record<string, string | undefined>
  >({});
  const setFieldError = (field: string, error: string | undefined) => {
    setFieldErrors((errors) => ({
      ...errors,
      [field]: error,
    }));
  };

  const form = useForm({
    defaultValues: useMemo(
      () => ({
        nameOfSchool: school.data?.name,
        streetAddress1: school.data?.address?.line1,
        streetAddress2: school.data?.address?.line2,
        city: school.data?.address?.city,
        state: school.data?.address?.state,
        zip: school.data?.address?.zip,
        schoolSize: school.data?.size ?? "1-50",
        headLatinTeacher: {
          firstName: headLatinTeacher.data?.firstName ?? "",
          lastName: headLatinTeacher.data?.lastName ?? "",
          email: headLatinTeacher.data?.email ?? "",
        },
        examAdministrator: {
          firstName: examAdministrator.data?.firstName ?? "",
          lastName: examAdministrator.data?.lastName ?? "",
          email: examAdministrator.data?.email ?? "",
        },
      }),
      [
        school.data?.address?.city,
        school.data?.address?.line1,
        school.data?.address?.line2,
        school.data?.address?.state,
        school.data?.address?.zip,
        school.data?.name,
        school.data?.size,
        headLatinTeacher.data?.firstName,
        headLatinTeacher.data?.lastName,
        headLatinTeacher.data?.email,
        examAdministrator.data?.firstName,
        examAdministrator.data?.lastName,
        examAdministrator.data?.email,
      ]
    ),
    onSubmit: async (values) => {
      let isValid = true;
      if (!hideAdministrators) {
        let headLatinTeacherId = headLatinTeacher.data?.id;
        let examAdministratorId = examAdministrator.data?.id;

        const [headLatinTeacherResult, examAdministratorResult] =
          await Promise.allSettled([
            headLatinTeacherMutation.mutateAsync(
              {
                firstName: values.headLatinTeacher.firstName,
                lastName: values.headLatinTeacher.lastName,
                email: values.headLatinTeacher.email,
              },
              {
                onError: (error) => {
                  if (isApiError(error)) {
                    const emailError = error.response?.data.errors.find(
                      (e) => e.path?.[0] === "email"
                    );
                    if (emailError) {
                      setFieldError(
                        "headLatinTeacher.email",
                        emailError.message
                      );
                    } else {
                      setFieldError("headLatinTeacher.email", undefined);
                    }
                  }
                },
              }
            ),
            examAdministratorMutation.mutateAsync({
              firstName: values.examAdministrator.firstName,
              lastName: values.examAdministrator.lastName,
              email: values.examAdministrator.email,
            }),
          ]);

        if (headLatinTeacherResult.status === "fulfilled") {
          if (headLatinTeacherResult.value) {
            headLatinTeacherId = headLatinTeacherResult.value.id;
          }
        } else {
          const error = headLatinTeacherResult.reason;
          // This happens if the user already exists but isn't an exam
          // administrator or head latin teacher.
          if (isApiError(error) && error.response?.status === 400) {
            const existingUser = (await getApi("/users", {})).data.find(
              (user) => user.email === values.headLatinTeacher.email
            );
            if (existingUser) {
              headLatinTeacherId = existingUser.id;
            } else {
              isValid = false;
            }
          } else {
            throw error;
          }
        }

        if (examAdministratorResult.status === "fulfilled") {
          if (examAdministratorResult.value) {
            examAdministratorId = examAdministratorResult.value.id;
          }
        } else {
          const error = examAdministratorResult.reason;
          // This happens if the user already exists but isn't an exam
          // administrator or head latin teacher.
          if (isApiError(error) && error.response?.status === 400) {
            const existingUser = (await getApi("/users", {})).data.find(
              (user) => user.email === values.headLatinTeacher.email
            );
            if (existingUser) {
              examAdministratorId = existingUser.id;
            } else {
              isValid = false;
            }
          } else {
            throw error;
          }
        }

        await putSchoolMutation.mutateAsync({
          ...(onlyAdministrators
            ? {}
            : {
                name: values.nameOfSchool,
                address: {
                  line1: values.streetAddress1,
                  line2: values.streetAddress2,
                  city: values.city,
                  state: values.state,
                  zip: values.zip,
                },
                size: values.schoolSize,
              }),
          headLatinTeacherId,
          examAdministratorId,
        });
      } else {
        await putSchoolMutation.mutateAsync({
          ...(onlyAdministrators
            ? {}
            : {
                name: values.nameOfSchool,
                address: {
                  line1: values.streetAddress1,
                  line2: values.streetAddress2,
                  city: values.city,
                  state: values.state,
                  zip: values.zip,
                },
                size: values.schoolSize,
              }),
        });
      }

      isValid && onSubmit && onSubmit();
    },
  });

  const headLatinTeacherMutation =
    school.data?.headLatinTeacherId &&
    headLatinTeacher.data?.email ===
      form.getFieldValue("headLatinTeacher.email")
      ? putHeadLatinTeacherMutation
      : postHeadLatinTeacherMutation;

  const examAdministratorMutation =
    school.data?.examAdministratorId &&
    examAdministrator.data?.email ===
      form.getFieldValue("examAdministrator.email")
      ? putExamAdministratorMutation
      : postExamAdministratorMutation;

  const FormField = getFormField(form);

  const inner =
    school.isInitialLoading ||
    headLatinTeacher.isInitialLoading ||
    examAdministrator.isInitialLoading ? (
      <Loader />
    ) : (
      <Column style={{ flex: 1 }} gap={32}>
        {onlyAdministrators ? null : (
          <>
            <FormField
              name="nameOfSchool"
              label="School Name"
              placeholder="Name of School"
              autocomplete="organization"
              required
            />
            <Column gap={12}>
              <FormField
                name="streetAddress1"
                placeholder="Street Address 1"
                label="Address"
                required
              />
              <FormField name="streetAddress2" placeholder="Street Address 2" />
              <Row gap={12} wrap>
                <FormField
                  name="city"
                  placeholder="City"
                  required
                  style={{ flex: 2, flexBasis: 200 }}
                />
                <FormField
                  name="state"
                  placeholder="State"
                  required
                  style={{ flex: 1, flexBasis: 200 }}
                />
                <FormField
                  name="zip"
                  placeholder="Zip"
                  required
                  style={{ flex: 1, flexBasis: 200 }}
                />
              </Row>
            </Column>
            <FormField
              label="School Size"
              name="schoolSize"
              placeholder="Select"
              type="select"
              required
              options={[
                {
                  label: "1-50",
                  value: "1-50",
                },
                {
                  label: "51-100",
                  value: "51-100",
                },
                {
                  label: "101-200",
                  value: "101-200",
                },
                {
                  label: "201-500",
                  value: "201-500",
                },
                {
                  label: "500+",
                  value: "500+",
                },
              ]}
            />
          </>
        )}

        {hideAdministrators ? null : (
          <>
            <Column gap={12}>
              <Text textStyle="strong">Head Latin Teacher Information</Text>
              <Text textStyle="body">
                This person will receive a link to customize the Latin exams for
                each group. This person will need to be familiar with what
                material each group has studied to customize the exams.
              </Text>
              <Row gap={12}>
                <FormField
                  name="headLatinTeacher.firstName"
                  placeholder="First Name"
                  required
                  style={{ flex: 1 }}
                />
                <FormField
                  name="headLatinTeacher.lastName"
                  placeholder="Last Name"
                  required
                  style={{ flex: 1 }}
                />
              </Row>
              <FormField
                name="headLatinTeacher.email"
                placeholder="Email"
                required
                errorText={fieldErrors["headLatinTeacher.email"]}
              />
            </Column>
            <Column gap={12}>
              <Text textStyle="strong">Exam Administrator Information</Text>
              <Text textStyle="body">
                This person will receive a PDF for each exam group 5 days before
                the exam time and will be responsible for distributing the exam
                and collecting results.
              </Text>
              <Row gap={12}>
                <FormField
                  name="examAdministrator.firstName"
                  placeholder="First Name"
                  required
                  style={{ flex: 1 }}
                />
                <FormField
                  name="examAdministrator.lastName"
                  placeholder="Last Name"
                  required
                  style={{ flex: 1 }}
                />
              </Row>
              <FormField
                name="examAdministrator.email"
                placeholder="Email"
                required
                errorText={fieldErrors["examAdministrator.email"]}
              />
            </Column>
          </>
        )}
      </Column>
    );

  return (
    <form.Provider>
      <form
        onSubmit={(e) => {
          e.preventDefault();
          e.stopPropagation();
          void form.handleSubmit();
        }}
        style={{
          display: "flex",
          flex: 1,
        }}
      >
        <Column
          style={{
            width: "100%",
          }}
        >
          <TopNavigation
            backLinkOptions={backLinkOptions}
            title={
              onlyAdministrators
                ? "Updated Teacher Roles"
                : "School Information"
            }
          >
            <Row gap={16}>
              {extraButton && (
                <Button
                  loading={putSchoolMutation.isLoading}
                  {...extraButton}
                />
              )}
              <Button
                text="Submit"
                type="submit"
                variant="primary"
                loading={putSchoolMutation.isLoading}
              />
            </Row>
          </TopNavigation>
          <PageContainer>
            <div
              style={{
                maxWidth: 789,
                alignSelf: "center",
                width: "100%",
              }}
            >
              {inner}
            </div>
          </PageContainer>
          {isMobile && (
            <Row
              style={{
                paddingLeft: 20,
                paddingRight: 20,
                paddingTop: 32,
                paddingBottom: 32,
                position: "sticky",
                bottom: 0,
                backgroundColor: designTokens.colors.surface.background,
                borderTop: `1px solid ${designTokens.colors.border.default}`,
              }}
              gap={16}
            >
              {extraButton && (
                <Button
                  style={{
                    flex: 1,
                  }}
                  loading={putSchoolMutation.isLoading}
                  {...extraButton}
                />
              )}
              <Button
                text="Submit"
                style={{
                  flex: 1,
                }}
                type="submit"
                variant="primary"
                loading={putSchoolMutation.isLoading}
              />
            </Row>
          )}
        </Column>
      </form>
    </form.Provider>
  );
};

export default SchoolInfoForm;
