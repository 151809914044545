import { Text } from "@gradience/ui";
import gradience from "../images/gradience.png";
import { Link } from "@tanstack/react-router";
import { styled } from "styled-components";
import { VisibleMedium } from "@gradience/ui";
import useManifest from "../lib/use-manifest";
import { dashboardHomeRoute } from "..";
import config from "../lib/config";

const ParentContainer = styled.div`
  display: flex;
  flex-direction: row;
  height: 100%;
  min-height: 100dvh;
  box-sizing: border-box;
  background-color: ${({ theme }) => theme.colors.surface.background};
  max-width: 1216px;
  padding-left: 12px;
  padding-right: 12px;
  margin-right: auto;
  margin-left: auto;
`;

const PaddedContainer = styled.div`
  padding: 20px;
  padding-top: 62px;
  padding-bottom: 118px;
  gap: 58px;
  display: flex;
  flex-grow: 0;
  flex-shrink: 0;
  flex-basis: 50%;
  flex-direction: column;
  max-width: 443px;
  flex-basis: 443px;

  @media only screen and ${({ theme }) => theme.devices.md} {
    padding-top: 58px;
    padding-bottom: 92px;
    padding-right: 118px;
    gap: 100px;
  }
`;

function HomePageChrome({ children }: { children: React.ReactNode }) {
  const manifest = useManifest();

  return (
    <ParentContainer>
      <PaddedContainer>
        <Link
          to={dashboardHomeRoute.fullPath}
          params={{
            slug: config.REACT_APP_TEST_SLUG,
          }}
          style={{
            alignSelf: "flex-start",
          }}
        >
          <img
            src={manifest.logoUrl}
            alt={`${manifest.name} Logo`}
            style={{ maxWidth: "11.8125rem" }}
          />
        </Link>
        <div
          style={{
            flexGrow: 1,
            justifyContent: "center",
            display: "flex",
            flexDirection: "column",
            gap: 32,
          }}
        >
          {children}
        </div>
        <span
          style={{
            flexDirection: "row",
            alignItems: "center",
            gap: 8,
            cursor: "pointer",
            display: "flex",
          }}
          onClick={() => {
            window.open(
              "mailto:build@gradience.co?subject=I%20have%20a%20a%20software%20development%20project%20I%20would%20like%20to%20discuss"
            );
          }}
        >
          <Text
            textStyle="caption"
            style={{
              fontWeight: 500,
            }}
          >
            The ULE is powered by
          </Text>
          <img
            src={gradience}
            alt="Gradience Logo"
            style={{ height: "1rem" }}
          />
        </span>
      </PaddedContainer>
      <VisibleMedium
        style={{
          flexGrow: 1,
          flexShrink: 1,
          flexBasis: "50%",
          minHeight: "100%",
          position: "relative",
        }}
      >
        <img
          src={manifest.homePageImageUrl}
          style={{
            objectFit: "contain",
            width: "100%",
            height: "100dvh",
            position: "sticky",
            top: 0,
          }}
          alt="Students at their desks"
        />
      </VisibleMedium>
    </ParentContainer>
  );
}

export default HomePageChrome;
