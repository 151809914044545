import { useNavigate, useParams, useSearch } from "@tanstack/react-router";
import {
  Text,
  Loader,
  Card,
  Button,
  Icon,
  Checkbox,
  ClickableText,
  Badge,
  Divider,
  ModalButtonsRow,
  useDesignTokens,
  Tooltip,
} from "@gradience/ui";
import { groupDetailRoute, selectCurriculumRoute } from "../../..";
import { useApiPut, useApiQuery } from "../../../lib/api";
import { Column, Row } from "@gradience/ui";
import { ReactNode, useEffect, useMemo, useState } from "react";
import config from "../../../lib/config";
import styled from "styled-components";
import {
  useIsMobile,
  useWindowIsSmallerThan,
} from "../../../lib/use-window-dimensions";
import useNumberOfQuestions from "../../../domain/use-number-of-questions";
import TopNavigation from "../../../components/top-navigation";
import { PaddedContainer } from "../../../components/page-container";

const CheckboxItem = styled.div<{ selected: boolean }>`
  display: flex;
  flex-direction: row;
  gap: 8px;
  padding: 16px;
  border-radius: 12px;
  cursor: pointer;
  background-color: ${({ selected, theme }) =>
    selected ? theme.colors.surface.subdued : theme.colors.transparent};
  border: ${({ selected, theme }) =>
    selected
      ? `1px solid ${theme.colors.border.subdued}`
      : `1px solid ${theme.colors.transparent}`};
  &:hover {
    background-color: ${({ theme }) => theme.colors.surface.subdued};
  }
`;

const SelectCurriculum = (): ReactNode => {
  const designTokens = useDesignTokens();
  const params = useParams({ from: selectCurriculumRoute.id });
  const group = useApiQuery("/groups/:id", { id: params.groupId });
  const [_selectedConcepts, setSelectedConcepts] = useState<string[]>();
  const curricula = useApiQuery(
    "/curricula",
    {},
    { initialData: { data: [] } },
    {
      groupId: params.groupId,
    }
  );
  const [_selectedCurricula, setSelectedCurricula] = useState<string[]>();
  const [_selectedChapters, setSelectedChapters] = useState<string[]>();

  const selectedConcepts = useMemo(
    () => _selectedConcepts ?? group.data?.conceptIds ?? [],
    [_selectedConcepts, group.data?.conceptIds]
  );
  const selectedCurricula = useMemo(
    () => _selectedCurricula ?? group.data?.curriculumIds ?? [],
    [_selectedCurricula, group.data?.curriculumIds]
  );
  const selectedChapters =
    _selectedChapters ?? group.data?.curriculumChapterIds ?? [];

  const { step: _step } = useSearch({ from: selectCurriculumRoute.id });
  // -1 means last step
  const step = _step === -1 ? selectedCurricula.length + 1 : _step;

  const setStep = (step: number) => {
    navigate({
      to: selectCurriculumRoute.fullPath,
      search: { step },
      params: { slug: config.REACT_APP_TEST_SLUG, groupId: params.groupId },
    });
  };

  const onFinalStep = step >= selectedCurricula.length + 1;
  const navigate = useNavigate();
  const updateGroupMutation = useApiPut("/groups/:id", { id: params.groupId });

  const chapters = useApiQuery(
    "/chapters",
    {},
    { enabled: selectedCurricula.length > 0 },
    {
      curriculumIds: selectedCurricula,
    }
  );
  const numberOfQuestions = useNumberOfQuestions(selectedConcepts);

  useEffect(() => {
    chapters.refetch();
  }, [selectedCurricula, chapters]);
  useEffect(() => {
    numberOfQuestions.refetch();
  }, [selectedConcepts, numberOfQuestions]);

  const curriculumSelector = (
    <>
      <Text textStyle="headingXS">Curriculum Selection</Text>
      <Text>
        Which of the following curricula did you use this year?
        <br />
        Select all that apply.
      </Text>
      <Card
        style={{
          padding: 8,
          gap: 0,
        }}
      >
        {curricula.data?.data.map((curriculum) => (
          <CheckboxItem
            key={curriculum.id}
            selected={selectedCurricula.includes(curriculum.id)}
            onClick={() => {
              if (selectedCurricula.includes(curriculum.id)) {
                setSelectedCurricula(
                  selectedCurricula.filter((x) => x !== curriculum.id)
                );
                // Remove this curriculum's chapters from the selected chapters
                const newSelectedChapters = [
                  ...selectedChapters.filter(
                    (chapterId) =>
                      chapters.data?.data.find(
                        (chapter) => chapter.id === chapterId
                      )?.curriculumId !== curriculum.id
                  ),
                ];
                setSelectedChapters(newSelectedChapters);
                setSelectedConcepts(
                  chapters.data?.data
                    .filter((chapter) =>
                      newSelectedChapters.includes(chapter.id)
                    )
                    .map((chapter) => chapter.conceptIds)
                    .flat() ?? []
                );
              } else {
                setSelectedCurricula([...selectedCurricula, curriculum.id]);
              }
            }}
          >
            <Checkbox value={selectedCurricula.includes(curriculum.id)} />
            <Text>{curriculum.name}</Text>
          </CheckboxItem>
        ))}
      </Card>
    </>
  );

  const selectingChaptersForCurriculum = curricula.data?.data.find(
    (curriculum) => curriculum.id === selectedCurricula[step - 1]
  );

  const selectChapter = (chapterId: string) => {
    let newSelectedChapters: string[];
    if (selectedChapters.includes(chapterId)) {
      newSelectedChapters = selectedChapters.filter((x) => x !== chapterId);
    } else {
      newSelectedChapters = [...selectedChapters, chapterId];
    }
    setSelectedChapters(newSelectedChapters);
    setSelectedConcepts(
      chapters.data?.data
        .filter((chapter) => newSelectedChapters.includes(chapter.id))
        .map((chapter) => chapter.conceptIds)
        .flat() ?? []
    );
  };
  const chapterSelector = (
    <>
      <Badge
        style={{
          alignSelf: "flex-start",
        }}
      >
        {selectingChaptersForCurriculum?.name ?? ""}
      </Badge>
      <Text textStyle="headingXS">Chapter Selection</Text>
      <Text>
        Select all chapters that you will have studied by the Exam Date.
        <br />
        Your first selection will select all chapters before it, and then you
        may deselect any chapters you do not plan to study.
      </Text>
      <Card
        style={{
          padding: 8,
          gap: 0,
        }}
      >
        {chapters.data?.data
          .filter(
            (chapter) =>
              chapter.curriculumId === selectingChaptersForCurriculum?.id
          )
          .map((chapter) => (
            <CheckboxItem
              selected={selectedChapters.includes(chapter.id)}
              onClick={() => {
                const chaptersForThisCurriculum = chapters.data?.data
                  .filter(
                    (chapter) =>
                      chapter.curriculumId ===
                      selectingChaptersForCurriculum?.id
                  )
                  .map((chapter) => chapter.id);
                // If no chapters are selected yet, select this one and all the
                // ones before it
                if (
                  selectedChapters.filter((chapter) =>
                    chaptersForThisCurriculum.includes(chapter)
                  ).length === 0
                ) {
                  const newSelectedChapters = [
                    ...chaptersForThisCurriculum.slice(
                      0,
                      chaptersForThisCurriculum.indexOf(chapter.id) + 1
                    ),
                    ...selectedChapters,
                  ];
                  setSelectedChapters(newSelectedChapters);
                  setSelectedConcepts(
                    chapters.data?.data
                      .filter((chapter) =>
                        newSelectedChapters.includes(chapter.id)
                      )
                      .map((chapter) => chapter.conceptIds)
                      .flat() ?? []
                  );
                  return;
                } else {
                  selectChapter(chapter.id);
                }
              }}
              key={chapter.id}
            >
              <Checkbox value={selectedChapters.includes(chapter.id)} />
              <Text>{chapter.name}</Text>
            </CheckboxItem>
          ))}
      </Card>
    </>
  );

  const conceptGroups = useApiQuery("/concept-groups", {});
  const [openGroup, setOpenGroup] = useState<string | null>(null);
  const conceptSelector = (
    <>
      <Text textStyle="headingXS">Review Concepts</Text>
      <Text>
        Add all concepts your class has studied this year to the tested concepts
        list and carefully review before finalizing.
      </Text>
      {conceptGroups.data?.data.map((group) => (
        <Card
          style={{
            padding: 0,
            gap: 0,
          }}
          key={group.id}
        >
          <Row
            style={{
              cursor: "pointer",
              padding: 16,
            }}
            gap={8}
            onClick={() => {
              if (openGroup === group.id) {
                setOpenGroup(null);
              } else {
                setOpenGroup(group.id);
              }
            }}
          >
            <Text textStyle="strong" style={{ flex: 1 }}>
              {group.name}
            </Text>
            <Icon
              noButtonStyle
              name={openGroup === group.id ? "chevron-up" : "chevron-down"}
              onClick={() => {
                if (openGroup === group.id) {
                  setOpenGroup(null);
                } else {
                  setOpenGroup(group.id);
                }
              }}
            />
          </Row>
          {openGroup === group.id && (
            <Column
              style={{
                padding: "0 16px",
                paddingBottom: 16,
              }}
            >
              {group.concepts.map((concept) => (
                <CheckboxItem
                  style={{
                    padding: "9px 17px",
                  }}
                  selected={selectedConcepts.includes(concept.id)}
                  onClick={() => {
                    if (selectedConcepts.includes(concept.id)) {
                      setSelectedConcepts(
                        selectedConcepts.filter((x) => x !== concept.id)
                      );
                    } else {
                      setSelectedConcepts([...selectedConcepts, concept.id]);
                    }
                  }}
                  key={concept.id}
                >
                  <Checkbox value={selectedConcepts.includes(concept.id)} />
                  <Text>{concept.name}</Text>
                </CheckboxItem>
              ))}
            </Column>
          )}
        </Card>
      ))}
    </>
  );
  const isMobile = useIsMobile();
  // I found this number by measuring, not sure why it is what it is
  const backButtonIsHidden = useWindowIsSmallerThan(1292);
  const backLinkOptions = {
    to: groupDetailRoute.fullPath,
    params: { slug: config.REACT_APP_TEST_SLUG, groupId: params.groupId },
  };

  return (
    <Column
      style={{
        display: "flex",
        position: "relative",
        flex: 1,
      }}
    >
      <TopNavigation backLinkOptions={backLinkOptions} title="Configure Tests">
        <Row gap={16}>
          <Button
            onPress={
              step === 0
                ? undefined
                : () => {
                    setStep(step - 1);
                  }
            }
            text={backButtonIsHidden && step === 0 ? "Cancel" : "Previous"}
            disabled={step === 0 && !backButtonIsHidden}
            linkProps={
              backButtonIsHidden && step === 0 ? backLinkOptions : undefined
            }
          />
          <Tooltip
            content={
              onFinalStep && numberOfQuestions.data?.numberOfQuestions === 0
                ? "Your test doesn't contain enough concepts for any questions."
                : undefined
            }
          >
            <Button
              onPress={async () => {
                if (onFinalStep) {
                  await updateGroupMutation.mutateAsync({
                    conceptIds: selectedConcepts,
                    curriculumIds: selectedCurricula,
                    curriculumChapterIds: selectedChapters,
                  });
                  navigate({
                    to: groupDetailRoute.fullPath,
                    params: {
                      slug: config.REACT_APP_TEST_SLUG,
                      groupId: params.groupId,
                    },
                  });
                } else {
                  // Select all the concepts related to the selected chapters
                  setSelectedConcepts(
                    chapters.data?.data
                      .filter((chapter) =>
                        selectedChapters.includes(chapter.id)
                      )
                      .map((chapter) => chapter.conceptIds)
                      .flat() ?? []
                  );
                  setStep(step + 1);
                }
              }}
              text={onFinalStep ? "Finish" : "Next"}
              variant="primary"
              disabled={
                (step === 0 && selectedCurricula.length === 0) ||
                (onFinalStep && numberOfQuestions.data?.numberOfQuestions === 0)
              }
              loading={updateGroupMutation.isLoading}
            />
          </Tooltip>
        </Row>
      </TopNavigation>
      <PaddedContainer
        style={{
          overflowY: "scroll",
          height: "100%",
          gap: 48,
          flexDirection: "row",
          flexWrap: "wrap",
        }}
      >
        <Column
          gap={8}
          style={{
            flexGrow: 1,
            flexBasis: 350,
          }}
        >
          {group.isLoading ||
          curricula.isLoading ||
          conceptGroups.isLoading ||
          (chapters.isLoading && step !== 0) ? (
            <Loader />
          ) : step === 0 ? (
            curriculumSelector
          ) : step < selectedCurricula.length + 1 ? (
            chapterSelector
          ) : (
            conceptSelector
          )}
          {/* Can't use a margin because nested container's margins are collapsed. We can't put the margin on the parent because this child overflowss */}
          <div
            style={{
              flexBasis: 48,
              flexShrink: 0,
            }}
          />
        </Column>
        <Column
          gap={16}
          style={{
            flexBasis: 308,
            flexShrink: 0,
            maxHeight: "100%",
            position: "sticky",
            top: 0,
            flexGrow: 1,
          }}
        >
          <Card
            style={{
              padding: 24,
              paddingBottom: 0,
              gap: 24,
              overflowY: "scroll",
            }}
          >
            <Text textStyle="headingSmall">Tested Concepts</Text>
            {selectedConcepts.length ? (
              <>
                {conceptGroups.data?.data
                  .filter(
                    (group) =>
                      group.concepts.filter((concept) =>
                        selectedConcepts.includes(concept.id)
                      ).length > 0
                  )
                  .map((group) => (
                    <Column gap={0} key={group.id}>
                      <Row
                        style={{
                          gap: 8,
                          marginBottom: 4,
                        }}
                      >
                        <Text textStyle="strong">{group.name}</Text>
                        <Badge type="secondary">
                          {group.concepts
                            .filter((concept) =>
                              selectedConcepts.includes(concept.id)
                            )
                            .length.toString()}
                        </Badge>
                      </Row>
                      {group.concepts
                        .filter((concept) =>
                          selectedConcepts.includes(concept.id)
                        )
                        .map((concept) => (
                          <Text
                            textStyle="caption"
                            style={{
                              padding: 8,
                            }}
                            key={concept.id}
                          >
                            {concept.name}
                          </Text>
                        ))}
                    </Column>
                  ))}
                <Column
                  gap={24}
                  style={{
                    position: "sticky",
                    bottom: 0,
                    backgroundColor: designTokens.colors.surface.default,
                    paddingBottom: 24,
                  }}
                >
                  <Divider />
                  <Row gap={4}>
                    <Text
                      style={{
                        flex: 1,
                      }}
                    >
                      # of Test Questions
                    </Text>
                    <Text
                      textStyle="strong"
                      loading={numberOfQuestions.isLoading}
                      loadingWidth="2rem"
                    >
                      {numberOfQuestions.data?.numberOfQuestions}
                    </Text>
                  </Row>
                </Column>
              </>
            ) : (
              <span
                style={{
                  paddingBottom: 24,
                }}
              >
                <div
                  style={{
                    padding: 8,
                    borderRadius: 12,
                    backgroundColor: designTokens.colors.surface.subdued,
                  }}
                >
                  <Text
                    style={{
                      color: designTokens.colors.text.disabled,
                      textAlign: "center",
                    }}
                  >
                    Waiting for curriculum & chapter selection...
                  </Text>
                </div>
              </span>
            )}
          </Card>
          {onFinalStep ? null : (
            <Card style={{ padding: 24, gap: 8 }}>
              <Text textStyle="headingXS">
                Don’t see your curriculum listed?
              </Text>
              <Text>
                If the curriculum you’re using this year isn’t listed, you can
                manually enter taught concepts{" "}
                <ClickableText
                  onClick={() => {
                    setSelectedChapters([]);
                    setSelectedConcepts([]);
                    setStep(-1);
                  }}
                >
                  here
                </ClickableText>
                .
              </Text>
            </Card>
          )}
        </Column>
      </PaddedContainer>
      {isMobile && (
        <ModalButtonsRow
          style={{
            flexGrow: "unset",
            marginLeft: 36,
            marginRight: 36,
          }}
        >
          <Button
            onPress={
              step === 0
                ? undefined
                : () => {
                    setStep(step - 1);
                  }
            }
            text={backButtonIsHidden && step === 0 ? "Cancel" : "Previous"}
            disabled={step === 0 && !backButtonIsHidden}
            linkProps={
              backButtonIsHidden && step === 0 ? backLinkOptions : undefined
            }
          />
          <Button
            onPress={async () => {
              if (onFinalStep) {
                await updateGroupMutation.mutateAsync({
                  conceptIds: selectedConcepts,
                  curriculumIds: selectedCurricula,
                  curriculumChapterIds: selectedChapters,
                });
                navigate({
                  to: groupDetailRoute.fullPath,
                  params: {
                    slug: config.REACT_APP_TEST_SLUG,
                    groupId: params.groupId,
                  },
                });
              } else {
                // Select all the concepts related to the selected chapters
                setSelectedConcepts(
                  chapters.data?.data
                    .filter((chapter) => selectedChapters.includes(chapter.id))
                    .map((chapter) => chapter.conceptIds)
                    .flat() ?? []
                );
                setStep(step + 1);
              }
            }}
            text={onFinalStep ? "Finish" : "Next"}
            variant="primary"
            disabled={step === 0 && selectedCurricula.length === 0}
            loading={updateGroupMutation.isLoading}
          />
        </ModalButtonsRow>
      )}
    </Column>
  );
};

export default SelectCurriculum;
