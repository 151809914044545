import {
  Button,
  getFormField,
  Loader,
  Text,
  Card,
  CompactModal,
  Column,
  Row,
  useDesignTokens,
} from "@gradience/ui";
import {
  PaymentElement,
  useElements,
  useStripe,
} from "@stripe/react-stripe-js";
import { useForm } from "@tanstack/react-form";
import { useNavigate, useParams, useSearch } from "@tanstack/react-router";
import { isAxiosError } from "axios";
import { useMemo, useState } from "react";
import { isApiError, useApiPost, useApiPut, useApiQuery } from "../../lib/api";
import useSchool from "../../lib/use-school";
import TopNavigation from "../../components/top-navigation";
import PageContainer from "../../components/page-container";
import { useIsMobile } from "../../lib/use-window-dimensions";
import config from "../../lib/config";
import OrderSummary from "../../components/order-summary";
import {
  hasPaymentMethod,
  isOrderComplete,
} from "../../domain/is-school-info-complete";
import displayDate from "../../lib/display-date";
import { SetupIntent, StripeError } from "@stripe/stripe-js";
import { dashboardOrderRoute } from "../..";

const Order = ({
  onSubmit,
  hidePayment,
  onlyPayment,
}: {
  hidePayment?: boolean;
  onlyPayment?: boolean;
  onSubmit?: () => void;
}) => {
  const school = useSchool();
  const [cancelOrderModalOpen, setCancelOrderModalOpen] = useState(false);
  const [showEditPaymentMethod, setShowEditPaymentMethod] = useState(false);

  const navigate = useNavigate();
  const goBack = () =>
    navigate({
      to:
        searchParams.prev === "overview"
          ? "/test/$slug/overview"
          : "/test/$slug/home",
      params: {
        slug: config.REACT_APP_TEST_SLUG,
      },
    });

  const isMobile = useIsMobile();

  const stripe = useStripe();
  const [stripeError, setStripeError] = useState<StripeError | undefined>();
  const elements = useElements();
  const postSetupIntent = useApiPost("/setup-intents");
  const putSchool = useApiPut(
    "/school",
    {},
    {
      onSuccess: () => {
        goBack();
      },
      onError: (error) => {
        if (isApiError(error)) {
          const studentsError = error.response?.data.errors.find(
            (e) => e.path?.[0] === "numberOfStudents"
          );
          if (studentsError) {
            setFieldError("numberOfStudents", studentsError.message);
          } else {
            setFieldError("numberOfStudents", undefined);
          }
          const groupsError = error.response?.data.errors.find(
            (e) => e.path?.[0] === "numberOfGroups"
          );
          if (groupsError) {
            setFieldError("numberOfGroups", groupsError.message);
          } else {
            setFieldError("numberOfGroups", undefined);
          }
        }
        form.update({});
      },
      useErrorBoundary: false,
    }
  );

  const form = useForm({
    defaultValues: useMemo(
      () => ({
        numberOfStudents: school.data?.numberOfStudents?.toString() ?? "",
        numberOfGroups: school.data?.numberOfGroups?.toString() ?? "",
      }),
      [school.data?.numberOfGroups, school.data?.numberOfStudents]
    ),
    onSubmit: async (values, api) => {
      setStripeError(undefined);
      if (
        hidePayment ||
        (!showEditPaymentMethod && hasPaymentMethod(school.data))
      ) {
        try {
          await putSchool.mutateAsync({
            numberOfStudents: parseInt(values.numberOfStudents),
            numberOfGroups: parseInt(values.numberOfGroups),
          });
        } catch (e) {
          // Axios errors are handled in onError above
          if (!isAxiosError(e)) {
            throw e;
          }
        }
      } else {
        if (!stripe || !elements) {
          // Stripe.js hasn't yet loaded.
          // Make sure to disable form submission until Stripe.js has loaded.
          return;
        }

        // Trigger form validation and wallet collection
        const { error: submitError } = await elements.submit();
        if (submitError) {
          return;
        }

        // Create the SetupIntent and obtain clientSecret
        const res = await postSetupIntent.mutateAsync({});

        const { clientSecret } = res;

        let error: StripeError | undefined = undefined;
        let setupIntent: SetupIntent | undefined = undefined;
        if (showEditPaymentMethod || !hasPaymentMethod(school.data)) {
          // Confirm the SetupIntent using the details collected by the Payment Element
          const confirmSetupResult = await stripe.confirmSetup({
            elements,
            clientSecret,
            confirmParams: {
              return_url: "https://example.com/complete",
            },
            redirect: "if_required",
          });
          error = confirmSetupResult.error;
          setupIntent = confirmSetupResult.setupIntent;
        }

        if (error) {
          setStripeError(error);
        } else {
          // Your customer is redirected to your `return_url`. For some payment
          // methods like iDEAL, your customer is redirected to an intermediate
          // site first to authorize the payment, then redirected to the `return_url`.
          try {
            await putSchool.mutateAsync({
              ...(onlyPayment
                ? {}
                : {
                    numberOfStudents: parseInt(values.numberOfStudents),
                    numberOfGroups: parseInt(values.numberOfGroups),
                  }),
              ...(setupIntent
                ? {
                    paymentMethod:
                      typeof setupIntent.payment_method === "string"
                        ? { id: setupIntent.payment_method }
                        : undefined,
                  }
                : {}),
            });
          } catch (e) {
            // Axios errors are handled in onError above
            if (!isAxiosError(e)) {
              throw e;
            }
          }
        }
      }
    },
  });

  const [fieldErrors, setFieldErrors] = useState<
    Record<(typeof form)["Field"]["name"], string | undefined>
  >({});
  const setFieldError = (name: string, error: string | undefined) => {
    setFieldErrors((errors) => ({
      ...errors,
      [name]: error,
    }));
  };

  const FormField = getFormField(form);

  const route = useParams({
    from: "/test/$slug/order",
  });
  const searchParams = useSearch({
    from: dashboardOrderRoute.fullPath,
  });

  const test = useApiQuery("/tests/:slug", {
    slug: route.slug,
  });

  const designTokens = useDesignTokens();

  const inner = school.isInitialLoading ? (
    <Loader />
  ) : (
    <Row
      gap={32}
      style={{
        alignItems: "stretch",
        flexWrap: "wrap",
      }}
    >
      <Column
        gap={32}
        style={{
          flexBasis: 500,
          flex: 1,
        }}
      >
        <Text textStyle="headingMedium">Reserve Tests</Text>
        {!onlyPayment && (
          <>
            <Column gap={12}>
              <Text textStyle="strong">Estimated Number of Students*</Text>
              <Text textStyle="body">
                Enter the total number of students from your school who will be
                taking the test. You will be able to adjust this number from the
                Order tab until {displayDate(test.data?.reservationDateEnd)}.
              </Text>
              <FormField
                name="numberOfStudents"
                placeholder="Number of Students"
                required
                type="number"
                errorText={fieldErrors.numberOfStudents}
              />
            </Column>
            <Column gap={12}>
              <Text textStyle="strong">Number of Groups*</Text>
              <Text textStyle="body">
                Enter the number of individual groups (or classes) that will be
                taking the test. You will be able to customize groups starting{" "}
                {displayDate(test.data?.configurationDateStart)}.
              </Text>
              <FormField
                name="numberOfGroups"
                placeholder="Number of Groups"
                required
                type="number"
                errorText={fieldErrors.numberOfGroups}
              />
            </Column>
          </>
        )}
        {!hidePayment && (
          <Column gap={12}>
            <Text textStyle="strong">Billing Information*</Text>
            <Text textStyle="body">
              {hasPaymentMethod(school.data) ? (
                `${
                  school.data?.credit
                    ? "Your credit balance"
                    : `Your card ending in ${school.data?.paymentMethod?.last4}`
                } will be charged ${displayDate(
                  test.data?.administrationDateStart
                )} when tests become available.`
              ) : (
                <span>
                  In order to reserve tests, please enter valid credit or debit
                  card information. Your card will not be charged until{" "}
                  {displayDate(test.data?.administrationDateStart)} when tests
                  become available.
                </span>
              )}
            </Text>
            {stripeError?.message ? (
              <Text
                textStyle="strong"
                style={{
                  color: designTokens.colors.text.error,
                }}
              >
                {stripeError?.message}
              </Text>
            ) : null}
            {!hasPaymentMethod(school.data) || showEditPaymentMethod ? (
              <PaymentElement />
            ) : (
              <Button
                text="Update payment method"
                disabled={form.state.isSubmitting}
                onPress={() => setShowEditPaymentMethod(true)}
              />
            )}
          </Column>
        )}
      </Column>
      <Column
        style={{
          flexBasis: 306,
          flexShrink: 0,
          position: "relative",
          flex: 1,
        }}
        gap={32}
      >
        <Card
          style={{
            padding: 24,
            position: "sticky",
            top: 0,
            gap: 24,
          }}
        >
          <Text textStyle="headingSmall">Reservation Summary</Text>
          <form.Subscribe
            children={(formState) => (
              <OrderSummary
                numberOfStudents={parseInt(formState.values.numberOfStudents)}
              />
            )}
          />
          {isOrderComplete(school.data) && (
            <Button
              disabled={form.state.isSubmitting}
              text="Cancel Order"
              onPress={() => setCancelOrderModalOpen(true)}
              style={{
                alignSelf: "flex-start",
              }}
            />
          )}
        </Card>
      </Column>
    </Row>
  );

  return (
    <form.Provider>
      <form
        onSubmit={(e) => {
          e.preventDefault();
          e.stopPropagation();
          void form.handleSubmit();
        }}
        style={{
          display: "flex",
          flex: 1,
        }}
      >
        <Column
          style={{
            width: "100%",
          }}
        >
          <TopNavigation
            backLinkOptions={{
              to:
                searchParams.prev === "overview"
                  ? "/test/$slug/overview"
                  : "/test/$slug/home",
              params: {
                slug: config.REACT_APP_TEST_SLUG,
              },
            }}
            title="Reserve Your Tests"
          >
            <Row gap={16}>
              <Button
                text="Submit"
                type="submit"
                variant="primary"
                disabled={form.state.isSubmitting}
              />
              <Button
                text="Cancel"
                linkProps={{
                  to:
                    searchParams.prev === "overview"
                      ? "/test/$slug/overview"
                      : "/test/$slug/home",
                  params: {
                    slug: config.REACT_APP_TEST_SLUG,
                  },
                }}
                disabled={form.state.isSubmitting}
              />
            </Row>
          </TopNavigation>
          <PageContainer>
            <div
              style={{
                maxWidth: 789,
                alignSelf: "center",
              }}
            >
              {inner}
            </div>
          </PageContainer>
          {isMobile && (
            <Row
              style={{
                paddingLeft: 20,
                paddingRight: 20,
                paddingTop: 32,
                paddingBottom: 32,
                position: "sticky",
                bottom: 0,
                backgroundColor: designTokens.colors.surface.background,
                borderTop: `1px solid ${designTokens.colors.border.default}`,
              }}
              gap={16}
            >
              <Button
                text="Submit"
                type="submit"
                variant="primary"
                style={{
                  flex: 1,
                }}
                disabled={form.state.isSubmitting}
              />
              <Button
                text="Cancel"
                linkProps={{
                  to:
                    searchParams.prev === "overview"
                      ? "/test/$slug/overview"
                      : "/test/$slug/home",
                  params: {
                    slug: config.REACT_APP_TEST_SLUG,
                  },
                }}
                style={{
                  flex: 1,
                }}
                disabled={form.state.isSubmitting}
              />
            </Row>
          )}
        </Column>
      </form>
      <CompactModal
        open={cancelOrderModalOpen}
        close={() => !putSchool.isLoading && setCancelOrderModalOpen(false)}
        style={{
          minWidth: 0,
          minHeight: 0,
        }}
      >
        <Column gap={24}>
          <Column gap={8}>
            <Text textStyle="headingSmall">Cancel Order</Text>
            <Text>
              Are you sure you want to cancel your order of the Universal Latin
              Exam? This cannot be undone and if the reservation period has
              ended (after{" "}
              {displayDate(
                test.data?.reservationDateEnd
                  ? new Date(test.data.reservationDateEnd)
                  : undefined
              )}
              ) you will not be able to reserve tests again.
            </Text>
          </Column>
          <Row gap={8}>
            <Button
              loading={putSchool.isLoading}
              onPress={() => {
                putSchool.mutate({
                  numberOfGroups: null,
                  numberOfStudents: null,
                });
              }}
              text="Cancel Order"
              variant="primary"
              style={{
                border: 0,
                backgroundColor: designTokens.colors.brand.critical,
              }}
            />
            <Button
              disabled={putSchool.isLoading}
              onPress={() => setCancelOrderModalOpen(false)}
              text="Never Mind"
            />
          </Row>
        </Column>
      </CompactModal>
    </form.Provider>
  );
};

export default Order;
