var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useDesignTokens } from "../lib/design-tokens";
var Loader = function () {
    var designTokens = useDesignTokens();
    return (_jsx("span", __assign({ style: {
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexBasis: "100%",
            flexGrow: 1,
        } }, { children: _jsx("svg", __assign({ width: "40", height: "40", viewBox: "0 0 40 40", xmlns: "http://www.w3.org/2000/svg", stroke: designTokens.colors.icon.default }, { children: _jsx("g", __assign({ fill: "none", fillRule: "evenodd" }, { children: _jsxs("g", __assign({ transform: "translate(2 2)", strokeWidth: "4" }, { children: [_jsx("circle", { strokeOpacity: ".5", cx: "18", cy: "18", r: "18" }), _jsx("path", __assign({ d: "M36 18c0-9.94-8.06-18-18-18" }, { children: _jsx("animateTransform", { attributeName: "transform", type: "rotate", from: "0 18 18", to: "360 18 18", dur: "1s", repeatCount: "indefinite" }) }))] })) })) })) })));
};
export default Loader;
