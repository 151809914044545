import { useDesignTokens } from "@gradience/ui";
import Navbar from "../components/navbar";

function PageChrome({ children }: { children: React.ReactNode }) {
  const designTokens = useDesignTokens();

  return (
    <div
      style={{
        display: "flex",
        height: "100%",
        minHeight: "100dvh",
        boxSizing: "border-box",
        backgroundColor: designTokens.colors.surface.background,
      }}
    >
      <Navbar />
      {children}
    </div>
  );
}

export default PageChrome;
