import { useParams } from "@tanstack/react-router";
import { useApiQuery } from "../../lib/api";
import {
  Loader,
  Text,
  Column,
  FileInput,
  useDesignTokens,
  Row,
  Icon,
  Card,
  Badge,
  MeatballMenu,
} from "@gradience/ui";
import React, { ReactNode, useMemo, useState } from "react";
import useSchool from "../../lib/use-school";
import PageContainer from "../../components/page-container";
import GroupConfigurationModal from "../../forms/group-configuration-modal";
import { useLoggedInUser } from "../../lib/auth";
import { useUploadTestScan } from "../../lib/use-upload-test-scan";
import { TestScan } from "@gradience/api-types";
import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  useReactTable,
} from "@tanstack/react-table";
import displayDate, { displayTime } from "../../lib/display-date";
import { useQueryClient } from "@tanstack/react-query";

const columnHelper = createColumnHelper<TestScan>();

const ScansTable = () => {
  const designTokens = useDesignTokens();
  const testScans = useApiQuery("/test-scans", {});
  const columns = useMemo(
    () => [
      columnHelper.accessor("originalFilename", {
        header: "Document Name",
        cell: (row) => <Text>{row.getValue()}</Text>,
      }),
      columnHelper.accessor("createdAt", {
        header: "Date Uploaded",
        cell: (row) => {
          const date = new Date(row.getValue());
          return (
            <Column gap={4}>
              <Text>{displayDate(date)}</Text>
              <Text
                style={{
                  color: "#7F7D83",
                }}
              >
                {displayTime(date)}
              </Text>
            </Column>
          );
        },
      }),
      columnHelper.display({
        id: "status",
        header: ({ table }) => <Text textStyle="strong">Status</Text>,
        cell: ({ row }) => <Badge icon="upload-03">Uploaded</Badge>,
      }),
      columnHelper.display({
        id: "actions",
        header: () => null,
        cell: ({ row }) => (
          <MeatballMenu
            options={[
              {
                label: "View pdf",
                value: "view",
              },
            ]}
            onSelect={() => {
              window.open(row.original.url);
            }}
          />
        ),
      }),
    ],
    []
  );

  const { getHeaderGroups, getRowModel } = useReactTable({
    columns,
    data: testScans.data?.data ?? [],
    getCoreRowModel: getCoreRowModel(),
  });

  return (
    <table
      style={{
        flex: 1,
        borderCollapse: "collapse",
      }}
    >
      <thead>
        {getHeaderGroups().map((headerGroup) => (
          <tr key={headerGroup.id}>
            {headerGroup.headers.map((header) => (
              <th
                key={header.id}
                style={{
                  paddingTop: 14,
                  paddingBottom: 14,
                  paddingLeft: 24,
                  paddingRight: 24,
                  borderTop: `1px solid ${designTokens.colors.border.subdued}`,
                  borderBottom: `1px solid ${designTokens.colors.border.subdued}`,
                  textAlign: "start",
                }}
              >
                <Text textStyle="strong">
                  {header.isPlaceholder
                    ? null
                    : flexRender(
                        header.column.columnDef.header,
                        header.getContext()
                      )}
                </Text>
              </th>
            ))}
            <th
              style={{
                paddingTop: 14,
                paddingBottom: 14,
                paddingLeft: 24,
                paddingRight: 24,
                borderTop: `1px solid ${designTokens.colors.border.subdued}`,
                borderBottom: `1px solid ${designTokens.colors.border.subdued}`,
                textAlign: "start",
              }}
            ></th>
          </tr>
        ))}
      </thead>
      <tbody>
        {getRowModel().rows?.map((row) => (
          <tr key={row.id}>
            {row.getVisibleCells()?.map((cell) => (
              <td
                key={cell.id}
                style={{
                  paddingTop: 14,
                  paddingBottom: 14,
                  paddingLeft: 24,
                  paddingRight: 24,
                  borderTop: `1px solid ${designTokens.colors.border.subdued}`,
                }}
              >
                {flexRender(cell.column.columnDef.cell, cell.getContext())}
              </td>
            ))}
            <td
              style={{
                paddingTop: 14,
                paddingBottom: 14,
                paddingLeft: 24,
                paddingRight: 24,
                borderTop: `1px solid ${designTokens.colors.border.subdued}`,
              }}
            ></td>
          </tr>
        ))}
      </tbody>
    </table>
  );
};

const DashboardUpload = (): ReactNode => {
  const route = useParams({
    from: "/test/$slug/upload",
  });

  const test = useApiQuery("/tests/:slug", {
    slug: route.slug,
  });

  const school = useSchool();

  const user = useLoggedInUser();
  const isLoading =
    school.isInitialLoading || test.isInitialLoading || user.isInitialLoading;
  const [configuringGroup, setConfiguringGroup] = React.useState<string>();

  const uploadFile = useUploadTestScan(test.data?.id ?? "");
  const queryClient = useQueryClient();
  const designTokens = useDesignTokens();
  const [fileUploading, setFileUploading] = useState(false);

  const inner =
    isLoading || !test.data ? (
      <Loader />
    ) : (
      <>
        <Text textStyle="headingLarge">Test Scans</Text>
        <Column
          grow
          gap={16}
          style={{
            flexBasis: 0,
            flexGrow: 2,
          }}
        >
          <Card
            style={{
              gap: 16,
              padding: 24,
            }}
          >
            <Text textStyle="headingSmall">Upload</Text>
            {fileUploading ? (
              <Loader />
            ) : (
              <FileInput
                onChange={(file) => {
                  if (file) {
                    uploadFile.mutateAsync(file).finally(() => {
                      setFileUploading(false);
                      queryClient.invalidateQueries(["test-scans"]);
                    });
                    setFileUploading(true);
                  }
                }}
                accept="pdf"
              />
            )}
            <Row gap={8} style={{ alignItems: "flex-start" }}>
              <span
                style={{
                  backgroundColor: designTokens.colors.brand[8],
                  borderRadius: 12,
                  padding: 12,
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Icon
                  name="information-circle-contained"
                  color={designTokens.colors.brand[100]}
                />
              </span>
              <Column gap={4}>
                <Text textStyle="strong">Uploading Instructions</Text>
                <Text textStyle="caption">
                  Your scanned tests must be PDF format. Make sure that all test
                  identifiers are not cut off and clearly visible. Please do not
                  discard the tests until you’ve received results.
                </Text>
              </Column>
            </Row>
          </Card>
          <Card
            style={{
              gap: 16,
              padding: 24,
            }}
          >
            <Text textStyle="headingSmall">Uploaded</Text>
            <ScansTable />
          </Card>
        </Column>
      </>
    );

  return (
    <PageContainer>
      {inner}
      {configuringGroup && (
        <GroupConfigurationModal
          open={true}
          onClose={() => {
            setConfiguringGroup(undefined);
          }}
          groupId={configuringGroup}
        />
      )}
    </PageContainer>
  );
};

export default DashboardUpload;
