import { useMutation } from "@tanstack/react-query";
import api, { getToken } from "./api";

interface UploadResponse {
  message: string;
}

const uploadTestScan = async ({
  file,
  testId,
}: {
  file: File;
  testId: string;
}): Promise<UploadResponse> => {
  const formData = new FormData();
  formData.append("file", file);

  const response = await api.post<UploadResponse>(
    `/test-scans/${testId}`,
    formData,
    {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${getToken()}`,
      },
    }
  );

  return response.data;
};

export const useUploadTestScan = (testId: string) => {
  return useMutation((file: File) => uploadTestScan({ file, testId }));
};
