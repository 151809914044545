var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { styled } from "styled-components";
import useLockBodyScroll from "../lib/use-lock-body-scroll";
var CompactModal = function (_a) {
    var children = _a.children, open = _a.open, close = _a.close, style = _a.style;
    useLockBodyScroll(open);
    return (_jsx("div", __assign({ style: {
            display: open ? "flex" : "none",
            position: "fixed",
            zIndex: 1,
            top: 0,
            left: 0,
            width: "100%",
            height: "100dvh",
            boxSizing: "border-box",
            backgroundColor: "rgba(0, 0, 0, 0.5)",
            overflow: "scroll",
            cursor: "pointer",
            justifyContent: "center",
        }, onClick: close }, { children: _jsx("div", __assign({ style: {
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                width: "100%",
                position: "relative",
            } }, { children: _jsxs(OuterContainer, __assign({ onClick: function (event) { return event.stopPropagation(); }, style: style }, { children: [_jsx(ModalBackground, { children: children }), _jsx(BottomPadding, {})] })) })) })));
};
export default CompactModal;
var OuterContainer = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  max-width: 720px;\n  cursor: default;\n  min-width: 100%;\n  display: flex;\n  margin: 24px;\n  align-items: flex-end;\n  justify-content: center;\n  pointer-events: none;\n\n  @media only screen and ", " {\n    pointer-events: none;\n    margin-top: 0;\n    align-items: unset;\n    min-width: 50%;\n  }\n"], ["\n  max-width: 720px;\n  cursor: default;\n  min-width: 100%;\n  display: flex;\n  margin: 24px;\n  align-items: flex-end;\n  justify-content: center;\n  pointer-events: none;\n\n  @media only screen and ", " {\n    pointer-events: none;\n    margin-top: 0;\n    align-items: unset;\n    min-width: 50%;\n  }\n"])), function (_a) {
    var devices = _a.theme.devices;
    return devices.md;
});
var BottomPadding = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  @media only screen and ", " {\n    height: 10vh;\n    pointer-events: none;\n  }\n"], ["\n  @media only screen and ", " {\n    height: 10vh;\n    pointer-events: none;\n  }\n"])), function (_a) {
    var devices = _a.theme.devices;
    return devices.md;
});
var ModalBackground = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  background-color: white;\n  box-shadow: 0px 8px 28px 0px rgba(0, 0, 0, 0.16),\n    0px 0px 2px 0px rgba(0, 0, 0, 0.16);\n  padding: 24px;\n  border-radius: 10px;\n  min-height: 100%;\n  height: auto;\n  box-sizing: border-box;\n  display: flex;\n  pointer-events: auto;\n  max-width: 438px;\n"], ["\n  background-color: white;\n  box-shadow: 0px 8px 28px 0px rgba(0, 0, 0, 0.16),\n    0px 0px 2px 0px rgba(0, 0, 0, 0.16);\n  padding: 24px;\n  border-radius: 10px;\n  min-height: 100%;\n  height: auto;\n  box-sizing: border-box;\n  display: flex;\n  pointer-events: auto;\n  max-width: 438px;\n"])));
var ModalButtonsRowWrapper = styled.div(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  gap: 16px;\n  display: flex;\n  flex-direction: row;\n  justify-content: stretch;\n  flex-grow: 1;\n  align-items: flex-end;\n  & > * {\n    flex-grow: 1;\n  }\n\n  background: #fff;\n\n  position: sticky;\n  bottom: 0;\n  left: 0;\n  right: 0;\n\n  @media only screen and ", " {\n    position: relative;\n  }\n"], ["\n  gap: 16px;\n  display: flex;\n  flex-direction: row;\n  justify-content: stretch;\n  flex-grow: 1;\n  align-items: flex-end;\n  & > * {\n    flex-grow: 1;\n  }\n\n  background: #fff;\n\n  position: sticky;\n  bottom: 0;\n  left: 0;\n  right: 0;\n\n  @media only screen and ", " {\n    position: relative;\n  }\n"])), function (_a) {
    var devices = _a.theme.devices;
    return devices.md;
});
var ModalButtonsRowInner = styled.div(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  gap: 16px;\n  display: flex;\n  flex-direction: row;\n  justify-content: stretch;\n  flex-grow: 1;\n  align-items: flex-end;\n  & > * {\n    flex-grow: 1;\n  }\n\n  background: #fff;\n  border-top: 1px solid var(--border-default, #bfbfbf);\n\n  margin-left: -36px;\n  margin-right: -36px;\n  padding: 8px;\n\n  @media only screen and ", " {\n    flex: 1;\n    align-items: flex-end;\n    margin: 0;\n    padding: 0;\n    border-top: none;\n    background: none;\n    position: relative;\n    & > * {\n      flex-grow: unset;\n    }\n  }\n"], ["\n  gap: 16px;\n  display: flex;\n  flex-direction: row;\n  justify-content: stretch;\n  flex-grow: 1;\n  align-items: flex-end;\n  & > * {\n    flex-grow: 1;\n  }\n\n  background: #fff;\n  border-top: 1px solid var(--border-default, #bfbfbf);\n\n  margin-left: -36px;\n  margin-right: -36px;\n  padding: 8px;\n\n  @media only screen and ", " {\n    flex: 1;\n    align-items: flex-end;\n    margin: 0;\n    padding: 0;\n    border-top: none;\n    background: none;\n    position: relative;\n    & > * {\n      flex-grow: unset;\n    }\n  }\n"])), function (_a) {
    var devices = _a.theme.devices;
    return devices.md;
});
export var ModalButtonsRow = function (_a) {
    var children = _a.children, style = _a.style;
    return (
    // Wrapper to make it fill vertical space in modal
    _jsx(ModalButtonsRowWrapper, __assign({ style: style }, { children: _jsx(ModalButtonsRowInner, { children: children }) })));
};
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5;
