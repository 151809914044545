import { jsx as _jsx } from "react/jsx-runtime";
import Input from "./input";
var getFormField = function (form) {
    return function (_a) {
        var name = _a.name, label = _a.label, placeholder = _a.placeholder, required = _a.required, style = _a.style, options = _a.options, type = _a.type, errorText = _a.errorText, autocomplete = _a.autocomplete, index = _a.index;
        return (
        // @ts-ignore
        _jsx(form.Field, { index: index, name: name, children: function (_a) {
                var name = _a.name, state = _a.state, handleBlur = _a.handleBlur, handleChange = _a.handleChange;
                return (_jsx(Input, { style: style, label: label, placeholder: placeholder, required: required, autocomplete: autocomplete, 
                    // @ts-ignore
                    name: name, options: options, type: type, inputProps: {
                        value: state.value,
                        onBlur: handleBlur,
                        onChange: function (e) {
                            // This will work as long as we use all string values until I
                            // can fix this
                            // @ts-ignore
                            handleChange(e.target.value);
                        },
                    }, errorText: state.meta.error || errorText || undefined }));
            } }));
    };
};
export default getFormField;
