var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState } from "react";
import Button from "./button";
import Text from "./text";
import { usePopper } from "react-popper";
import { useClickAway } from "@uidotdev/usehooks";
import { mergeRefs } from "react-merge-refs";
import Icon from "./icon";
import styled from "styled-components";
import { useDesignTokens } from "../lib/design-tokens";
var OptionContainer = styled.button(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  appearance: none;\n  border: none;\n  background-color: transparent;\n  width: 100%;\n  display: flex;\n  flex-direction: row;\n  gap: 8px;\n  align-items: center;\n  padding: 12px 16px;\n  border-radius: 12px;\n  cursor: pointer;\n\n  &:hover {\n    background-color: ", ";\n  }\n"], ["\n  appearance: none;\n  border: none;\n  background-color: transparent;\n  width: 100%;\n  display: flex;\n  flex-direction: row;\n  gap: 8px;\n  align-items: center;\n  padding: 12px 16px;\n  border-radius: 12px;\n  cursor: pointer;\n\n  &:hover {\n    background-color: ", ";\n  }\n"])), function (_a) {
    var theme = _a.theme;
    return theme.colors.surface.disabled;
});
/**
 * A button that opens a dropdown menu. The menu has a number of options which
 * can be selected.
 */
var DropdownButton = function (_a) {
    var options = _a.options, onSelect = _a.onSelect, props = __rest(_a, ["options", "onSelect"]);
    var _b = useState(false), isOpen = _b[0], setIsOpen = _b[1];
    var _c = useState(null), referenceElement = _c[0], setReferenceElement = _c[1];
    var _d = useState(null), popperElement = _d[0], setPopperElement = _d[1];
    var _e = usePopper(referenceElement, popperElement, {
        placement: "bottom-start",
        modifiers: [
            {
                name: "offset",
                options: {
                    offset: [0, 6],
                },
            },
        ],
    }), styles = _e.styles, attributes = _e.attributes;
    var clickAwayRef = useClickAway(function () { return setIsOpen(false); });
    var designTokens = useDesignTokens();
    var toggle = function () { return setIsOpen(!isOpen); };
    return (_jsxs("span", __assign({ style: {
            position: "relative",
        }, ref: mergeRefs([clickAwayRef, setReferenceElement]) }, { children: [_jsx(Button, __assign({}, props, { onPress: toggle, icon: "chevron-".concat(isOpen ? "up" : "down", "-filled"), style: __assign(__assign({}, props.style), (props.disabled
                    ? {
                        backgroundColor: "transparent",
                        border: "1px solid transparent",
                    }
                    : {})) })), isOpen && (_jsx("div", __assign({ ref: setPopperElement, style: __assign({ backgroundColor: designTokens.colors.white, borderRadius: 12, width: 192, padding: 4, boxShadow: "0px 6px 20px 0px rgba(0, 0, 0, 0.12), 0px 0px 2px 0px rgba(0, 0, 0, 0.16)" }, styles.popper) }, attributes.popper, { children: options.map(function (option) { return (_jsxs(OptionContainer, __assign({ onClick: function () {
                        onSelect === null || onSelect === void 0 ? void 0 : onSelect(option.value);
                        setIsOpen(false);
                    } }, { children: [option.icon && (_jsx(Icon, { name: option.icon, color: designTokens.colors.icon.default })), _jsx(Text, { children: option.label })] }))); }) })))] })));
};
export default DropdownButton;
var templateObject_1;
