import { Group } from "@gradience/api-types";

const isGroupCompleted = (
  group: Group | undefined
): group is Group & {
  testDate: NonNullable<Group["testDate"]>;
  name: NonNullable<Group["name"]>;
  instructorFirstName: NonNullable<Group["instructorFirstName"]>;
  instructorLastName: NonNullable<Group["instructorLastName"]>;
  numberOfStudents: NonNullable<Group["numberOfStudents"]>;
} => {
  return (
    Boolean(group?.testDate) &&
    Boolean(group?.name) &&
    Boolean(group?.instructorFirstName) &&
    Boolean(group?.instructorLastName) &&
    Boolean(group?.numberOfStudents) &&
    Boolean(group?.conceptIds?.length)
  );
};

export default isGroupCompleted;
