var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { cloneElement, useState, useEffect, useRef } from "react";
import "./icon.css";
import { useDesignTokens } from "../lib/design-tokens";
import styled from "styled-components";
var IconSizes = {
    small: 16,
    medium: 20,
    large: 32,
};
var iconMap = {
    "arrow-refresh-04": (_jsx("svg", __assign({ width: "20", height: "20", viewBox: "0 0 20 20", fill: "none", xmlns: "http://www.w3.org/2000/svg" }, { children: _jsx("path", { d: "M6.66671 16.1406C4.67404 14.9864 3.33337 12.8289 3.33337 10.3579C3.33337 7.55629 5.05685 5.15773 7.50004 4.16739M7.50004 14.1131L7.50004 17.451H4.16671M13.3334 4.64369C15.326 5.79796 16.6667 7.9554 16.6667 10.4264C16.6667 13.228 14.9432 15.6266 12.5 16.6169M12.5 6.67125V3.33334L15.8334 3.33334", stroke: "currentColor", strokeWidth: "2", strokeLinecap: "round", strokeLinejoin: "round" }) }))),
    "dot-vertical-filled": (_jsxs("svg", __assign({ width: "20", height: "20", viewBox: "0 0 20 20", fill: "none", xmlns: "http://www.w3.org/2000/svg" }, { children: [_jsx("path", { d: "M10 6C8.89543 6 8 5.10457 8 4C8 2.89543 8.89543 2 10 2C11.1046 2 12 2.89543 12 4C12 5.10457 11.1046 6 10 6Z", fill: "currentColor" }), _jsx("path", { d: "M10 12C8.89543 12 8 11.1046 8 10C8 8.89543 8.89543 8 10 8C11.1046 8 12 8.89543 12 10C12 11.1046 11.1046 12 10 12Z", fill: "currentColor" }), _jsx("path", { d: "M10 18C8.89543 18 8 17.1046 8 16C8 14.8954 8.89543 14 10 14C11.1046 14 12 14.8954 12 16C12 17.1046 11.1046 18 10 18Z", fill: "currentColor" })] }))),
    filter: (_jsx("svg", __assign({ width: "20", height: "20", viewBox: "0 0 20 20", fill: "none", xmlns: "http://www.w3.org/2000/svg" }, { children: _jsx("path", { d: "M5.38459 9.99998H14.6154M3.33331 5.83331H16.6666M8.46152 14.1666H11.5384", stroke: "currentColor", strokeWidth: "2", strokeLinecap: "round", strokeLinejoin: "round" }) }))),
    "external-link": (_jsx("svg", __assign({ width: "16", height: "16", viewBox: "0 0 16 16", fill: "none", xmlns: "http://www.w3.org/2000/svg" }, { children: _jsx("path", { d: "M7.25 2H4.25C3.00736 2 2 3.00735 2 4.24999V11.75C2 12.9926 3.00736 14 4.25 14H11.75C12.9926 14 14 12.9926 14 11.75V8.74996M10.2496 2.00018L14 2M14 2V5.37507M14 2L7.62445 8.37478", stroke: "currentColor", strokeWidth: "2", strokeLinecap: "round", strokeLinejoin: "round" }) }))),
    "menu-01": (_jsx("svg", __assign({ width: "20", height: "20", viewBox: "0 0 20 20", fill: "none", xmlns: "http://www.w3.org/2000/svg" }, { children: _jsx("path", { d: "M16.6666 15H3.33331M16.6666 10H3.33331M16.6666 5H3.33331", stroke: "currentColor", strokeWidth: "2", strokeLinecap: "round" }) }))),
    calendar: (_jsx("svg", __assign({ width: "20", height: "20", viewBox: "0 0 20 20", fill: "none", xmlns: "http://www.w3.org/2000/svg" }, { children: _jsx("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M15.4471 2.83234C15.4471 2.37265 15.0874 2 14.6436 2C14.1998 2 13.84 2.37265 13.84 2.83234V3.94271L6.25 3.94275V2.83243C6.25 2.37274 5.89023 2.00009 5.44643 2.00009C5.00263 2.00009 4.64286 2.37274 4.64286 2.83243V3.94275C3.45939 3.94275 2.5 4.93649 2.5 6.16232V15.7804C2.5 17.0063 3.45939 18 4.64286 18H15.3571C16.5406 18 17.5 17.0063 17.5 15.7804V6.16232C17.5 4.96771 16.5889 3.99352 15.4471 3.94467L15.4471 3.94271V2.83234ZM4.91071 8.38188C4.91071 7.9222 5.27049 7.54955 5.71429 7.54955H14.6429C15.0867 7.54955 15.4464 7.9222 15.4464 8.38188C15.4464 8.84157 15.0867 9.21422 14.6429 9.21422H5.71429C5.27049 9.21422 4.91071 8.84157 4.91071 8.38188Z", fill: "currentColor" }) }))),
    "calendar-check": (_jsx("svg", __assign({ width: "20", height: "20", viewBox: "0 0 20 20", fill: "none", xmlns: "http://www.w3.org/2000/svg" }, { children: _jsx("path", { d: "M4.58333 7.42855H16.25M6.09127 2.5V3.78586M14.5833 2.5V3.78571M14.5833 3.78571H6.25C4.86929 3.78571 3.75 4.93697 3.75 6.35712V14.9286C3.75 16.3487 4.86929 17.5 6.25 17.5H14.5833C15.964 17.5 17.0833 16.3487 17.0833 14.9286L17.0833 6.35712C17.0833 4.93697 15.964 3.78571 14.5833 3.78571ZM8.33333 12.5714L9.58333 13.8571L12.5 10.8571", stroke: "currentColor", strokeWidth: "2", strokeLinecap: "round", strokeLinejoin: "round" }) }))),
    help: (_jsxs("svg", __assign({ width: "16", height: "17", viewBox: "0 0 16 17", fill: "none", xmlns: "http://www.w3.org/2000/svg" }, { children: [_jsxs("g", __assign({ clipPath: "url(#clip0_240_1031)" }, { children: [_jsx("path", { d: "M8 13.4648C8.55228 13.4648 9 13.0171 9 12.4648C9 11.9126 8.55228 11.4648 8 11.4648C7.44772 11.4648 7 11.9126 7 12.4648C7 13.0171 7.44772 13.4648 8 13.4648Z", fill: "currentColor" }), _jsx("path", { d: "M6.5 4.54184C7.453 3.68184 9.518 3.69684 9.923 5.17684C10.45 7.09884 8 7.10684 8 9.96484", stroke: "currentColor", strokeLinecap: "round", strokeLinejoin: "round" }), _jsx("path", { d: "M14 0.964844H2C1.17157 0.964844 0.5 1.63642 0.5 2.46484V14.4648C0.5 15.2933 1.17157 15.9648 2 15.9648H14C14.8284 15.9648 15.5 15.2933 15.5 14.4648V2.46484C15.5 1.63642 14.8284 0.964844 14 0.964844Z", stroke: "currentColor", strokeLinecap: "round", strokeLinejoin: "round" })] })), _jsx("defs", { children: _jsx("clipPath", __assign({ id: "clip0_240_1031" }, { children: _jsx("rect", { width: "16", height: "16", fill: "white", transform: "translate(0 0.464844)" }) })) })] }))),
    close: (_jsx("svg", __assign({ width: "24", height: "24", viewBox: "0 0 24 24", fill: "none", xmlns: "http://www.w3.org/2000/svg" }, { children: _jsx("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M21 4.38462L13.3846 12L21 19.6154L19.6154 21L12 13.3846L4.38462 21L3 19.6154L10.6154 12L3 4.38462L4.38462 3L12 10.6154L19.6154 3L21 4.38462Z", fill: "currentColor" }) }))),
    menu: (_jsxs("svg", __assign({ width: "24", height: "24", viewBox: "0 0 24 24", fill: "none", xmlns: "http://www.w3.org/2000/svg" }, { children: [_jsx("path", { d: "M5 6H19", stroke: "currentColor", strokeWidth: "1.5", strokeLinecap: "round", strokeLinejoin: "round" }), _jsx("path", { d: "M5 18H19", stroke: "currentColor", strokeWidth: "1.5", strokeLinecap: "round", strokeLinejoin: "round" }), _jsx("path", { d: "M5 12H19", stroke: "currentColor", strokeWidth: "1.5", strokeLinecap: "round", strokeLinejoin: "round" })] }))),
    "caret-down": (_jsx("svg", __assign({ width: "24", height: "24", viewBox: "0 0 24 24", fill: "none", xmlns: "http://www.w3.org/2000/svg" }, { children: _jsx("path", { d: "M17 10L12 16L7 10H17Z", fill: "currentColor" }) }))),
    "check-02": (_jsx("svg", __assign({ width: "20", height: "20", viewBox: "0 0 20 20", fill: "none", xmlns: "http://www.w3.org/2000/svg" }, { children: _jsx("path", { d: "M5.8335 9.89869C6.61803 10.4693 8.1871 12.0383 8.82899 13.1082C9.61353 11.3964 11.6105 7.54509 13.7502 5.83337", stroke: "currentColor", strokeWidth: "2", strokeLinecap: "round", strokeLinejoin: "round" }) }))),
    eye: (_jsxs("svg", __assign({ width: "24", height: "24", viewBox: "0 0 24 24", fill: "none", xmlns: "http://www.w3.org/2000/svg" }, { children: [_jsx("path", { d: "M20.2 9.0125C21.6 10.7025 21.6 13.3025 20.2 14.9925C18.2 17.4025 15.27 18.9425 12 18.9425C8.73001 18.9425 5.81 17.4125 3.8 14.9925C2.4 13.3025 2.4 10.7025 3.8 9.0125C5.8 6.6025 8.73001 5.0625 12 5.0625C15.27 5.0625 18.19 6.5925 20.2 9.0125Z", stroke: "currentColor", strokeWidth: "1.5", strokeLinecap: "round", strokeLinejoin: "round" }), _jsx("path", { d: "M20.2 9.0125C21.6 10.7025 21.6 13.3025 20.2 14.9925C18.2 17.4025 15.27 18.9425 12 18.9425C8.73001 18.9425 5.81 17.4125 3.8 14.9925C2.4 13.3025 2.4 10.7025 3.8 9.0125C5.8 6.6025 8.73001 5.0625 12 5.0625C15.27 5.0625 18.19 6.5925 20.2 9.0125Z", stroke: "currentColor", strokeWidth: "1.5", strokeLinecap: "round", strokeLinejoin: "round" }), _jsx("path", { d: "M11.9999 15.0819C13.7009 15.0819 15.0799 13.7029 15.0799 12.0019C15.0799 10.3008 13.7009 8.92188 11.9999 8.92188C10.2989 8.92188 8.91992 10.3008 8.91992 12.0019C8.91992 13.7029 10.2989 15.0819 11.9999 15.0819Z", stroke: "currentColor", strokeWidth: "1.5", strokeLinecap: "round", strokeLinejoin: "round" }), _jsx("path", { d: "M2.75 2.75L6.59 6.59L9.82 9.82L14.18 14.18L17.41 17.41L21.25 21.25", stroke: "currentColor", strokeWidth: "1.5", strokeLinecap: "round", strokeLinejoin: "round", className: "animated-stroke-out" })] }))),
    "eye-off": (_jsxs("svg", __assign({ width: "24", height: "24", viewBox: "0 0 24 24", fill: "none", xmlns: "http://www.w3.org/2000/svg" }, { children: [_jsx("path", { d: "M14.52 18.6301C13.71 18.8401 12.87 18.9401 12 18.9401C8.73 18.9401 5.8 17.4101 3.8 14.9901C2.4 13.3001 2.4 10.6901 3.8 9.01006C3.96 8.81006 4.14 8.62005 4.32 8.43005", stroke: "currentColor", strokeWidth: "1.5", strokeLinecap: "round", strokeLinejoin: "round" }), _jsx("path", { d: "M17.4001 17.4101C18.4501 16.7701 19.4001 15.9501 20.2001 14.9901C21.6001 13.3101 21.6001 10.6901 20.2001 9.01006C18.2001 6.59006 15.2701 5.06006 12.0001 5.06006C10.0201 5.06006 8.18009 5.61006 6.59009 6.59006", stroke: "currentColor", strokeWidth: "1.5", strokeLinecap: "round", strokeLinejoin: "round" }), _jsx("path", { d: "M14.1801 14.18C14.7301 13.62 15.0801 12.85 15.0801 12C15.0801 10.29 13.7101 8.92004 12.0001 8.92004C11.1501 8.92004 10.3701 9.26005 9.82007 9.82005", stroke: "currentColor", strokeWidth: "1.5", strokeLinecap: "round", strokeLinejoin: "round" }), _jsx("path", { d: "M2.75 2.75L6.59 6.59L9.82 9.82L14.18 14.18L17.41 17.41L21.25 21.25", stroke: "currentColor", strokeWidth: "1.5", strokeLinecap: "round", strokeLinejoin: "round", className: "animated-stroke-in" })] }))),
    "user-profile-group-filled": (_jsxs("svg", __assign({ width: "20", height: "20", viewBox: "0 0 20 20", fill: "none", xmlns: "http://www.w3.org/2000/svg" }, { children: [_jsx("path", { d: "M7.77772 8.8889C9.31185 8.8889 10.5555 7.64525 10.5555 6.11112C10.5555 4.577 9.31185 3.33334 7.77772 3.33334C6.2436 3.33334 4.99995 4.577 4.99995 6.11112C4.99995 7.64525 6.2436 8.8889 7.77772 8.8889Z", fill: "currentColor" }), _jsx("path", { d: "M15.5555 7.22223C15.5555 8.14271 14.8093 8.8889 13.8888 8.8889C12.9684 8.8889 12.2222 8.14271 12.2222 7.22223C12.2222 6.30176 12.9684 5.55557 13.8888 5.55557C14.8093 5.55557 15.5555 6.30176 15.5555 7.22223Z", fill: "currentColor" }), _jsx("path", { d: "M14.5833 14.7819C14.4724 15.1037 14.7049 15.4769 15.0405 15.4205C16.9357 15.1022 17.7777 14.0246 17.7777 12.7442C17.7777 11.2134 15.9996 9.97245 13.8061 9.97245C13.4837 9.97245 13.1702 9.99926 12.8701 10.0499C12.7864 10.064 12.7676 10.1743 12.8391 10.2201C13.881 10.8891 14.799 11.9903 14.799 13.4608C14.799 13.9252 14.7257 14.3688 14.5833 14.7819Z", fill: "currentColor" }), _jsx("path", { d: "M7.87873 16.9192C11.8212 16.9192 13.5353 15.3695 13.5353 13.4578C13.5353 11.5462 11.0028 9.99648 7.87873 9.99648C4.7547 9.99648 2.22217 11.5462 2.22217 13.4578C2.22217 15.3695 3.93628 16.9192 7.87873 16.9192Z", fill: "currentColor" })] }))),
    "grid-04-filled": (_jsxs("svg", __assign({ width: "20", height: "20", viewBox: "0 0 20 20", fill: "none", xmlns: "http://www.w3.org/2000/svg" }, { children: [_jsx("path", { d: "M2 3C2 2.44772 2.44772 2 3 2H5C5.55228 2 6 2.44772 6 3V5C6 5.55228 5.55228 6 5 6H3C2.44772 6 2 5.55228 2 5V3Z", fill: "currentColor" }), _jsx("path", { d: "M8 3C8 2.44772 8.44772 2 9 2H11C11.5523 2 12 2.44772 12 3V5C12 5.55228 11.5523 6 11 6H9C8.44772 6 8 5.55228 8 5V3Z", fill: "currentColor" }), _jsx("path", { d: "M14 3C14 2.44772 14.4477 2 15 2H17C17.5523 2 18 2.44772 18 3V5C18 5.55228 17.5523 6 17 6H15C14.4477 6 14 5.55228 14 5V3Z", fill: "currentColor" }), _jsx("path", { d: "M2 9C2 8.44772 2.44772 8 3 8H5C5.55228 8 6 8.44772 6 9V11C6 11.5523 5.55228 12 5 12H3C2.44772 12 2 11.5523 2 11V9Z", fill: "currentColor" }), _jsx("path", { d: "M8 9C8 8.44772 8.44772 8 9 8H11C11.5523 8 12 8.44772 12 9V11C12 11.5523 11.5523 12 11 12H9C8.44772 12 8 11.5523 8 11V9Z", fill: "currentColor" }), _jsx("path", { d: "M14 9C14 8.44772 14.4477 8 15 8H17C17.5523 8 18 8.44772 18 9V11C18 11.5523 17.5523 12 17 12H15C14.4477 12 14 11.5523 14 11V9Z", fill: "currentColor" }), _jsx("path", { d: "M2 15C2 14.4477 2.44772 14 3 14H5C5.55228 14 6 14.4477 6 15V17C6 17.5523 5.55228 18 5 18H3C2.44772 18 2 17.5523 2 17V15Z", fill: "currentColor" }), _jsx("path", { d: "M8 15C8 14.4477 8.44772 14 9 14H11C11.5523 14 12 14.4477 12 15V17C12 17.5523 11.5523 18 11 18H9C8.44772 18 8 17.5523 8 17V15Z", fill: "currentColor" }), _jsx("path", { d: "M14 15C14 14.4477 14.4477 14 15 14H17C17.5523 14 18 14.4477 18 15V17C18 17.5523 17.5523 18 17 18H15C14.4477 18 14 17.5523 14 17V15Z", fill: "currentColor" })] }))),
    survey: (_jsxs("svg", __assign({ width: "31", height: "32", viewBox: "0 0 31 32", fill: "none", xmlns: "http://www.w3.org/2000/svg" }, { children: [_jsx("path", { d: "M17 11.0289H23", stroke: "currentColor", strokeWidth: "2", strokeLinecap: "round", strokeLinejoin: "round" }), _jsx("path", { d: "M13 8.02893H9C8.44772 8.02893 8 8.47665 8 9.02893V13.0289C8 13.5812 8.44772 14.0289 9 14.0289H13C13.5523 14.0289 14 13.5812 14 13.0289V9.02893C14 8.47665 13.5523 8.02893 13 8.02893Z", stroke: "currentColor", strokeWidth: "2", strokeLinecap: "round", strokeLinejoin: "round" }), _jsx("path", { d: "M17 20.0289H23", stroke: "currentColor", strokeWidth: "2", strokeLinecap: "round", strokeLinejoin: "round" }), _jsx("path", { d: "M14 17.0289L8 23.0289", stroke: "currentColor", strokeWidth: "2", strokeLinecap: "round", strokeLinejoin: "round" }), _jsx("path", { d: "M8 17.0289L14 23.0289", stroke: "currentColor", strokeWidth: "2", strokeLinecap: "round", strokeLinejoin: "round" })] }))),
    list: (_jsx("svg", __assign({ width: "24", height: "24", viewBox: "0 0 24 24", fill: "none", xmlns: "http://www.w3.org/2000/svg" }, { children: _jsx("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M2 2H4V4H2V2ZM0 6V0H6V6H0ZM2 11H4V13H2V11ZM0 15V9H6V15H0ZM4 20H2V22H4V20ZM0 18V24H6V18H0ZM24 2H8V4H24V2ZM8 11H24V13H8V11ZM24 20H8V22H24V20Z", fill: "currentColor" }) }))),
    pencil: (_jsxs("svg", __assign({ width: "24", height: "24", viewBox: "0 0 24 24", fill: "none", xmlns: "http://www.w3.org/2000/svg" }, { children: [_jsx("path", { d: "M6.46001 21.24L21.25 6.45L17.55 2.75L2.75999 17.54L2.75 21.25L6.46001 21.24Z", stroke: "currentColor", strokeWidth: "1.5", strokeLinecap: "round", strokeLinejoin: "round" }), _jsx("path", { d: "M15.3496 6.13281L17.8696 8.6528", stroke: "currentColor", strokeWidth: "1.5", strokeLinecap: "round", strokeLinejoin: "round" })] }))),
    school: (_jsxs("svg", __assign({ width: "31", height: "32", viewBox: "0 0 31 32", fill: "none", xmlns: "http://www.w3.org/2000/svg" }, { children: [_jsx("path", { d: "M22 12.0289V8.02893H19V10.0289H22", stroke: "currentColor", strokeMiterlimit: "10", strokeLinecap: "round", strokeLinejoin: "round" }), _jsx("path", { d: "M17 23.0289V18.0289H14V23.0289", stroke: "currentColor", strokeMiterlimit: "10", strokeLinecap: "round", strokeLinejoin: "round" }), _jsx("path", { d: "M19 14.0289L15.5 11.0289L12 14.0289H8V23.0289H23V14.0289H19Z", stroke: "currentColor", strokeMiterlimit: "10", strokeLinecap: "round", strokeLinejoin: "round" })] }))),
    user: (_jsxs("svg", __assign({ width: "24", height: "24", viewBox: "0 0 24 24", fill: "none", xmlns: "http://www.w3.org/2000/svg" }, { children: [_jsx("path", { d: "M19.1906 17.6338C17.4506 16.0738 14.8705 15.0938 12.0005 15.0938C9.13055 15.0938 6.55055 16.0838 4.81055 17.6338", stroke: "currentColor", strokeWidth: "1.5", strokeLinecap: "round", strokeLinejoin: "round" }), _jsx("path", { d: "M11.9997 11.9994C13.4191 11.9994 14.5697 10.8488 14.5697 9.42938C14.5697 8.01001 13.4191 6.85938 11.9997 6.85938C10.5803 6.85938 9.42969 8.01001 9.42969 9.42938C9.42969 10.8488 10.5803 11.9994 11.9997 11.9994Z", stroke: "currentColor", strokeWidth: "1.5", strokeLinecap: "round", strokeLinejoin: "round" }), _jsx("path", { d: "M12 21.25C17.1086 21.25 21.25 17.1086 21.25 12C21.25 6.89137 17.1086 2.75 12 2.75C6.89137 2.75 2.75 6.89137 2.75 12C2.75 17.1086 6.89137 21.25 12 21.25Z", stroke: "currentColor", strokeWidth: "1.5", strokeLinecap: "round", strokeLinejoin: "round" })] }))),
    show: (_jsxs("svg", __assign({ width: "16", height: "16", viewBox: "0 0 16 16", fill: "none", xmlns: "http://www.w3.org/2000/svg" }, { children: [_jsxs("g", __assign({ clipPath: "url(#clip0_10_497)" }, { children: [_jsx("path", { d: "M14.5 0.5H1.5V7.5H14.5V0.5Z", stroke: "currentColor", strokeLinecap: "round", strokeLinejoin: "round" }), _jsx("path", { d: "M6.5 11.5V9.5H3.5V11.5", stroke: "currentColor", strokeLinecap: "round", strokeLinejoin: "round" }), _jsx("path", { d: "M12.5 11.5V9.5H9.5V11.5", stroke: "currentColor", strokeLinecap: "round", strokeLinejoin: "round" }), _jsx("path", { d: "M3.5 15.5V13.5H0.5V15.5", stroke: "currentColor", strokeLinecap: "round", strokeLinejoin: "round" }), _jsx("path", { d: "M15.5 15.5V13.5H12.5V15.5", stroke: "currentColor", strokeLinecap: "round", strokeLinejoin: "round" }), _jsx("path", { d: "M9.5 15.5V13.5H6.5V15.5", stroke: "currentColor", strokeLinecap: "round", strokeLinejoin: "round" })] })), _jsx("defs", { children: _jsx("clipPath", __assign({ id: "clip0_10_497" }, { children: _jsx("rect", { width: "16", height: "16", fill: "white" }) })) })] }))),
    "opening-times": (_jsxs("svg", __assign({ width: "16", height: "16", viewBox: "0 0 16 16", fill: "none", xmlns: "http://www.w3.org/2000/svg" }, { children: [_jsxs("g", __assign({ clipPath: "url(#clip0_10_509)" }, { children: [_jsx("path", { d: "M0.5 5.5H15.5", stroke: "currentColor", strokeLinecap: "round", strokeLinejoin: "round" }), _jsx("path", { d: "M15.5 5.5V4C15.5 3.172 14.828 2.5 14 2.5H2C1.172 2.5 0.5 3.172 0.5 4V14C0.5 14.828 1.172 15.5 2 15.5H6.5", stroke: "currentColor", strokeLinecap: "round", strokeLinejoin: "round" }), _jsx("path", { d: "M4.5 0.5V2.5", stroke: "currentColor", strokeLinecap: "round", strokeLinejoin: "round" }), _jsx("path", { d: "M11.5 0.5V2.5", stroke: "currentColor", strokeLinecap: "round", strokeLinejoin: "round" }), _jsx("path", { d: "M11.5 15.5C13.7091 15.5 15.5 13.7091 15.5 11.5C15.5 9.29086 13.7091 7.5 11.5 7.5C9.29086 7.5 7.5 9.29086 7.5 11.5C7.5 13.7091 9.29086 15.5 11.5 15.5Z", stroke: "currentColor", strokeLinecap: "round", strokeLinejoin: "round" }), _jsx("path", { d: "M11.5 9.5V11.5H13.5", stroke: "currentColor", strokeLinecap: "round", strokeLinejoin: "round" })] })), _jsx("defs", { children: _jsx("clipPath", __assign({ id: "clip0_10_509" }, { children: _jsx("rect", { width: "16", height: "16", fill: "white" }) })) })] }))),
    "chevron-left": (_jsx("svg", __assign({ width: "20", height: "20", viewBox: "0 0 20 20", fill: "none", xmlns: "http://www.w3.org/2000/svg" }, { children: _jsx("path", { d: "M12.5002 14.1667L8.3335 10L12.5002 5.83335", stroke: "currentColor", strokeWidth: "2", strokeLinecap: "round", strokeLinejoin: "round" }) }))),
    "chevron-up": (_jsx("svg", __assign({ width: "16", height: "16", viewBox: "0 0 16 16", fill: "none", xmlns: "http://www.w3.org/2000/svg" }, { children: _jsx("path", { d: "M4.66602 9.33301L7.99989 6.27967L11.3327 9.33301", stroke: "currentColor", strokeWidth: "1.6", strokeLinecap: "round", strokeLinejoin: "round" }) }))),
    "chevron-down": (_jsx("svg", __assign({ width: "16", height: "16", viewBox: "0 0 16 16", fill: "none", xmlns: "http://www.w3.org/2000/svg" }, { children: _jsx("path", { d: "M4.66699 6.66699L8.00087 9.72033L11.3337 6.66699", stroke: "currentColor", strokeWidth: "1.6", strokeLinecap: "round", strokeLinejoin: "round" }) }))),
    "chevron-right": (_jsx("svg", __assign({ width: "20", height: "20", viewBox: "0 0 20 20", fill: "none", xmlns: "http://www.w3.org/2000/svg" }, { children: _jsx("path", { d: "M8.33333 5.83334L12.5 10L8.33333 14.1667", stroke: "currentColor", strokeWidth: "2", strokeLinecap: "round", strokeLinejoin: "round" }) }))),
    "upload-03": (_jsx("svg", __assign({ width: "20", height: "20", viewBox: "0 0 20 20", fill: "none", xmlns: "http://www.w3.org/2000/svg" }, { children: _jsx("path", { d: "M3.33301 12.6703V15.7437C3.33301 16.2095 3.5086 16.6562 3.82116 16.9856C4.13372 17.315 4.55765 17.5 4.99967 17.5H14.9997C15.4417 17.5 15.8656 17.315 16.1782 16.9856C16.4907 16.6562 16.6663 16.2095 16.6663 15.7437V12.6703M10.0003 12.4521L10.0003 2.5M10.0003 2.5L6.19077 6.30265M10.0003 2.5L13.8098 6.30265", stroke: "currentColor", strokeWidth: "2", strokeLinecap: "round", strokeLinejoin: "round" }) }))),
    "plus-01": (_jsx("svg", __assign({ width: "21", height: "20", viewBox: "0 0 21 20", fill: "none", xmlns: "http://www.w3.org/2000/svg" }, { children: _jsx("path", { d: "M10.5 4L10.5 16M16.5 10L4.5 10", stroke: "currentColor", strokeWidth: "2", strokeLinecap: "round" }) }))),
    "trash-04": (_jsx("svg", __assign({ width: "20", height: "20", viewBox: "0 0 20 20", fill: "none", xmlns: "http://www.w3.org/2000/svg" }, { children: _jsx("path", { d: "M3.3335 5.14706L16.6668 5.14706M13.3335 17.5H6.66683C5.74636 17.5 5.00016 16.7099 5.00016 15.7353V6.02941C5.00016 5.5421 5.37326 5.14706 5.8335 5.14706H14.1668C14.6271 5.14706 15.0002 5.5421 15.0002 6.02941V15.7353C15.0002 16.7099 14.254 17.5 13.3335 17.5ZM8.3335 5.14706H11.6668C12.1271 5.14706 12.5002 4.75202 12.5002 4.26471V3.38235C12.5002 2.89504 12.1271 2.5 11.6668 2.5H8.3335C7.87326 2.5 7.50016 2.89504 7.50016 3.38235V4.26471C7.50016 4.75202 7.87326 5.14706 8.3335 5.14706Z", stroke: "currentColor", strokeWidth: "2", strokeLinecap: "round" }) }))),
    "chevron-down-filled": (_jsx("svg", __assign({ width: "16", height: "16", viewBox: "0 0 16 16", fill: "none", xmlns: "http://www.w3.org/2000/svg" }, { children: _jsx("path", { d: "M8.25314 10.5049L12.3284 5.75046C12.5137 5.53424 12.3601 5.2002 12.0753 5.2002L3.9248 5.2002C3.64001 5.2002 3.48638 5.53423 3.67171 5.75046L7.74697 10.5049C7.88 10.6601 8.12011 10.6601 8.25314 10.5049Z", fill: "currentColor" }) }))),
    "chevron-up-filled": (_jsx("svg", __assign({ width: "16", height: "16", viewBox: "0 0 16 16", fill: "none", xmlns: "http://www.w3.org/2000/svg" }, { children: _jsx("path", { d: "M7.6464 5.95316L4.0535 9.54606C3.73852 9.86104 3.96161 10.3996 4.40706 10.3996H11.5928C12.0383 10.3996 12.2614 9.86104 11.9464 9.54606L8.3535 5.95316C8.15824 5.7579 7.84166 5.7579 7.6464 5.95316Z", fill: "currentColor" }) }))),
    "arrow-right": (_jsx("svg", __assign({ xmlns: "http://www.w3.org/2000/svg", width: "16", height: "16", viewBox: "0 0 16 16", fill: "none" }, { children: _jsx("path", { d: "M8.88897 3.33301L13.3334 7.99967M13.3334 7.99967L8.88897 12.6663M13.3334 7.99967L2.66675 7.99967", stroke: "currentColor", strokeWidth: "2", strokeLinecap: "round", strokeLinejoin: "round" }) }))),
    "information-circle-contained": (_jsx("svg", __assign({ width: "20", height: "20", viewBox: "0 0 20 20", fill: "none", xmlns: "http://www.w3.org/2000/svg" }, { children: _jsx("path", { d: "M10 10L10 13.75M10 7.22046V7.1875M2.5 10C2.5 5.85786 5.85787 2.5 10 2.5C14.1421 2.5 17.5 5.85787 17.5 10C17.5 14.1421 14.1421 17.5 10 17.5C5.85786 17.5 2.5 14.1421 2.5 10Z", stroke: "currentColor", strokeWidth: "1.5", strokeLinecap: "round", strokeLinejoin: "round" }) }))),
    "file-question-02": (_jsx("svg", __assign({ width: "20", height: "20", viewBox: "0 0 20 20", fill: "none", xmlns: "http://www.w3.org/2000/svg" }, { children: _jsx("path", { d: "M10 17.9624H4.99999C3.89542 17.9623 2.99999 17.0669 3 15.9623L3.00008 3.96239C3.00008 2.85783 3.89551 1.9624 5.00008 1.9624H14.0003C15.1049 1.9624 16.0003 2.85783 16.0003 3.9624V7.4624M13.0003 13.3437C13.0003 12.2631 13.8957 11.3872 15.0003 11.3872C16.1049 11.3872 17.0003 12.2631 17.0003 13.3437C17.0003 14.4243 16.1049 15.3003 15.0003 15.3003M15.0003 18.0376V17.9624M6.50031 5.9624H12.5003M6.50031 8.9624H12.5003M6.50031 11.9624H9.50031", stroke: "currentColor", strokeWidth: "2", strokeLinecap: "round", strokeLinejoin: "round" }) }))),
    "plus-02": (_jsx("svg", __assign({ width: "21", height: "20", viewBox: "0 0 21 20", fill: "none", xmlns: "http://www.w3.org/2000/svg" }, { children: _jsx("path", { d: "M10.5 5L10.5 15M15.5 10L5.5 10", stroke: "currentColor", strokeWidth: "2", strokeLinecap: "round" }) }))),
    award: (_jsxs("svg", __assign({ width: "16", height: "17", viewBox: "0 0 16 17", fill: "none", xmlns: "http://www.w3.org/2000/svg" }, { children: [_jsx("path", { d: "M6.75987 4.62423L5.14565 1.92912C4.94654 1.59578 4.58121 1.38867 4.19276 1.38867H1.45143C1.05232 1.38867 0.681652 1.60467 0.484318 1.95223C0.286985 2.29978 0.293207 2.72912 0.49854 3.07045L2.92521 7.13445C3.82476 5.86601 5.18298 4.94512 6.75987 4.62423Z", fill: "currentColor" }), _jsx("path", { d: "M15.5153 1.95223C15.318 1.60467 14.9473 1.38867 14.5482 1.38867H11.8069C11.4175 1.38867 11.0522 1.59578 10.854 1.92823L9.23975 4.62423C10.8202 4.94512 12.182 5.86956 13.0815 7.14245L15.5011 3.07134C15.7064 2.72823 15.7117 2.29978 15.5153 1.95223Z", fill: "currentColor" }), _jsx("path", { d: "M7.99973 5.83398C5.30462 5.83398 3.11084 8.02687 3.11084 10.7229C3.11084 13.4189 5.30462 15.6118 7.99973 15.6118C10.6948 15.6118 12.8886 13.4189 12.8886 10.7229C12.8886 8.02687 10.6948 5.83398 7.99973 5.83398ZM10.0877 10.4615L9.36595 11.1655L9.53662 12.1593C9.56506 12.3264 9.49662 12.4944 9.35973 12.594C9.2824 12.65 9.19084 12.6793 9.0984 12.6793C9.02728 12.6793 8.95706 12.6624 8.89217 12.6278L7.99973 12.1593L7.10728 12.6278C6.95795 12.706 6.77662 12.6935 6.63973 12.594C6.50284 12.4944 6.4344 12.3264 6.46284 12.1593L6.63351 11.1655L5.91173 10.4615C5.78995 10.3433 5.7464 10.1664 5.79884 10.0055C5.85128 9.84465 5.99084 9.72821 6.15706 9.70332L7.1544 9.55843L7.60062 8.65443C7.74995 8.35043 8.24862 8.35043 8.39706 8.65443L8.84328 9.55843L9.84062 9.70332C10.0077 9.72732 10.1473 9.84465 10.1988 10.0055C10.2513 10.1664 10.2077 10.3433 10.086 10.4615H10.0877Z", fill: "currentColor" })] }))),
};
var IconButton = styled.button(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  appearance: none;\n  border: none;\n  background: none;\n  cursor: pointer;\n  height: ", "px +\n    ", ";\n  width: ", "px +\n    ", ";\n  border-radius: 8px;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  padding: ", ";\n  cursor: ", ";\n\n  &:hover {\n    background: ", ";\n  }\n"], ["\n  appearance: none;\n  border: none;\n  background: none;\n  cursor: pointer;\n  height: ", "px +\n    ", ";\n  width: ", "px +\n    ", ";\n  border-radius: 8px;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  padding: ", ";\n  cursor: ", ";\n\n  &:hover {\n    background: ", ";\n  }\n"])), function (props) { return IconSizes[props.size]; }, function (props) { return (props.noButtonStyle ? "0" : "8px"); }, function (props) { return IconSizes[props.size]; }, function (props) { return (props.noButtonStyle ? "0" : "8px"); }, function (props) { return (props.noButtonStyle ? "0" : "4px"); }, function (props) { return (props.disabled ? "not-allowed" : "pointer"); }, function (props) {
    return props.noButtonStyle || props.disabled
        ? "initial"
        : props.theme.colors.surface.disabled;
});
var Icon = function (_a) {
    var name = _a.name, color = _a.color, _b = _a.size, size = _b === void 0 ? "medium" : _b, style = _a.style, onClick = _a.onClick, disabled = _a.disabled, noButtonStyle = _a.noButtonStyle;
    var icon = iconMap[name];
    var initialIcon = useRef(name);
    var _c = useState(false), hasChanged = _c[0], setHasChanged = _c[1];
    useEffect(function () {
        if (initialIcon.current === name)
            return;
        setHasChanged(true);
    }, [name]);
    var designTokens = useDesignTokens();
    var inner = cloneElement(icon, {
        key: name,
        color: disabled
            ? designTokens.colors.icon.disabled
            : color || designTokens.colors.black,
        width: IconSizes[size],
        height: IconSizes[size],
        style: onClick ? {} : style,
        className: hasChanged ? "animate" : "",
    });
    return onClick ? (_jsx(IconButton, __assign({ size: size, type: "button", onClick: onClick, disabled: disabled, noButtonStyle: noButtonStyle, style: __assign(__assign({}, style), { cursor: disabled ? "not-allowed" : "pointer" }) }, { children: inner }))) : (inner);
};
export default Icon;
var templateObject_1;
