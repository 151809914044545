import {
  Link,
  useNavigate,
  useParams,
  useSearch,
} from "@tanstack/react-router";
import api, { useApiQuery } from "../../lib/api";
import {
  Loader,
  Button,
  Text,
  Column,
  FileInput,
  Checkbox,
  useDesignTokens,
  Modal,
  ClickableText,
  Row,
  Icon,
  Card,
  Badge,
  Tooltip,
} from "@gradience/ui";
import React, { ReactNode, useMemo, useState } from "react";
import useSchool from "../../lib/use-school";
import PageContainer from "../../components/page-container";
import GroupConfigurationModal from "../../forms/group-configuration-modal";
import { useLoggedInUser } from "../../lib/auth";
import { useUploadTestScan } from "../../lib/use-upload-test-scan";
import { TestScan } from "@gradience/api-types";
import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  useReactTable,
} from "@tanstack/react-table";
import { dashboardResultsRoute, groupDetailRoute } from "../..";

const DashboardResults = (): ReactNode => {
  const route = useParams({
    from: "/test/$slug/home",
  });

  const test = useApiQuery("/tests/:slug", {
    slug: route.slug,
  });

  const groups = useApiQuery("/groups", {});

  const navigate = useNavigate();

  const isLoading = test.isLoading || groups.isLoading;
  const designTokens = useDesignTokens();

  return (
    <PageContainer>
      {isLoading || !test.data ? (
        <Loader />
      ) : (
        <>
          <Text textStyle="headingLarge">Results</Text>
          <Card style={{ padding: 24, gap: 24 }}>
            <Row gap={12} style={{ alignItems: "center" }}>
              <Text textStyle="headingSmall">Percentiles</Text>
              <Tooltip content="To calculate percentiles, students are compared with all other students nationally in their grade level.">
                <Icon
                  color={designTokens.colors.icon.default}
                  name="information-circle-contained"
                />
              </Tooltip>
            </Row>
            <Column>
              {groups.data?.data.map((group, index) => (
                <Row
                  gap={8}
                  style={{
                    alignItems: "center",
                    minHeight: 52,
                    borderBottom:
                      index === groups.data.data.length - 1
                        ? undefined
                        : `1px solid ${designTokens.colors.border.subdued}`,
                  }}
                >
                  <Text
                    textStyle="strong"
                    style={{
                      width: 80,
                    }}
                  >
                    {group.name}
                  </Text>
                  <div
                    style={{
                      flex: 1,
                    }}
                  >
                    <span
                      style={{
                        display: "block",
                        backgroundColor: designTokens.colors.brand[100],
                        borderRadius: 4,
                        height: 8,
                        width: `${(group.averagePercentile ?? 0) * 100}%`,
                      }}
                    />
                  </div>
                  <Text
                    textStyle="strong"
                    style={{
                      width: 80,
                    }}
                  >
                    {group.averagePercentile
                      ? `${Math.round(group.averagePercentile * 100)}%`
                      : "N/A"}
                  </Text>
                </Row>
              ))}
            </Column>
          </Card>
          <Text textStyle="headingXS">Groups</Text>
          <Row
            grow
            gap={16}
            style={{
              flexWrap: "wrap",
            }}
          >
            {groups.data?.data.map((group) => {
              return (
                <Card
                  style={{
                    flexBasis: 256,
                    flexShrink: 0,
                    flexDirection: "column",
                    height: 224,
                    flexGrow: 1,
                    padding: 24,
                  }}
                  key={group.id}
                >
                  <Row gap={20}>
                    <Column gap={8} style={{ flexGrow: 1 }}>
                      <Text textStyle="headingSmall">{group.name}</Text>
                      <Row gap={8}>
                        <Badge>
                          {`Instructor: ${group.instructorFirstName} ${group.instructorLastName}`}
                        </Badge>
                        <Badge>{`${
                          group.numberOfStudents ?? ""
                        } students`}</Badge>
                      </Row>
                    </Column>
                    <Icon
                      name="arrow-right"
                      onClick={() =>
                        navigate({
                          to: groupDetailRoute.id,
                          params: { groupId: group.id, slug: test.data?.slug },
                          search: { prev: "results", modal: undefined },
                        })
                      }
                      size="large"
                      color={designTokens.colors.icon.default}
                    />
                  </Row>
                  <Row style={{ flex: 1, alignItems: "flex-end" }}>
                    {group.averagePercentile ? (
                      <Column gap={4} style={{ flex: 1 }}>
                        <Text textStyle="headingLarge">
                          {Math.round(group.averagePercentile * 100)}%
                        </Text>
                        <Text textStyle="caption">Average Percentile</Text>
                      </Column>
                    ) : null}
                    {group.averagePercentile ? (
                      <Column gap={4} style={{ flex: 1 }}>
                        <Text textStyle="headingLarge">
                          {group.numberOfAwards}
                        </Text>
                        <Text textStyle="caption">Awards Won</Text>
                      </Column>
                    ) : null}
                  </Row>
                </Card>
              );
            })}
          </Row>
        </>
      )}
    </PageContainer>
  );
};

export default DashboardResults;

const ResultsModal = () => {
  const { pageNumber: _pageNumber, testScan: testScanId } = useSearch({
    from: dashboardResultsRoute.id,
  });
  const pageNumber = _pageNumber ?? 1;
  const navigate = useNavigate();
  const closeModal = () =>
    navigate({
      search: {
        pageNumber: undefined,
        testScan: undefined,
      },
    });

  const page = useApiQuery(
    "/test-scan-pages",
    {},
    {},
    { testScanId, pageNumber: pageNumber.toString() }
  );
  const testScans = useApiQuery("/test-scans", {
    id: testScanId,
  });
  const testScan = testScans.data?.data.find(
    (testScan) => testScan.id === testScanId
  );

  return (
    <Modal open={Boolean(testScan)} close={closeModal}>
      <Column gap={12}>
        <Row
          gap={6}
          style={{
            alignItems: "center",
          }}
        >
          <Icon
            name="chevron-left"
            disabled={pageNumber === 1}
            onClick={() =>
              navigate({
                search: {
                  pageNumber: pageNumber - 1,
                  testScan: testScan?.id,
                },
              })
            }
          />
          <Text textStyle="caption">{pageNumber}</Text>
          <Icon
            name="chevron-right"
            disabled={pageNumber === testScan?.numberOfPages}
            onClick={() => {
              navigate({
                search: {
                  testScan: testScan?.id,
                  pageNumber: pageNumber + 1,
                },
              });
            }}
          />
        </Row>
        {page.isLoading ? (
          <Loader />
        ) : (
          <img
            src={page.data?.data[0]?.imageUrl}
            alt={`Page ${pageNumber}`}
            style={{
              width: "100%",
              height: "auto",
            }}
          />
        )}
      </Column>
    </Modal>
  );
};
