var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useHover } from "@uidotdev/usehooks";
import { useDesignTokens } from "../lib/design-tokens";
var Checkbox = function (_a) {
    var value = _a.value, onChange = _a.onChange, inputProps = _a.inputProps;
    var _b = useHover(), ref = _b[0], hovering = _b[1];
    var designTokens = useDesignTokens();
    var checkedOverlay = (_jsx("span", __assign({ style: {
            backgroundColor: designTokens.colors.brand[100],
            opacity: hovering ? 0.8 : 1,
            borderRadius: 4,
            position: "absolute",
            top: 1,
            left: 1,
            width: 18,
            height: 18,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            pointerEvents: "none",
        } }, { children: _jsx("svg", __assign({ width: "10", height: "8", viewBox: "0 0 10 8", fill: "none", xmlns: "http://www.w3.org/2000/svg" }, { children: _jsx("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M3.31476 7.858L0.132954 4.441C-0.044318 4.253 -0.044318 3.947 0.132954 3.757L0.775678 3.074C0.95295 2.886 1.24113 2.886 1.4184 3.074L3.63657 5.466L8.5811 0.141C8.75837 -0.047 9.04655 -0.047 9.22382 0.141L9.86655 0.825C10.0438 1.013 10.0438 1.32 9.86655 1.507L3.95748 7.858C3.78021 8.046 3.49203 8.046 3.31476 7.858Z", fill: "white" }) })) })));
    return (_jsxs("span", __assign({ style: {
            position: "relative",
            display: "flex",
        } }, { children: [_jsx("input", __assign({ ref: ref, style: {
                    appearance: "none",
                    cursor: "pointer",
                    width: 18,
                    height: 18,
                    margin: 1,
                    border: "1px solid ".concat(designTokens.colors.border.default),
                    borderRadius: 4,
                    backgroundColor: hovering
                        ? designTokens.colors.surface.action
                        : "initial",
                }, type: "checkbox", checked: value, onChange: function (e) { return onChange && onChange(e.target.checked); } }, inputProps)), value && checkedOverlay] })));
};
export default Checkbox;
