import { Group } from "@gradience/api-types";

/**
 * Groups are actually created when an order is placed, but they are just
 * placholders until someone enters some information about the group.
 */
const isGroupCreated = (
  group: Group
): group is Group & {
  testDate: NonNullable<Group["testDate"]>;
  name: NonNullable<Group["name"]>;
  instructorFirstName: NonNullable<Group["instructorFirstName"]>;
  instructorLastName: NonNullable<Group["instructorLastName"]>;
} => {
  return (
    Boolean(group.testDate) &&
    Boolean(group.name) &&
    Boolean(group.instructorFirstName) &&
    Boolean(group.instructorLastName)
  );
};

export default isGroupCreated;
