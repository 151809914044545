import { useNavigate, useSearch } from "@tanstack/react-router";
import SchoolInfoForm from "../../forms/school-info";
import {
  accountRoute,
  dashboardHomeRoute,
  dashboardOverviewRoute,
  editSchoolInfoRoute,
} from "../..";
import config from "../../lib/config";

const EditSchoolInfo = () => {
  const navigate = useNavigate();
  const { prev } = useSearch({
    from: editSchoolInfoRoute.fullPath,
  });

  const prevRoute =
    prev === "account"
      ? accountRoute
      : prev === "overview"
      ? dashboardOverviewRoute
      : dashboardHomeRoute;

  const linkProps = {
    to: prevRoute.fullPath,
    params: { slug: config.REACT_APP_TEST_SLUG },
  };

  return (
    <SchoolInfoForm
      onSubmit={() => {
        navigate(linkProps);
      }}
      extraButton={{
        text: "Cancel",
        linkProps,
      }}
      backLinkOptions={linkProps}
    />
  );
};

export default EditSchoolInfo;
